import classes from "./Product.module.scss"
import dot from "../../assets/imgs/dot.png";
import Brand from "../../components/Brand";

import amanin_desc1 from "../../assets/imgs/content/amanin_desc1.jpg";
// import amanin_desc2 from "../../assets/imgs/content/amanin_desc2.jpg";
import amanin_desc3 from "../../assets/imgs/content/amanin_desc3.jpg";
// import amanin_desc4 from "../../assets/imgs/content/amanin_desc4.jpg";
import amanin_desc5 from "../../assets/imgs/content/amanin_desc5.jpg";
import amanin_desc6 from "../../assets/imgs/content/amanin_desc6.jpg";
import amanin_desc7 from "../../assets/imgs/content/amanin_desc7.jpg";
import amanin_desc8 from "../../assets/imgs/content/amanin_desc8.jpg";
import ExamNowButton from "../../components/ExamNow";
import { LANG } from "../../libs/utils";
import { Lang } from "../../libs/Language";

export const __Product__ = function (_this) {
    this.render = () => {
        const generateItem = (text, type = "default") => {
            // return <div>p</div>
            return <div
                className={[
                    classes.item,
                    type === "default" ? null : classes.item2,
                    _this.state.desktopView ? null : type === "default" ? classes.mb : classes.mb2
                ].join(" ")}>
                <div className={classes.item_dot}>
                    <div></div>
                </div>
                <span className={
                    _this.state.desktopView ? null : classes.mobile2
                }>
                    {text}
                </span>
            </div>
        }

        const renderLearnMore = (url = '/proktorfirstlearnmore') => {
            return <div className={classes.learnmore}>
                <a
                    href={url}
                    className={[classes.mobile].join(" ")}
                >
                    {Lang(`Pelajari Lebih Lanjut`, LANG(), { en: "Learn More" })}
                </a>
            </div>
        }

        const defaultTitle =
            /*`Solusi bagi anda yang ingin menyelenggarakan ujian dengan berbagai skala ujian.`*/
            Lang(`Solusi bagi anda yang ingin menyelenggarakan ujian dengan berbagai skala ujian.`,
                LANG(),
                {
                    en: 'The solution for those  who want to organize exams with various test scales.'
                })

        const contentItems = {
            'Proktor First': {
                title: Lang(`Tersedia berbagai pilihan konfigurasi ujian online, sesuai untuk pengguna pertama. Dimulai dengan GRATIS.`,
                    LANG(),
                    {
                        en: 'Various configurations for online exams are available, suitable for first-time users. Starting for FREE.'
                    }),
                items: <>
                    {generateItem(
                        /*"Pengawasan Online"*/
                        Lang('Pengawasan Online',
                            LANG(),
                            {
                                en: 'Online Proctoring'
                            })
                    )}
                    {generateItem(
                        /*"Deteksi Kecurangan Berbasis AI"*/
                        Lang('Deteksi Kecurangan Berbasis AI',
                            LANG(),
                            {
                                en: 'AI-Based Cheating Detection'
                            })
                    )}
                    {generateItem(
                        /*"Kustomisasi Ujian"*/
                        Lang('Kustomisasi Ujian',
                            LANG(),
                            {
                                en: 'Exam Customization'
                            })
                    )}
                    {generateItem("Live-chat Support")}
                    {generateItem("Broadcast Alert")}
                    {generateItem("Screenshot")}
                    {generateItem("Notes")}
                    {generateItem("Flags")}
                    {generateItem(
                        /*"Penjadwalan Ujian"*/
                        Lang('Penjadwalan Ujian',
                            LANG(),
                            {
                                en: 'Exam Scheduling'
                            })
                    )}
                    {generateItem(Lang("Post Proktor", LANG(), { en: `Post Proctoring` }))}
                </>,
                learnmore: renderLearnMore()
            },
            'Proktor Freedom': {
                title: /*}`Solusi bagi Anda yang mempunyai kebutuhan ujian yang unik dan spesifik.`*/
                    Lang('Solusi bagi Anda yang mempunyai kebutuhan ujian yang unik dan spesifik.',
                        LANG(),
                        {
                            en: 'A solution for those with unique and specific exam needs.'
                        }),
                items: <>
                    {generateItem(
                        Lang('Untuk kebutuhan yang lebih detail dan kompleks',
                            LANG(),
                            {
                                en: 'For more detailed and complex needs'
                            })
                        , "new")}
                    {generateItem(
                        Lang('Mengakomodasi secara fleksibel untuk kebutuhan khusus Anda',
                            LANG(),
                            {
                                en: 'Flexibly accommodates your specific needs'
                            })
                        , "new")}
                    {generateItem(
                        Lang('Menyediakan solusi yang handal dan cepat',
                            LANG(),
                            {
                                en: 'Provides a reliable and fast solution'
                            })
                        , "new")}
                    {generateItem(
                        Lang('Mendukung Sistem On-Premise',
                            LANG(),
                            {
                                en: 'Supports On-Premise Systems'
                            })
                        , "new")}
                </>,
                learnmore: renderLearnMore("/proktorfreedomlearnmore")
            },
            'Proktor Event': {
                title: /*`Solusi Ujian skala besar di satu waktu.`*/
                    Lang('Solusi Ujian skala besar di satu waktu.',
                        LANG(),
                        {
                            en: 'Large-scale exam solutions at one time.'
                        }),
                items: <>
                    {generateItem(
                        Lang('Pengadaan ujian skala besar',
                            LANG(),
                            {
                                en: 'Administration of large-scale exams'
                            })
                        , "new")}
                    {generateItem(
                        Lang('Ribuan Peserta dalam satu waktu',
                            LANG(),
                            {
                                en: 'Thousands of participants at one time'
                            })
                        , "new")}
                    {generateItem(
                        Lang('Mendukung banyak pengawas',
                            LANG(),
                            {
                                en: 'Support multiple proctors'
                            })
                        , "new")}
                    {generateItem(
                        Lang('Menyediakan tim untuk membantu anda di lokasi',
                            LANG(),
                            {
                                en: 'Provide a team to assist you on-site'
                            })
                        , "new")}
                </>,
                learnmore: renderLearnMore("/proktoreventlearnmore")
            }
        }
        const generateContent = () => {
            return <div className={classes.container}>
                <div className={classes.container_left}>
                    <div className={classes.container_left_c}>
                        <div className={classes.container_left_c_2}>
                            <div className={classes.body2_brand}>
                                <Brand white={true} />
                            </div>
                            <p className={classes.body2_brand_desc}>
                                {defaultTitle}
                            </p>
                            <div className={classes.body2_brand_prod}>
                                <div
                                    className={_this.state.chosenProduct === "Proktor First" ? classes.chosen : null}
                                    onClick={() => {
                                        _this.setState({
                                            chosenProduct: "Proktor First"
                                        })
                                    }}>
                                    <span>Proktor First</span>
                                </div>
                                <div
                                    className={_this.state.chosenProduct === "Proktor Freedom" ? classes.chosen : null}
                                    onClick={() => {
                                        _this.setState({
                                            chosenProduct: "Proktor Freedom"
                                        })
                                    }}>
                                    <span>Proktor Freedom</span>
                                </div>
                                <div
                                    className={_this.state.chosenProduct === "Proktor Event" ? classes.chosen : null}
                                    onClick={() => {
                                        _this.setState({
                                            chosenProduct: "Proktor Event"
                                        })
                                    }}
                                >
                                    <span>Proktor Event</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ExamNowButton />
                        </div>

                    </div>
                </div>
                <div className={classes.container_right}>
                    {_this.state.chosenProduct === "Proktor First" &&
                        <div className={classes.body2_content}>
                            <img
                                alt="desc"
                                src={amanin_desc5}
                            // width={"250px"}
                            />
                            <div>
                                <h1>Proktor First</h1>
                                <p>{contentItems['Proktor First'].title}</p>
                                <div>
                                    {contentItems['Proktor First'].items}
                                </div>
                                {contentItems['Proktor First'].learnmore}
                            </div>
                        </div>
                    }

                    {_this.state.chosenProduct === "Proktor Freedom" &&
                        <div className={classes.body2_content}>
                            <img
                                alt="desc"
                                src={amanin_desc7}
                            // width={"250px"}
                            />
                            <div>
                                <h1>Proktor Freedom</h1>
                                <p>{contentItems['Proktor Freedom'].title}</p>
                                <div>
                                    {contentItems['Proktor Freedom'].items}
                                </div>
                                {contentItems['Proktor Freedom'].learnmore}
                            </div>
                        </div>
                    }

                    {_this.state.chosenProduct === "Proktor Event" &&
                        <div className={classes.body2_content}>
                            <img
                                alt="desc"
                                src={amanin_desc8}
                            // width={"250px"}
                            />
                            <div>
                                <h1>Proktor Event</h1>
                                <p>{contentItems['Proktor Event'].title}</p>
                                <div>
                                    {contentItems['Proktor Event'].items}
                                </div>
                                {contentItems['Proktor Event'].learnmore}

                            </div>
                        </div>
                    }
                </div>
            </div>
        }

        const generateContentMobile = () => {
            return <div className={classes.mcontainer}>
                <div className={[classes.mcontainer_top].join(" ")}>
                    <div className={classes.mcontainer_top_t}>
                        <div className={[classes.body2_brand, classes.mobile].join(" ")}>
                            <Brand white={true} />
                        </div>
                        <span className={[classes.body2_brand_desc, classes.mobile].join(" ")}>
                            {defaultTitle}
                        </span>
                    </div>
                    <div className={[
                        classes.body2_brand_prod,
                        classes.mcontainer_top_b,
                        classes.mobile,
                    ].join(" ")}>
                        <div
                            className={[_this.state.chosenProduct === "Proktor First" ? classes.chosen : null, classes.mobile].join(" ")}
                            onClick={() => {
                                _this.setState({
                                    chosenProduct: "Proktor First"
                                })
                            }}>
                            <span className={classes.mobile}>Proktor First</span>
                        </div>
                        <div
                            className={[_this.state.chosenProduct === "Proktor Freedom" ? classes.chosen : null, classes.mobile].join(" ")}
                            onClick={() => {
                                _this.setState({
                                    chosenProduct: "Proktor Freedom"
                                })
                            }}>
                            <span className={classes.mobile}>Proktor Freedom</span>
                        </div>
                        <div
                            className={[_this.state.chosenProduct === "Proktor Event" ? classes.chosen : null, classes.mobile].join(" ")}
                            onClick={() => {
                                _this.setState({
                                    chosenProduct: "Proktor Event"
                                })
                            }}
                        >
                            <span className={classes.mobile}>Proktor Event</span>
                        </div>
                    </div>
                </div>

                <div className={classes.mcontainer_bottom}>
                    {_this.state.chosenProduct === "Proktor First" &&
                        <div className={[classes.body2_content, classes.mobile].join(" ")}>
                            {/* <img
                                className={classes.imgmobile}
                                alt="desc"
                                src={amanin_desc1}
                                width={"220px"}
                            /> */}
                            <div className={classes.mb}>
                                <h1>Proktor First</h1>
                                <p className={classes.mobile}>{contentItems['Proktor First'].title}</p>
                                <div>
                                    {contentItems['Proktor First'].items}
                                </div>
                                {contentItems['Proktor First'].learnmore}
                            </div>
                        </div>
                    }

                    {_this.state.chosenProduct === "Proktor Freedom" &&
                        <div className={[classes.body2_content, classes.mobile].join(" ")}>
                            {/* <img
                                alt="desc"

                                src={amanin_desc3}
                                width={"220px"}
                            /> */}
                            <div className={classes.mb}>
                                <h1>Proktor Freedom</h1>
                                <p className={classes.mobile}>{contentItems['Proktor Freedom'].title}</p>
                                <div>
                                    {contentItems['Proktor Freedom'].items}
                                </div>
                                {contentItems['Proktor Freedom'].learnmore}

                            </div>
                        </div>
                    }

                    {_this.state.chosenProduct === "Proktor Event" &&
                        <div className={[classes.body2_content, classes.mobile].join(" ")}>
                            {/* <img
                                alt="desc"
                                src={amanin_desc6}
                                width={"220px"}
                            /> */}
                            <div className={classes.mb}>
                                <h1>Proktor Event</h1>
                                <p className={classes.mobile}>{contentItems['Proktor Event'].title}</p>
                                <div>
                                    {contentItems['Proktor Event'].items}
                                </div>
                                {contentItems['Proktor Event'].learnmore}

                            </div>
                        </div>
                    }
                </div>
            </div >
        }
        return <div id="product" ref={_this.productRef}>
            {_this.state.desktopView ?
                generateContent() :
                generateContentMobile()
            }
        </div>
    }
}