export const COMPANY_NAME = "PT. Amanin Inteligensia International"
export const DISABLE_START_FREE = true

export const PROKTOR_URL = "https://proktor.amanin.id"
const API_URL = "https://proktor.amanin.id/api/proktor";
const API_URL_CONF = "https://proktor.amanin.id/confapi";

export const LANG = () => {
    // return "en";
    // return "id";
    return localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
};

export const handleScreenChange = (_this, event) => {
    if (event.matches) {
        // console.log('Aplikasi sedang berjalan di perangkat mobile.');
        _this.setState({ desktopView: false })
    } else {
        // console.log('Aplikasi sedang berjalan di desktop.');
        _this.setState({ desktopView: true })
    }
};

export const fetchDataJSON = async (url, jsonData, errorListener) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(jsonData),
    };
    return fetch(url, requestOptions)
        .then(async (response) => {
            const data = await response.json();
            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } else {
                return data;
            }
        })
        .catch((error) => {
            errorListener(error);
        });
};

export const fetchJSONMessage = async (action, body_content, error_handler) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        action: action,
        body: body_content
    };
    return fetchDataJSON(API_URL, requestOptions, (error) => {
        error_handler(error);
    });
}

export const fetchJSONMessageConf = async (action, body_content, error_handler) => {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        action: action,
        body: body_content
    };
    return fetchDataJSON(API_URL_CONF, requestOptions, (error) => {
        error_handler(error);
    });
}

export const fetchJSONMessageConfUrl = async (url, action, body_content, error_handler) => {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        action: action,
        body: body_content
    };
    return fetchDataJSON(`https://${url}/confapi/termsandconditions`, requestOptions, (error) => {
        error_handler(error);
    });
}

export const fetchAgreeTerms = async (url, id, error_handler) => {
    // return fetchJSONMessageConfUrl(url, "agree-terms-conditions", {
    //     'id': id,
    // }, error_handler)
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        // action: action,
        // body: body_content
    };
    return fetchDataJSON(`https://${url}/confapi/termsandconditions?id=${id}`, requestOptions, (error) => {
        error_handler(error)
    })
}

// captcha
// Use this site key in the HTML code your site serves to users.
// client
// site key = 6LfATmQpAAAAAJ3waY1n0UQlQMRAFSDZc-ajCNBB


// Use this secret key for communication between your site and reCAPTCHA.
// server
// secret key = 6LfATmQpAAAAAA628jAN3X5Au5UK-xIfsMqULkk-

// https://www.google.com/recaptcha/admin/site/694439616/setup


/// v2
// site key = 6LevWWQpAAAAAAr8jhhUnQ3oJzjYO4NZENxz8txJ
// secret key = 6LevWWQpAAAAACz5ptWUI9p6VCZkb75orxnxgSN2