import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _12_Pemasaran = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("13. Pemasaran", LANG(), { en: '13. Marketing' })}
        </span>
        <p>
            {Lang("Anda memberikan izin kepada Proktor Amanin dan hak untuk : ", LANG(), { en: 'You grant permission to Proktor Amanin and the right to:' })}
        </p>
        <ol type="a">
            <li>
                {Lang("mengidentifikasi Anda sebagai pelanggan dan menggunakan logo Anda di seluruh materi pemasaran Proktor Amanin (misalnya, situs web Proktor Amanin, surel, presentasi, brosur), dan", LANG(), { en: 'Identify you as a customer and use your logo across all Proktor Amanin marketing materials (e.g., Proktor Amanin website, emails, presentations, brochures), and' })}
            </li>
            <li>
                {Lang("mengembangkan konten seputar pengalaman Anda sebagai pelanggan Kami (misalnya, studi kasus tertulis atau studi kasus video). Setiap konten yang dibuat berdasarkan klausul (b) dari Bagian 12  ini akan dibuat dengan kerjasama Anda dan hanya digunakan setelah persetujuan dari Anda.", LANG(), { en: 'Develop content about your experience as our customer (e.g., written case studies or video case studies). Any content created pursuant to clause (b) of this Section 13 will be created in collaboration with you and used only upon your approval.' })}
            </li>
        </ol>
    </div>
}