import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _1_GambaranUmum = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("1. Gambaran Umum", LANG(), { en: '1. General Overview' })}</span>
        <p>
            {Lang("Proktor Amanin adalah platform pengawasan ujian online. Ketika Anda menggunakan Layanan, Anda akan memiliki akses untuk membuat ujian online, membagikan tautan ujian Anda ke Pengguna lain seperti Peserta ujian ataupun Pengawas ujian.  Anda juga memiliki akses ke rekaman kamera, mikrofon, dan tangkapan layar komputer Pengguna lain yang Anda bertanggung jawab.", LANG(),
                { en: 'Proktor Amanin is an online exam proctoring platform. When you use the Service, you will have access to create online exams, share your exam links with other Users such as Exam Participants or Exam Supervisors. You will also have access to recordings of camera, microphone, and computer screen captures of other Users for whom you are responsible.' })}

        </p>
        <p>
            {Lang("Platform dan Layanan ini tersedia di amanin.id dan dalam bentuk lain yang disediakan atau tersedia oleh Proktor Amanin. Penggunaan Anda terhadap Platform dan Layanan ini tunduk pada Syarat dan Ketentuan. Dengan menggunakan Platform dan Layanan, Anda mengakui dan menyetujui Kebijakan Privasi Proktor Amanin.", LANG(), { en: 'This Platform and Service are available at amanin.id and other forms provided or made available by Proktor Amanin. Your use of this Platform and Service is subject to these Terms and Conditions. By using the Platform and Service, you acknowledge and agree to the Privacy Policy of Proktor Amanin.' })}

        </p>
    </div>
}