import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _5_Tautan = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("5. TAUTAN KE SITUS WEB LAIN", LANG(), { en: '5. LINKS TO OTHER WEBSITES' })}</span>
        <p>
            {Lang("Sebagai bagian dari Layanan, Kami dapat menyediakan tautan atau kompatibilitas dengan situs web atau aplikasi lain. Namun, Kami tidak bertanggung jawab atas praktik privasi yang digunakan oleh situs web tersebut atau informasi atau konten yang mereka miliki. Kebijakan Privasi ini hanya berlaku untuk informasi yang dikumpulkan oleh Kami melalui situs web dan Layanan. Oleh karena itu, Kebijakan Privasi ini tidak berlaku untuk penggunaan Anda terhadap situs web pihak ketiga yang diakses dengan memilih tautan di situs Kami atau melalui Layanan Kami. Sejauh Anda mengakses atau menggunakan Layanan melalui atau di situs web atau aplikasi lain, maka kebijakan privasi situs web atau aplikasi lain tersebut akan berlaku untuk akses atau penggunaan Anda terhadap situs atau aplikasi tersebut. Kami mendorong pengguna Kami untuk membaca pernyataan privasi situs web lain sebelum melanjutkan untuk menggunakannya.", LANG(), { en: 'As part of the Services, we may provide links or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the website and Services. Therefore, this Privacy Policy does not apply to your use of third-party websites accessed by selecting links on our website or through our Services. To the extent that you access or use the Services through or on another website or application, the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.' })}
        </p>
    </div>
}