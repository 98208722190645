import React from "react";
import classes from "../TermsConditions/__index__.module.scss";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { _1_InformasiYang } from "./1_InformasiYang";
import { _0_Intro } from "./0_Intro";
import Footer from "../../components/Footer";
import NavPage from "../../components/Nav";
import { handleScreenChange } from "../../libs/utils";
import { _2_BagaimanaKami } from "./2_BagaimanaKami";
import { _0_Intro2 } from "./0_Intro2";
import { _3_BagaimanaKamiMembagiInformasi } from "./3_BagaimanaKamiMembagiInformasi";
import { _4_BagaimanaKamiMembagiInformasi, _4_BagaimanaKamiMenjagaInformasi } from "./4_BagaimanaKamiMenjagaInformasi";
import { _5_Tautan } from "./5_Tautan";
import { _6_PerubahanKebijakan } from "./6_PerubahanKebijakan";
import { _7_HubungiKami } from "./7_HubungiKami";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            desktopView: true,
        }
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentWillUnmount() {
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
        this.contentRef?.current?.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    renderContent() {
        return <div className={[
            classes.container_content,
            classes.fadein1s
        ].join(" ")}>
            <div className={classes.container_content_left}></div>
            <div className={classes.container_content_right}>
                {_0_Intro(this, classes)}
                <div
                    ref={this.contentRef}
                    className={classes.container_content_right_c}>
                    {_0_Intro2(this, classes)}
                    {_1_InformasiYang(this, classes)}
                    {_2_BagaimanaKami(this, classes)}
                    {_3_BagaimanaKamiMembagiInformasi(this, classes)}
                    {_4_BagaimanaKamiMenjagaInformasi(this, classes)}
                    {_5_Tautan(this, classes)}
                    {_6_PerubahanKebijakan(this, classes)}
                    {_7_HubungiKami(this, classes)}
                </div>
                <div className={classes.container_content_right_b}>
                </div>
            </div>
        </div>
    }

    generateContent() {
        const vd = this.state.desktopView;
        return <div className={[classes.container].join(" ")}
            style={{
                marginTop: this.Id ? "0px" : "67px",
            }}
        >
            {!this.Id && <NavPage desktopView={this.state.desktopView} />}
            {this.renderContent()}
            {!this.Id && <Footer />}
        </div>
    }
    render() {
        return this.generateContent();
    }
}

export default withRouter(PrivacyPolicy);