import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _9_PenggunaanYangDilarang = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("10. Penggunaan Yang Dilarang", LANG(), { en: '10. Prohibited Uses' })}
        </span>
        <p>
            {Lang("Anda setuju bahwa Anda tidak akan, dan tidak akan membiarkan Pengguna Akhir apa pun untuk, secara langsung atau tidak langsung:", LANG(), { en: '' })}
        </p>
        <ol type="a">
            <li>
                {Lang("menggunakan Platform dan Layanan tidak sesuai dengan Syarat dan Ketentuan ini;", LANG(), { en: 'Use the Platform and Service in violation of these Terms and Conditions;' })}
            </li>
            <li>
                {Lang(" memodifikasi, menyesuaikan, membongkar, mendekompilasi, menyiapkan karya turunan, membuat perbaikan, mengekstraksi inovasi dari, merubah kode sumber, proses, set data atau database, alat manajemen, alat pengembangan, server atau situs hosting, atau mencoba untuk mengakses teknologi dasar dari Platform atau Layanan;", LANG(),
                    { en: 'Modify, adapt, reverse engineer, decompile, prepare derivative works, make improvements, extract innovations from, alter the source code, processes, data sets or databases, management tools, development tools, servers or hosting sites, or attempt to access the underlying technology of the Platform or Service;' })}
            </li>
            <li>
                {Lang("dengan sengaja atau dengan kelalaian menggunakan Platform atau Layanan dengan cara yang menyalahgunakan, mengganggu, atau mengganggu jaringan Proktor Amanin, akun Anda, akun Peserta, atau hak pengguna lain, atau Layanan;", LANG(), { en: 'Intentionally or negligently use the Platform or Service in a manner that abuses, disrupts, or interferes with the Proktor Amanin network, your account, Participant accounts, or other user rights, or the Service;' })}
            </li>
            <li>
                {Lang("terlibat dalam kegiatan yang ilegal menurut Hukum yang berlaku, penipuan, palsu, atau menyesatkan;", LANG(),
                    { en: 'Engage in activities that are illegal under applicable Law, fraudulent, fake, or deceptive;' })}
            </li>
            <li>
                {Lang("mengirimkan melalui Platform atau Layanan setiap materi yang dapat melanggar, mengambil alih, atau melanggar Hak Kekayaan Intelektual pihak ketiga;", LANG(),
                    { en: 'Transmit through the Platform or Service any material that may infringe upon, take over, or violate the Intellectual Property Rights of third parties;' })}
            </li>
            <li>
                {Lang("membangun atau mengukurkan produk atau layanan yang bersaing, atau menyalin fitur, fungsi, atau grafis dari Platform atau Layanan;", LANG(),
                    { en: 'Develop or measure products or services that compete, or copy features, functions, or graphics of the Platform or Service;' })}
            </li>
            <li>
                {Lang("menggunakan Platform atau Layanan untuk pengembangan, produksi, atau pemasaran layanan atau produk yang secara substansial mirip dengan Platform atau Layanan;", LANG(),
                    { en: 'Use the Platform or Service for the development, production, or marketing of services or products substantially similar to the Platform or Service;' })}
            </li>
            <li>
                {Lang("menggunakan Platform atau Layanan untuk menyampaikan pesan atau materi yang bersifat melecehkan, fitnah, menakut-nakuti, cabul, tidak senonoh, akan melanggar atau melanggar Hak Kekayaan Intelektual pihak mana pun, atau sebaliknya melanggar hukum, atau akan menimbulkan tanggung jawab perdata atau pidana, menurut setiap Hukum yang berlaku;", LANG(),
                    { en: 'Use the Platform or Service to convey messages or material that is abusive, defamatory, threatening, obscene, indecent, will violate or infringe upon the Intellectual Property Rights of any party, or otherwise violates the law, or will cause civil or criminal liability, under any applicable Law;' })}
            </li>
            <li>
                {Lang("mengunggah atau mengirimkan perangkat lunak, konten, atau kode apa pun yang bertujuan merusak, menonaktifkan, menghancurkan, atau merugikan kinerja Platform dan Layanan dengan cara apa pun atau yang bertujuan merugikan atau mengekstrak informasi atau data dari perangkat keras, perangkat lunak, jaringan, atau pengguna lain dari Platform dan Layanan;", LANG(),
                    { en: 'Upload or send any software, content, or code intended to damage, disable, destroy, or adversely affect the performance of the Platform and Service in any way or intended to harm or extract information or data from the hardware, software, networks, or other users of the Platform and Service;' })}
            </li>
            <li>
                {Lang("terlibat dalam aktivitas atau menggunakan Platform, Layanan, atau akun Anda dengan cara apa pun yang dapat merusak, menonaktifkan, memberatkan, merusak atau mengganggu Platform, Layanan, atau setiap server atau jaringan yang terhubung dengan Platform, Layanan, atau sistem keamanan Proktor Amanin;", LANG(),
                    { en: "Engage in activities or use the Platform, Service, or your account in any manner that may damage, disable, burden, impair, or interfere with the Platform, Service, or any server or network connected to the Platform, Service, or Proktor Amanin's security systems;" })}
            </li>
            <li>
                {Lang("menggunakan Platform dan Layanan dengan melanggar kebijakan Proktor Amanin atau dengan cara yang melanggar Hukum yang berlaku, termasuk anti-spam, kontrol impor dan ekspor, kekayaan intelektual, privasi, anti-terorisme, anti-pemberian suap, praktik korupsi asing, dan setiap Hukum lain yang mensyaratkan persetujuan dari subjek rekaman audio dan video;", LANG(),
                    { en: 'Use the Platform and Service in violation of Proktor Amanin policies or in a manner that violates applicable Law, including anti-spam, import and export controls, intellectual property, privacy, anti-terrorism, anti-bribery, foreign corrupt practices, and any other Law requiring consent from subjects of audio and video recording;' })}
            </li>
            <li>
                {Lang("menghilangkan, menghapus, mengubah, atau menyembunyikan pemberitahuan Hak Kekayaan Intelektual yang disediakan pada atau bersama dengan Platform atau Layanan, termasuk salinan tersebut;", LANG(),
                    { en: 'Remove, delete, modify, or obscure any Intellectual Property notice provided on or with the Platform or Service, including copies thereof;' })}
            </li>
            <li>
                {Lang("membuat, menggunakan, atau menawarkan Platform dan Layanan untuk disewa guna usahakan, disewakan, atau dijual, atau mereproduksi, menjual kembali, mendistribusikan, menerbitkan, menampilkan, memberikan, mentransfer, memberi lisensi, meminjamkan, menggunakan secara bersama atau menggunakan Platform dan Layanan untuk tujuan komersial atau tujuan lain yang tidak secara tegas diizinkan oleh Syarat dan Ketentuan ini; ", LANG(),
                    { en: 'Make, use, or offer the Platform and Service for rent, lease, or sale, or reproduce, resell, distribute, publish, display, deliver, transfer, license, lend, use jointly or use the Platform and Service for commercial purposes or other purposes not explicitly permitted by these Terms and Conditions; or' })}  atau
            </li>
            <li>
                {Lang("membuat Platform atau Layanan, atau fitur atau fungsionalitas apa pun dari itu, tersedia untuk pihak ketiga untuk alasan atau dengan cara apa pun, kecuali secara tegas diizinkan oleh Syarat dan Ketentuan ini atau sebaliknya secara tegas disetujui secara tertulis oleh Anda dan Proktor Amanin", LANG(), { en: 'Make the Platform or Service, or any features or functionalities thereof, available to third parties for any reason or in any manner, except as expressly permitted by these Terms and Conditions or otherwise expressly agreed in writing by you and Proktor Amanin.' })}
            </li>
        </ol>
    </div>
}
