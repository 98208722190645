import React from "react";
import classes from "./__index__.module.scss";
import { fetchAgreeTerms, handleScreenChange } from "../../libs/utils";
import NavPage from "../../components/Nav";
import Footer from "../../components/Footer";
import { _1_GambaranUmum } from "./1_GambaranUmum";
import { _2_KetentuanUmum } from "./2_KetentuanUmum";
import { _3_PenggunaanPlatformDanLayanan } from "./3_PenggunaanPlatformDanLayanan";
import { _4_KeamananDanKebijakanPrivasi } from "./4_KeamananDanKebijakanPrivasi";
import { _9_PenggunaanYangDilarang } from "./9_PenggunaanYangDilarang";
import { _5_InformasiAkun } from "./5_InformasiAkun";
import { _6_SpesifikasiSistem } from "./6_SpesifikasiSistem";
import { _7_TautanKeSitusLain } from "./7_TautanKeSitusLain";
import { _8_Rekaman } from "./8_Rekaman";
import { _10_TanggungJawabTerkaitPenggunaan } from "./10_TanggungJawabTerkaitPenggunaan";
import { _11_PembatasanPostingan } from "./11_PembatasanPostingan";
import { _12_Pemasaran } from "./12_Pemasaran";
import { _13_HakKekayaanIntelektual } from "./13_HakKekayaanIntelektual";
import { _14_EmailTidakBoleh } from "./14_EmailTidakBoleh";
import { _15_PersetujuanPengguna } from "./15_PersetujuanPengguna";
import { _16_PemberitahuanJaminan } from "./16_PemberitahuanJaminan";
import { _17_PembatasanKerugian } from "./17_PembatasanKerugian";
import { _18_ModifikasiPengguan } from "./18_ModifikasiPenggunaan";
import { _19_LainLain } from "./19_LainLain";
import { _20_Definisi } from "./20_Definisi";
import { _0_Intro } from "./0_Intro";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import loading from "../../assets/imgs/loading.gif";
import { _9_PembayarandanBiaya } from "./9_PembayarandanBiaya";

class TermsConditions extends React.Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();

        this.showAgreeButton = this.props.showAgreeButton ? this.props.showAgreeButton : false

        const searchParams = new URLSearchParams(this.props.location.search);
        this.Id = searchParams.get('id');
        this.Url = searchParams.get('url');

        this.state = {
            desktopView: true,
            scrolledBottom: false,
            showLoading: false,
        }
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    handleScroll = (ev) => {
        const el = ev.target;
        const divHeight = el.clientHeight;

        const scrollTop = el.scrollTop;

        const scrollHeight = el.scrollHeight;

        const remainingHeight = scrollHeight - (divHeight + scrollTop);

        if (remainingHeight <= 20) {
            this.setState({ scrolledBottom: true })
        }
    }
    componentWillUnmount() {
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
        this.contentRef?.current?.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)

        this.contentRef.current.addEventListener('scroll', this.handleScroll);
    }

    renderContent() {
        return <div className={[
            classes.container_content,
            classes.fadein1s
        ].join(" ")}>
            {this.state.showLoading ? <div
                style={{
                    margin: "0 auto",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "1.0rem",
                    alignItems:"center",
                }}
            >
                <img
                    src={loading}
                />
                <span>Menghubungkan dengan layanan Legal ...</span>
            </div> : <>
                <div className={classes.container_content_left}></div>
                <div className={classes.container_content_right}>
                    {_0_Intro(this, classes)}
                    <div
                        ref={this.contentRef}
                        className={classes.container_content_right_c}>
                        {_1_GambaranUmum(this, classes)}
                        {_2_KetentuanUmum(this, classes)}
                        {_3_PenggunaanPlatformDanLayanan(this, classes)}
                        {_4_KeamananDanKebijakanPrivasi(this, classes)}
                        {_5_InformasiAkun(this, classes)}
                        {_6_SpesifikasiSistem(this, classes)}
                        {_7_TautanKeSitusLain(this, classes)}
                        {_8_Rekaman(this, classes)}
                        {_9_PembayarandanBiaya(this, classes)}
                        {_9_PenggunaanYangDilarang(this, classes)}
                        {_10_TanggungJawabTerkaitPenggunaan(this, classes)}
                        {_11_PembatasanPostingan(this, classes)}
                        {_12_Pemasaran(this, classes)}
                        {_13_HakKekayaanIntelektual(this, classes)}
                        {_14_EmailTidakBoleh(this, classes)}
                        {_15_PersetujuanPengguna(this, classes)}
                        {_16_PemberitahuanJaminan(this, classes)}
                        {_17_PembatasanKerugian(this, classes)}
                        {_18_ModifikasiPengguan(this, classes)}
                        {_19_LainLain(this, classes)}
                        {_20_Definisi(this, classes)}
                    </div>
                    <div className={classes.container_content_right_b}>
                        {
                            this.Id ?
                                <div className={[
                                    classes.btn,
                                    !this.state.scrolledBottom ? classes.inactive : classes.active
                                ].join(" ")}
                                    onClick={() => {
                                        if (this.state.scrolledBottom) {
                                            fetchAgreeTerms(this.Url, this.Id, (err) => {
                                                console.error(err);
                                                // alert('Gagal mengirim terms and conditions');
                                            }).then((data) => {
                                                console.log(data);
                                                this.setState({ showLoading: true })
                                            })
                                        }
                                    }}
                                >
                                    {!this.state.scrolledBottom ?
                                        "Geser Ke Bawah Untuk Menyetujui" :
                                        "Saya Menyetujui"}
                                </div>
                                : null
                        }
                    </div>
                </div>
            </>}
        </div>
    }

    generateContent() {
        const vd = this.state.desktopView;
        return <div className={[classes.container].join(" ")}
            style={{
                marginTop: this.Id ? "0px" : "67px",
            }}
        >
            {!this.Id && <NavPage desktopView={this.state.desktopView} />}
            {this.renderContent()}
            {!this.Id && <Footer />}
        </div>
    }
    render() {
        return this.generateContent();
    }
}

export default withRouter(TermsConditions);