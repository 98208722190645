import React from "react";
import classes from "./__index__.module.scss";
import NavPage from "../../components/Nav";
import Footer from "../../components/Footer";
import apple from "../../assets/imgs/apple.png";
import windows from "../../assets/imgs/windows.png";
import { LANG } from "../../libs/utils";
import { Lang } from "../../libs/Language";

class Troubleshooting extends React.Component {
    constructor(props) {
        super(props);

        this.cases = [
            {
                img: null,
                title: [Lang(`Panduan Untuk Penyelenggara Ujian`, LANG(), { en: 'Guide for Exam Organizers' })],
                url: `https://amanin.id/troubleshootings_guidelines/panduan_penyelenggara_1.png`,
                url2: `https://amanin.id/troubleshootings_guidelines/panduan_penyelenggara_2.png`
            },
            {
                img: null,
                title: [Lang(`Panduan Penting Peserta Ujian`, LANG(), { en: 'Important Guidelines for Exam Participants' })],
                url: `https://amanin.id/troubleshootings_guidelines/guideline_participants.png`
            },
            {
                img: null,
                title: [Lang(`Hal - hal Yang Mungkin Terjadi Selama Ujian Online`, LANG(), { en: 'Possible Issues During Online Exams' })],
                url: `https://amanin.id/troubleshootings_guidelines/guideline_problems_in_exams.png`
            },

            {
                img: apple,
                title: [Lang(`Tidak dapat mengaktifkan kamera pada MacOS`, LANG(), { en: `Unable to Activate Camera on MacOS` })],
                url: `https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_camera_problems.png`
            },
            {
                img: apple,
                title: [Lang(`Tidak dapat mengaktifkan screen dan audio pada MacOS`, LANG(), { en: `Unable to Activate Screen and Audio on MacOS` })],
                url: `https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_screen_audio_problems.png`
            },
            {
                img: apple,
                title: [Lang(`Tidak dapat mengaktifkan microphone pada MacOS`, LANG(), { en: 'Unable to Activate Microphone on MacOS' })],
                url: `https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_microphone_problems.png`
            },
            {
                img: null,
                title: [Lang(`Bermasalah dengan Ekstensi Chrome`, LANG(), { en: 'Issues with Chrome Extensions' })],
                url: `https://amanin.id/troubleshootings_guidelines/ekstensi%20troubleshooting%20guide.png`
            },
            // {
            //     img: null,
            //     title: `Tidak dapat mengaktifkan kamera dan screen pada MacOS`,
            //     url: `https://amanin.id/pdf/macbook%20troubleshooting%20guide.png`
            // },
        ];

        this.state = {
            chosenIdx: 0
        }
    }

    render() {
        return <div className={classes.container}>
            <NavPage />
            <div className={classes.container_top}>
                {this.cases.map((v, idx) => {
                    return <div
                        key={idx}
                        onClick={() => {
                            this.setState({ chosenIdx: idx })
                        }}
                        className={this.state.chosenIdx === idx ? classes.chosen : null}>
                        {v.img !== null && <img alt="logo" src={v.img} width={"50px"} />}
                        {v.title}
                    </div>
                })}
            </div>
            <div className={classes.container_bottom}>
                <div>
                    <img
                        alt="content"
                        src={this.cases[this.state.chosenIdx].url}
                    />
                    {this.cases[this.state.chosenIdx].url2 ? <img alt="content" src={this.cases[this.state.chosenIdx].url2} /> : null}
                </div>
            </div>
            <Footer />
        </div>
    }
}

export default Troubleshooting;