import React from "react";
import classes from "./ExamNow.module.scss"
import { DISABLE_START_FREE, LANG, PROKTOR_URL } from "../libs/utils";
import { Lang } from "../libs/Language";

class ExamNowButton extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        return DISABLE_START_FREE ? <></> : <div className={classes.container}>
            <a href={PROKTOR_URL}>
                {Lang(`Mulai Ujian Sekarang Dengan Gratis`, LANG(), { en: "Start For Free" })}
            </a>
        </div>
    }
}

export default ExamNowButton;