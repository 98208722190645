import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

// {Lang("", LANG(), { en: '' })}
export const _19_LainLain = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("20. Lain Lain", LANG(), { en: '20. Others' })}
        </span>
        <p>
            {Lang("Jika bagian dari perjanjian Syarat dan Ketentuan ini dianggap atau ditemukan tidak sah atau tidak dapat dilaksanakan, bagian dari perjanjian tersebut akan diartikan untuk konsisten dengan hukum yang berlaku sementara bagian yang tersisa dari perjanjian tersebut tetap berlaku sepenuhnya. Kegagalan Kami untuk menegakkan ketentuan apa pun dalam perjanjian ini tidak akan dianggap sebagai pengabaian hak Kami untuk menegakkan ketentuan tersebut. Hak-hak Kami di bawah perjanjian ini tetap ada setelah adanya transfer atau pengakhiran perjanjian ini.", LANG(),
                { en: 'If any part of this Terms and Conditions agreement is deemed or found to be invalid or unenforceable, that portion of the agreement will be interpreted in a manner consistent with applicable law while the remaining portions of the agreement will continue to be in full force and effect. Our failure to enforce any provision of this agreement will not be construed as a waiver of our right to enforce such provision. Our rights under this agreement will survive any transfer or termination of this agreement.' })}
        </p>
        <p>
            {Lang("Ketentuan Penggunaan ini dan penggunaan Anda terhadap Kami diatur oleh hukum Negara Indonesia, tanpa memperhatikan ketentuan konflik hukum.", LANG(),
                { en: 'These Terms of Use and your use of our services are governed by the laws of Indonesia, without regard to its conflict of law principles.' })}
        </p>
        <p>
            {Lang("Kami dapat menyerahkan atau menugaskan Syarat dan Ketentuan ini dan/atau Kebijakan Privasi Kami, secara keseluruhan atau sebagian, kepada siapa pun atau entitas kapan saja dengan atau tanpa persetujuan Anda. Anda tidak dapat menyerahkan atau menugaskan hak atau kewajiban apa pun di bawah Syarat dan Ketentuan atau Kebijakan Privasi tanpa persetujuan tertulis Kami sebelumnya, dan setiap penyerahan atau penugasan yang tidak sah oleh Anda adalah batal.", LANG(), { en: 'We may assign or transfer these Terms and Conditions and/or our Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or transfer any rights or obligations under these Terms and Conditions or the Privacy Policy without our prior written consent, and any unauthorized assignment or transfer by you is null and void.' })}
        </p>
        <p>
            {Lang("ANDA MENGAKUI BAHWA ANDA TELAH MEMBACA KETENTUAN PENGGUNAAN INI, MEMAHAMI SYARAT DAN KETENTUAN, DAN AKAN TERIKAT OLEH KETENTUAN DAN KONDISI INI. ANDA LEBIH LANJUT MENGAKUI BAHWA SYARAT DAN KETENTUAN INI SERTA KEBIJAKAN PRIVASI MERUPAKAN PERNYATAAN LENGKAP DAN EKSKLUSIF DARI PERJANJIAN ANTARA KAMI DAN BAHWA INI MENGGANTIKAN SEGALA PROPOSAL ATAU PERJANJIAN SEBELUMNYA, LISAN ATAU TULISAN, DAN SEGALA KOMUNIKASI LAIN ANTARA KAMI YANG BERKAITAN DENGAN SUBYEK PERJANJIAN INI.", LANG(), { en: 'YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE, UNDERSTAND THE TERMS AND CONDITIONS, AND AGREE TO BE BOUND BY THEM. YOU FURTHER ACKNOWLEDGE THAT THESE TERMS AND CONDITIONS AND THE PRIVACY POLICY REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US, AND THAT IT SUPERSEDES ANY PRIOR PROPOSALS OR AGREEMENTS, WHETHER ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.' })}
        </p>
    </div>
}