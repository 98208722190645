import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _2_BagaimanaKami = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("2. BAGAIMANA KAMI MENGGUNAKAN INFORMASI", LANG(), { en: '2. HOW WE USE INFORMATION' })}</span>
        <p>
            {Lang("Kecuali dinyatakan lain dalam Kebijakan Privasi ini, Kami tidak menjual, menukar, menyewakan, atau berbagi informasi tentang Anda untuk tujuan pemasaran dengan pihak ketiga tanpa persetujuan Anda. Kami menggunakan data pribadi Anda untuk kegiatan-kegiatan berikut :", LANG(), { en: 'Unless otherwise stated in this Privacy Policy, we do not sell, trade, rent, or share your information for marketing purposes with third parties without your consent. We use your personal data for the following activities:' })}
        </p>
        <ol type="a">
            <li>
                {Lang("Menyediakan Produk dan Layanan Proktor Amanin:", LANG(), { en: 'Providing Proktor Amanin Products and Services:' })}
                <ol type="i">
                    <li>
                        {Lang("Untuk menyediakan produk dan layanan kepada pemilik akun, pengguna, dan mereka yang diundang untuk bergabung dalam ujian online yang diadakan di akun mereka.", LANG(), { en: 'Deliver products and services to account holders, users, and those invited to participate in online exams hosted on their accounts.' })}
                    </li>
                    <li>
                        {Lang("Untuk menyesuaikan produk dan layanan Proktor Amanin dan rekomendasi untuk akun pengguna mereka.", LANG(), { en: 'Customize Proktor Amanin products and services and recommendations for their user accounts.' })}
                    </li>
                    <li>
                        {Lang("Menggunakan data pribadi untuk menentukan produk dan layanan apa yang mungkin tersedia di lokasi Anda.", LANG(), { en: 'Using personal data to determine which products and services may be available in your location.' })}
                    </li>
                    <li>
                        {Lang("Menggunakan data pribadi, termasuk informasi kontak, untuk mengarahkan undangan atau pesan dari pengguna saat menggunakan produk dan layanan Proktor Amanin.", LANG(), { en: 'Using personal data, including contact information, to direct invitations or messages from users while using Proktor Amanin products and services.' })}
                    </li>
                    <li>
                        {Lang("Penggunaan data pribadi untuk dukungan pelanggan, yang mungkin mencakup mengakses suara, video, file, pesan, dan konten atau konteks lainnya, termasuk informasi yang Anda berikan melalui fitur obrolan virtual situs web Proktor Amanin, atas arahan pemilik akun atau pengguna mereka.", LANG(), { en: 'Using personal data for customer support, which may include accessing voice, video, files, messages, and other content or context provided through the virtual chat feature of the Proktor Amanin website, under the direction of account owners or their users.' })}
                    </li>
                    <li>
                        {Lang("Menggunakan data pribadi untuk mengelola hubungan dan kontrak Kami dengan pemilik akun dan pihak lain, termasuk penagihan, kepatuhan terhadap kewajiban kontraktual, dan administrasi terkait.", LANG(), { en: 'Using personal data to manage our relationships and contracts with account holders and other parties, including billing, compliance with contractual obligations, and related administration.' })}
                    </li>
                </ol>
            </li>
            <li>
                {Lang("Penelitian dan Pengembangan Produk:", LANG(), { en: 'Product Research and Development:' })}
                <ol type="i">
                    <li>
                        {Lang("Jika diotorisasi oleh pengaturan yang berlaku, untuk mengembangkan, menguji, dan meningkatkan produk dan layanan Proktor Amanin, dan untuk pemecahan masalah produk dan layanan.", LANG(), { en: 'If authorized by applicable settings, to develop, test, and improve Proktor Amanin products and services, and to troubleshoot product and service issues.' })}
                    </li>
                </ol>
            </li>
            <li>
                {Lang("Pemasaran dan Promosi:", LANG(), { en: 'Marketing and Promotion:' })}
                <ol type="i">
                    <li>
                        {Lang("Untuk memungkinkan Proktor Amanin dan/atau mitra pemasaran pihak ketiga untuk memasarkan, mengiklankan, dan mempromosikan produk dan layanan Proktor Amanin, termasuk berdasarkan penggunaan produk Anda, informasi yang Kami terima dari mitra pihak ketiga, atau jika Anda mengunjungi situs web Kami.", LANG(), { en: 'To enable Proktor Amanin and/or third-party marketing partners to market, advertise, and promote Proktor Amanin products and services, including based on your product usage, information received from third-party partners, or if you visit our website.' })}
                    </li>
                </ol>
            </li>
            <li>
                {Lang("Autentikasi, Integritas, Keamanan, dan Keselamatan:", LANG(), { en: 'Authentication, Integrity, Security, and Safety:' })}
                <ol type="i">
                    <li>
                        {Lang("Untuk mengotentikasi akun dan aktivitas, mendeteksi, menyelidiki, dan mencegah perilaku jahat, aktivitas penipuan, atau pengalaman yang tidak aman.", LANG(), { en: 'To authenticate accounts and activities, detect, investigate, and prevent malicious behavior, fraud activities, or unsafe experiences.' })}
                    </li>
                    <li>
                        {Lang("Mengatasi ancaman keamanan, melindungi keselamatan publik, dan mengamankan produk dan layanan Proktor Amanin.", LANG(), { en: 'Addressing security threats, protecting public safety, and securing Proktor Amanin products and services' })}
                    </li>
                    <li>
                        {Lang("Proktor Amanin menggunakan alat canggih untuk secara otomatis memindai beberapa jenis konten seperti latar belakang virtual, gambar profil, obrolan, untuk mendeteksi dan mencegah pelanggaran syarat atau kebijakan Kami serta aktivitas ilegal atau berbahaya lainnya.", LANG(), { en: 'Proktor Amanin uses advanced tools to automatically scan certain types of content such as virtual backgrounds, profile images, chats, to detect and prevent violations of our terms or policies as well as other illegal or harmful activities.' })}
                    </li>
                </ol>
            </li>
            <li>
                {Lang("Berkomunikasi dengan Anda:", LANG(), { en: 'Communicating with You:' })}
                <ol type="i">
                    <li>
                        {Lang("Menggunakan data pribadi (termasuk informasi kontak, dan informasi yang Anda berikan termasuk isi pesan) untuk berkomunikasi dengan Anda tentang produk dan layanan Proktor Amanin, termasuk pembaruan produk, akun Anda, dan perubahan kebijakan dan ketentuan Kami.", LANG(), { en: 'Using personal data (including contact information, and information you provide including message content) to communicate with you about Proktor Amanin products and services, including product updates, your account, and changes to our policies and terms.' })}
                    </li>
                    <li>
                        {Lang("Juga menggunakan informasi Anda untuk merespons Anda saat Anda menghubungi Kami.", LANG(), { en: 'Also using your information to respond to you when you contact us.' })}
                    </li>
                </ol>
            </li>
            <li>
                {Lang("Alasan Hukum:", LANG(), { en: 'Legal Reasoning:' })}
                <ol type="i">
                    <li>
                        {Lang("Untuk mematuhi hukum yang berlaku atau merespons proses hukum yang sah, termasuk dari penegak hukum atau lembaga pemerintah.", LANG(), { en: 'Comply with applicable laws or respond to lawful legal processes, including from law enforcement or government agencies.' })}
                    </li>
                    <li>
                        {Lang("Untuk menyelidiki atau berpartisipasi dalam pemeriksaan perdata, litigasi, atau proses hukum lainnya yang bersifat bersifat adversarial.", LANG(), { en: 'Investigate or participate in civil litigation, or other adversarial legal processes.' })}
                    </li>
                    <li>
                        {Lang("Melindungi Anda, Kami, dan orang lain dari kegiatan yang curang, jahat, menyesatkan, kasar, atau melanggar hukum.", LANG(), { en: 'Protecting you, us, and others from fraudulent, malicious, misleading, abusive, or illegal activities.' })}
                    </li>
                    <li>
                        {Lang("Menegakkan atau menyelidiki pelanggaran potensial terhadap Syarat dan Ketentuan atau kebijakan Kami.", LANG(), { en: 'Enforcing or investigating potential violations of our Terms and Conditions or policies.' })}
                    </li>
                    <li>
                        {Lang("Dalam hal ini, Proktor Amanin menunjukkan pendekatan yang diambilnya dalam menggunakan data pribadi pengguna untuk berbagai kegiatan, dan menekankan penggunaan yang diatur oleh persetujuan atau izin pengguna dan pemilik akun, serta kewajiban hukum, keamanan, dan keselamatan.", LANG(), { en: 'In these instances, Proktor Amanin outlines its approach to using users` personal data for various activities, emphasizing usage governed by user and account owner consent or permission, as well as legal obligations, security, and safety considerations.' })}
                    </li>
                </ol>
            </li>
        </ol>
    </div>
}