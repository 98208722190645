import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _3_BagaimanaKamiMembagiInformasi = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("3. BAGAIMANA KAMI MEMBAGI INFORMASI", LANG(), { en: '3. HOW WE SHARE INFORMATION' })}</span>
        <p>
            {Lang("Proktor Amanin memberikan data pribadi kepada pihak ketiga hanya dengan persetujuan atau dalam salah satu dari situasi berikut (dengan mempertimbangkan persetujuan Anda sebelumnya jika diperlukan menurut hukum yang berlaku):", LANG(), { en: 'Proktor Amanin provides personal data to third parties only with consent or in one of the following situations (taking into account your prior consent if required by applicable law):' })}
        </p>
        <ol type="a">
            <li>
                {Lang("Produk dan Layanan", LANG(), { en: 'Products and Services:' })}
                <ol type="i">
                    <li>
                        {Lang("Proktor Amanin dapat menggunakan informasi Anda yang relevan dengan produk, fitur-fitur dan Layanan yang Kami sediakan, misalnya data audio, video, tangkapan layar, tangkapan wajah, percakapan, dan informasi lainnya yang berasal dari ujian online yang diadakan oleh pengguna atau pemilik lisensi.", LANG(),
                            { en: 'Proktor Amanin may use your information relevant to the products, features, and Services we provide, such as audio data, video data, screenshots, facial captures, conversations, and other information derived from online exams conducted by users or license holders.' })}
                    </li>
                    <li>
                        {Lang(" Data dari para peserta ujian online yang diadakan oleh pengguna atau pemilik lisensi akan digunakan untuk mengevaluasi jalannya ujian online, menentukan  hasil dari ujian online tersebut.", LANG(), { en: 'Data from participants in online exams conducted by users or license holders will be used to evaluate the conduct of the online exams and determine the results of such online exams.' })}
                    </li>
                </ol>
            </li>

            <li>
                {Lang("Vendor:", LANG(), { en: 'Vendors:' })}
                <ol type="i">
                    <li>
                        {Lang("Proktor Amanin bekerja sama dengan penyedia layanan pihak ketiga untuk menyediakan, mendukung, dan meningkatkan produk dan layanan Proktor Amanin serta infrastruktur teknis, dan untuk layanan bisnis seperti pemrosesan pembayaran, termasuk sehubungan dengan pembelian melalui Proktor Amanin App Marketplace.", LANG(),
                            { en: 'Proktor Amanin collaborates with third-party service providers to provide, support, and enhance Proktor Amanin products and services, as well as technical infrastructure, and for business services such as payment processing, including related to purchases through the Proktor Amanin App Marketplace.' })} </li>
                    <li>
                        {Lang("Proktor Amanin juga dapat bekerja sama dengan penyedia layanan pihak ketiga untuk menyediakan iklan dan analisis bisnis mengenai produk dan layanan Proktor Amanin.", LANG(),
                            { en: 'Proktor Amanin may also work with third-party service providers to provide advertising and business analytics regarding Proktor Amanin products and services.' })}
                    </li>
                    <li>
                        {Lang("Vendor ini dapat mengakses data pribadi dengan ketentuan kontraktual dan teknis untuk melindungi data pribadi dan melarang mereka menggunakan data pribadi untuk tujuan selain menyediakan layanan kepada Proktor Amanin atau sebagaimana diwajibkan oleh hukum.", LANG(), { en: 'These vendors may access personal data under contractual and technical provisions to protect personal data and prohibit them from using personal data for purposes other than providing services to Proktor Amanin or as required by law.' })}
                    </li>
                    <li>
                        {Lang(" Proktor Amanin dapat mengintegrasikan teknologi pihak ketiga untuk menyediakan fitur canggih. Informasi ini diproses di perangkat Anda, dan informasi tersebut tidak diterima atau disimpan oleh pihak ketiga atau Proktor Amanin.", LANG(), { en: 'Proktor Amanin may integrate third-party technologies to provide advanced features. This information is processed on your device, and such information is not received or stored by the third party or Proktor Amanin.' })}
                    </li>
                </ol>
            </li>

            <li>
                {Lang("Untuk Alasan Hukum:", LANG(), { en: 'Legal Reasons:' })}
                <p>
                    {Lang("Proktor Amanin dapat membagikan data pribadi sesuai kebutuhan untuk:", LANG(), { en: 'Proktor Amanin may share personal data as necessary to:' })}
                </p>
                <ol type="i">
                    <li>
                        {Lang("Mematuhi hukum yang berlaku atau merespons, menyelidiki, atau berpartisipasi dalam proses hukum yang sah, termasuk dari penegak hukum atau lembaga pemerintah.", LANG(), { en: 'Comply with applicable laws or respond, investigate, or participate in lawful legal processes, including from law enforcement or governmental agencies.' })}
                    </li>
                    <li>
                        {Lang("Menegakkan atau menyelidiki pelanggaran potensial terhadap Syarat dan Ketentuan atau kebijakan Proktor Amanin.", LANG(),
                            { en: 'Enforce or investigate potential violations of Proktor Amanin`s Terms and Conditions or policies' })}
                    </li>
                    <li>
                        {Lang("Mendeteksi, mencegah, atau menyelidiki potensi kecurangan, penyalahgunaan, atau kekhawatiran keamanan dan keselamatan, termasuk ancaman terhadap masyarakat.", LANG(), { en: 'Detect, prevent, or investigate potential fraud, misuse, or security concerns, including threats to public safety.' })}
                    </li>
                    <li>
                        {Lang("Memenuhi komitmen Kami terhadap tanggung jawab sosial dan perusahaan.", LANG(), { en: 'Fulfill our commitments to social and corporate responsibility.' })}
                    </li>
                    <li>
                        {Lang("Melindungi hak dan properti Kami dan pelanggan Kami.", LANG(), { en: 'Protect our rights and property and those of our customers.' })}
                    </li>
                    <li>
                        {Lang("Menyelesaikan sengketa dan menegakkan perjanjian.", LANG(), { en: 'Resolve disputes and enforce agreements.' })}
                    </li>
                </ol>
            </li>


            <li>
                {Lang("Mitra Pemasaran, Periklanan, dan Analisis:", LANG(), { en: 'Marketing, Advertising, and Analytics Partners:' })}
                <p>
                    {Lang("Proktor Amanin menggunakan penyedia pemasaran, periklanan, dan analisis pihak ketiga:", LANG(), { en: 'Proktor Amanin uses third-party marketing, advertising, and analytics providers to:' })}
                </p>
                <ol type="i">
                    <li>
                        {Lang("Untuk menyediakan statistik dan analisis tentang bagaimana orang menggunakan produk dan layanan Proktor Amanin, termasuk situs web Kami.", LANG(), { en: 'Provide statistics and analytics on how people use Proktor Amanin products and services, including our website.' })}
                    </li>
                    <li>
                        {Lang("Untuk menyediakan iklan dan pemasaran untuk produk dan layanan Proktor Amanin, termasuk iklan tertarget berdasarkan penggunaan situs web Kami.", LANG(), { en: 'Provide advertising and marketing for Proktor Amanin products and services, including targeted advertising based on the use of our website.' })}
                    </li>
                </ol>
            </li>


            <li>
                {Lang("Perubahan Pengendalian:", LANG(), { en: 'Change of Control:' })}
                <ol type="i">
                    <li>
                        {Lang("Proktor Amanin dapat berbagi data pribadi dengan perusahaan yang benar-benar atau potensial sebagai pihak yang membeli, perwakilan mereka, dan peserta relevan lainnya dalam, atau selama negosiasi, penjualan, penggabungan, akuisisi, restrukturisasi, atau perubahan pengendalian seluruhnya atau sebagian dari bisnis atau aset Proktor Amanin, termasuk sehubungan dengan kepailitan atau proses serupa.", LANG(), { en: 'Proktor Amanin may share personal data with companies that are wholly or potentially purchasing parties, their representatives, and relevant participants in, or during negotiations for, the sale, merger, acquisition, restructuring, or change of control, wholly or partially, of Proktor Amanin`s business or assets, including in connection with bankruptcy or similar processes' })}
                    </li>
                </ol>
            </li>
        </ol>
    </div>
}