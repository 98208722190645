import React from "react";
import classes from "./Menu.module.scss";

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closeState: 'open',// close, open, hide
            openState: 'close',// close, open, hide
        }
    }

    generateLine(n = 3) {
        let d = [];
        for (let i = 0; i < 3; i++) {
            d.push(<div key={i} className={[
                this.state.closeState === 'open' ?
                    classes.open :
                    classes.close,
                classes.fadein1s].join(" ")}
            ></div>);
        }
        return d;
    }

    generateX() {
        return <div className={[
            classes.x,
            // this.state.closeState === 'open' ?
            //     classes.hopen :
            //     classes.hclose,
            classes.fadein1s,
        ].join(" ")}>
            <div className={classes.x_1}></div>
            <div className={classes.x_2}></div>
        </div>
    }

    render() {
        return <div className={classes.container}
            onClick={() => {
                if (this.state.closeState === 'open') {
                    this.setState({
                        closeState: 'close'
                    })
                    this.props.onOpen();
                    setTimeout(() => { this.setState({ closeState: 'hide' }) }, 400)

                } else if (this.state.closeState === 'hide') {
                    this.setState({
                        closeState: 'close'
                    })
                    this.props.onClose();
                    setTimeout(() => { this.setState({ closeState: 'open' }) }, 10)
                }
            }}
        >
            {this.state.closeState !== 'hide' && <>
                {/* 3 lines */}
                {this.generateLine()}
            </>}
            {
                this.state.closeState === 'hide' && <>
                    {/* <span className={[classes.x, classes.fadein1s].join(" ")}>X</span> */}
                    {this.generateX()}
                </>
            }
        </div>
    }
}

export default Menu;