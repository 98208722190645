import classes from "./Body3.module.scss"
import amanin_desc3 from "../../assets/imgs/content/amanin_desc3.jpg";
import { generatePricePlan } from "./PricePlan";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

export const generateBody3 = (_this) => {
    return <div className={classes.container}>
        <div className={classes.container_content}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor Freedom</span>
                <span className={classes.title2}>
                    {/* Dengan senang hati kami menyediakan fleksibilitas untuk memenuhi kebutuhan khusus Anda.
                Apabila Anda memiliki permintaan perubahan sesuai dengan kebutuhan tertentu, jangan ragu untuk menghubungi tim kami. 
                Kami berkomitmen untuk menyediakan pengalaman yang sesuai dengan kebutuhan Anda. */}
                    {Lang(` Dengan senang hati kami menyediakan fleksibilitas untuk memenuhi kebutuhan khusus Anda.`, LANG(), {
                        en: `We are happy to provide flexibility to meet your specific needs.`
                    })}
                    {Lang(`Apabila Anda memiliki permintaan perubahan sesuai dengan kebutuhan tertentu, jangan ragu untuk menghubungi tim kami.`, LANG(), {
                        en: `If you have a specific requirement change request, please do not hesitate to contact our team.`
                    })}
                    {Lang(`Kami berkomitmen untuk menyediakan pengalaman yang sesuai dengan kebutuhan Anda.`, LANG(), {
                        en: `We are committed to providing an experience that suits your needs.`
                    })}

                </span>
                {/* <span className={classes.content}>
                    <ul>
                        <li>AI membantu anda dalam mendeteksi kecurangan yang mungkin dilakukan</li>
                        <li>Fitur Screenshot, Notes dan Flags memudahkan Anda menandai peserta yang dianggap mencurigakan</li>
                    </ul>
                </span> */}
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc3}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"}
                />
            </div>
        </div>
    </div>
}