import arrowup from "../../assets/imgs/arrowup.png";
import classes from './VisionMission.module.scss';

// import img1 from "../../assets/imgs/content/img1.jpg";
// import img2 from "../../assets/imgs/content/img2.jpg";
// import img4 from "../../assets/imgs/content/img4.jpg";
// import img5 from "../../assets/imgs/content/img5.jpg";

// import slide_h1 from "../../assets/imgs/content/amanin_small1(id).png";
// import slide_h1_en from "../../assets/imgs/content/amanin_small1(en).png";
// import slide_h2 from "../../assets/imgs/content/amanin_small2(id).png";
// import slide_h2_en from "../../assets/imgs/content/amanin_small2(en).png";
// import slide_v2 from "../../assets/imgs/content/amanin_small3(id).png";
// import slide_v2_en from "../../assets/imgs/content/amanin_small3(en).png";
// import slide_v1 from "../../assets/imgs/content/amanin_small4(id).png";
// import slide_h4 from "../../assets/imgs/content/amanin_small5(id).png";
// import slide_h4_en from "../../assets/imgs/content/amanin_small5(en).png";
// import slide_v3 from "../../assets/imgs/content/amanin_small6(id).png";
// import slide_v3_en from "../../assets/imgs/content/amanin_small6(en).png";
// import slide_h3 from "../../assets/imgs/content/amanin_small7(id).png";
// import slide_h3_en from "../../assets/imgs/content/amanin_small7(en).png";
// import slide_v4 from "../../assets/imgs/content/amanin_small8(id).png";
// import slide_v4_en from "../../assets/imgs/content/amanin_small8(en).png";


import amanin_desc1 from "../../assets/imgs/content/amanin_desc1.jpg";
import amanin_desc2 from "../../assets/imgs/content/amanin_desc2.jpg";
import amanin_desc3 from "../../assets/imgs/content/amanin_desc3.jpg";
import amanin_desc4 from "../../assets/imgs/content/amanin_desc4.jpg";
import amanin_desc5 from "../../assets/imgs/content/amanin_desc5.jpg";
import amanin_desc6 from "../../assets/imgs/content/amanin_desc6.jpg";
import amanin_desc7 from "../../assets/imgs/content/amanin_desc7.jpg";
import amanin_desc8 from "../../assets/imgs/content/amanin_desc8.jpg";
import ExamNowButton from "../../components/ExamNow";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";
import { slide_h1, slide_h2, slide_h3, slide_v1, slide_v2, slide_v3, slide_v4 } from "../../assets/imgs";

const content = [
    {
        title: Lang(`Pengawasan Ujian Online Berkualitas, Di Mana Saja, Kapan Saja`, LANG(), {
            en: `Best Quality of Online Exam Proctoring, Anywhere, Anytime`
        }),
        text: Lang(`Kami mendesain layanan pengawasan ujian online ini untuk keamanan dan kenyamanan peserta dan pengawas, menuju ujian online berintegritas`, LANG(), {
            en: `We designed this online exam proctoring service for the safety and convenience of participants and proctors, towards online exams with integrity.`
        }),
        big_img1: amanin_desc1,
        big_img2: amanin_desc2,
        small_img1: slide_h1,
        small_img2: slide_v1,
        class_img1: classes.img_h1,
        class_img2: classes.img_v1
    },
    {
        title: Lang(`Fleksibel, Tanpa Infrastruktur, Dan Mudah Digunakan`, LANG(), {
            en: `Flexible, Infrastructure-less, and Easy to Use`
        }),
        text: Lang(`Peserta dan pengawas dapat mengikuti ujian online darimana saja dan kapan saja`, LANG(), {
            en: `Participants and proctors can take online exams from anywhere and at any time`
        }),
        big_img1: amanin_desc3,
        big_img2: amanin_desc4,
        small_img1: slide_h2,
        small_img2: slide_v2,
        class_img1: classes.img_h2,
        class_img2: classes.img_v2
    },
    {
        title: Lang(`Fitur Berbasis AI`, LANG(), {
            en: `AI-Based Features`
        }),
        text: Lang(`Fitur AI menjadi salah satu fitur yang membantu pengawas dalam menentukan keputusan-keputusan di setiap ujian`, LANG(), {
            en: `The AI feature is one of the features that help proctors make decisions in each exam.`
        }),
        big_img1: amanin_desc5,
        big_img2: amanin_desc6,
        small_img1: slide_h3,
        small_img2: slide_v3,
        class_img1: classes.img_h3,
        class_img2: classes.img_v3
    },
    {
        title: Lang(`Mudah Digunakan`, LANG(), {
            en: `Easy to Use`
        }),
        text: Lang(`Didesain untuk memberikan pengalaman penggunaan yang mudah, namun tetap mengutamakan keamanan`, LANG(), {
            en: `Designed to provide a user-friendly experience while still prioritizing security.`
        }),
        big_img1: amanin_desc7,
        big_img2: amanin_desc8,
        small_img1: slide_h2,
        small_img2: slide_v4,
        class_img1: classes.img_h2,
        class_img2: classes.img_v4
    },
];

export const __VisionMission__ = function (_this) {
    this.getBodyContentLength = () => {
        return content.length;
    }

    this.render = () => {
        const generateButton = (_this) => {
            const vd = _this.state.desktopView
            return <div className={[
                classes.button,
                vd ? null : classes.mobile
            ].join(" ")}
            >
                <div className={[
                    vd ? null : classes.mobile
                ].join(" ")}
                >
                    <img
                        alt="arrow"
                        className={vd ? null : classes.imb}
                        src={arrowup}

                        onClick={() => {
                            _this.scrollToProduct()
                        }}
                    />
                    <span className={vd ? null : classes.mb}>
                        {Lang(`Pelajari Lebih Lanjut`, LANG(), { en: "Learn More" })}
                    </span>
                </div>
            </div>
        }

        const generateContent = (title, text, big_img1, big_img2, small_img1, small_img2, class_img1, class_img2) => {
            const action_class = _this.state.body.closeContent === 'true' ? classes.fadeout : _this.state.body.closeContent === 'false' ? classes.fadein : classes.opacity_0;

            return <div
                className={[classes.content].join(" ")}
            >
                <div
                    className={classes.content_left}
                >
                    <div
                        className={classes.content_left_c}
                    >
                        <div>
                            <h1
                                className={[
                                    "text-primary",
                                    action_class
                                ].join(" ")}
                            >
                                {title}
                            </h1>
                            <p className={["display-5",
                                action_class
                            ].join(" ")}
                                style={{
                                    maxWidth: "550px",
                                    border: "0px solid black",
                                    fontSize: "1.1rem",
                                    fontWeight: "500",
                                    marginTop: "20px",
                                    minHeight: "100px"
                                }}
                            >
                                {text}
                            </p>
                            <div className={classes.loading}
                            >
                                <div style={{
                                    width: _this.state.body.loading
                                }}
                                ></div>
                            </div>
                            <ExamNowButton />
                            {generateButton(_this)}
                        </div>
                    </div>
                </div>
                <div className={classes.content_right}>
                    <div className={classes.content_right_c}>
                        {generateImageSlide1(_this, big_img1, big_img2, small_img1, small_img2, class_img1, class_img2)}
                    </div>
                </div>
            </div>
        }
        const generateContentMobile = (title, text, big_img1, big_img2, small_img1, small_img2, class_img1, class_img2) => {
            const action_class = _this.state.body.closeContent === 'true' ? classes.fadeout : _this.state.body.closeContent === 'false' ? classes.fadein : classes.opacity_0;

            return <div
                className={[classes.content, classes.mobile].join(" ")}
            >
                <div
                    className={[classes.content_left, classes.mobile].join(" ")}
                >
                    <div
                        className={[classes.content_left_c, classes.mobile].join(" ")}
                    >
                        <div className={classes.mobile}>
                            <h1
                                className={[
                                    classes.mobile,
                                    action_class,
                                    classes.mbt
                                ].join(" ")}
                            >
                                {title}
                            </h1>
                            <p className={[
                                classes.mobile,
                                // _this.state.body.closeContent ? classes.fadeout : classes.fadein,
                                action_class,

                                classes.mb
                            ].join(" ")}>
                                {text}
                            </p>
                            <div className={classes.loading}
                            >
                                <div style={{
                                    width: _this.state.body.loading
                                }}
                                ></div>
                            </div>
                            <ExamNowButton />
                            {generateButton(_this)}

                        </div>
                    </div>
                </div>
                <div className={[
                    classes.content_right,
                    classes.mobile].join(" ")}>
                    <div className={[
                        classes.content_right_c,
                        classes.mobile
                    ].join(" ")}>
                        {/* IMAGE SLIDE IS HERE */}
                        {generateImageSlide1(_this, big_img1, big_img2, small_img1, small_img2, class_img1, class_img2)}
                    </div>
                </div>
            </div>
        }

        const generateImageSlide1 = (_this, big_img1, big_img2, small_img_horizontal, small_img_vertical, class_img1, class_img2) => {
            const action_class = _this.state.body.closeContent === 'true' ? classes.fadeout : _this.state.body.closeContent === 'false' ? classes.fadein : classes.opacity_0;
            const vd = _this.state.desktopView
            return <div className={[
                classes.imgslide,
                // _this.state.body.closeContent ? classes.fadeout : classes.fadein,
                action_class,
                vd ? null : classes.mobile
            ].join(" ")}>
                <img alt="slide" className={[classes.img1, vd ? classes.desktop : classes.mobile].join(" ")} src={big_img1} />
                <img alt="slide" className={[classes.img2, vd ? classes.desktop : classes.mobile].join(" ")} src={big_img2} />
                <img alt="slide" className={[class_img1, vd ? classes.desktop : classes.mobile].join(" ")} src={small_img_horizontal} />
                <img alt="slide" className={[class_img2, vd ? classes.desktop : classes.mobile].join(" ")} src={small_img_vertical} />

            </div>
        }

        const ctn = content[_this.state.body.content_id];
        return <div style={{
        }}
            className={classes.container}
        >
            {
                _this.state.desktopView ? generateContent(
                    ctn.title,
                    ctn.text,
                    ctn.big_img1,
                    ctn.big_img2,
                    ctn.small_img1,
                    ctn.small_img2,
                    ctn.class_img1,
                    ctn.class_img2
                ) : generateContentMobile(
                    ctn.title,
                    ctn.text,
                    ctn.big_img1,
                    ctn.big_img2,
                    ctn.small_img1,
                    ctn.small_img2,
                    ctn.class_img1,
                    ctn.class_img2
                )
            }
        </div>
    }
}