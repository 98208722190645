import classes from "./Body1.module.scss"
import amanin_desc1 from "../../assets/imgs/content/amanin_desc6.jpg";
import { generatePricePlan } from "./PricePlan";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

export const generateBody1 = (_this) => {
    return <div className={classes.container}>
        <div className={classes.container_content}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor Freedom</span>
                <span className={classes.title2}>
                    {/* Pengaturan Ujian dapat disesuaikan dengan kebutuhan Anda secara lebih spesifik */}
                    {Lang(`Pengaturan Ujian dapat disesuaikan dengan kebutuhan Anda secara lebih spesifik`, LANG(), {
                        en: `Test Setup can be tailored to your specific needs`
                    })}
                </span>
                <span className={classes.content}>
                    {/* Produk kami dirancang untuk memberikan fleksibilitas yang dibutuhkan untuk penyesuaian yang luas. */}
                    {Lang(`Produk kami dirancang untuk memberikan fleksibilitas yang dibutuhkan untuk penyesuaian yang luas.`, LANG(), {
                        en: `Our products are designed to provide the flexibility needed for extensive customization.`
                    })}
                </span>
                <span className={classes.content}>
                    {/* Dengan fitur - fitur yang dapat disesuaikan, kami menyediakan solusi yang dapat diandalkan untuk tantangan - tantangan unik di kasus anda. */}
                    {Lang(`Dengan fitur - fitur yang dapat disesuaikan, kami menyediakan solusi yang dapat diandalkan untuk tantangan - tantangan unik di kasus anda.`, LANG(), {
                        en: `With customizable features, we provide reliable solutions for your unique challenges.`
                    })}
                </span>
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc1}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"}
                />
            </div>
        </div>
    </div>
}