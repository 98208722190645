import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";
import classes from "./PricePlan.module.scss";

export const generatePricePlan = (_this) => {
    return <div className={classes.container}>
        <div>
            <div className={classes.package}
                onClick={() => {
                    window.location.href = "/priceplan";
                }}
            >{Lang("Lihat Paket", LANG(), { en: 'View Package' })}</div>
            <div className={classes.free}
                onClick={() => {
                    window.location.href = "/sales"
                }}
            >{Lang(`Hubungi Kami`, LANG(), { en: `Contact Us` })}</div>
        </div>
    </div>
}