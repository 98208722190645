import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Switch, Route, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PricePlanPage from './pages/PricePlanPage/__index__';
import ProktorFirstLM from './pages/ProktorFirstLM/ProktorFirstLM';
import SalesPage from './pages/SalesPage/__index__';
import ProktorFreedomLM from './pages/ProktorFreedomLM/ProktorFreedomLM';
import ProktorEventLM from './pages/ProktorEventLM/ProktorEventLM';
import CaseStudyPage from './pages/CaseStudyPage/__index__';
import FaqPage from './pages/FAQ/__index__';
import AboutUsPage from './pages/AboutUs/AboutUs';
import HomePage from './pages/HomePage/__index__';
import DownloadPage from './pages/Download/__index__';
import TermsConditions from './pages/TermsConditions/__index__';
import PrivacyPolicy from "./pages/PrivacyPolicy/__index__";
import Troubleshooting from './pages/Troubleshooting/__index__';
import Blogs from './pages/Blogs/__index__';

function App() {
  return (
    <Switch>
      <Route path="/index" exact >
        <HomePage />
      </Route>

    <Route path="/download" exact>
      <DownloadPage />
    </Route>
      <Route path="/priceplan" exact>
        <PricePlanPage />
      </Route>

      <Route path="/proktorfirstlearnmore" exact>
        <ProktorFirstLM />
      </Route>

      <Route path="/proktorfreedomlearnmore" exact>
        <ProktorFreedomLM />
      </Route>

      <Route path="/proktoreventlearnmore" exact>
        <ProktorEventLM />
      </Route>

      <Route path="/casestudy" exact>
        <CaseStudyPage />
      </Route>

      <Route path="/sales" exact>
        <SalesPage />
      </Route>
      <Route path="/contactus" exact>
        <SalesPage />
      </Route>

      <Route path="/faq" exact>
        <FaqPage />
      </Route>

      <Route path="/troubleshooting" exact>
        <Troubleshooting />
      </Route>

      <Route path="/blog/" exact>
        <Blogs />
      </Route>

      <Route path="/blog/:postId" exact>
        <Blogs />
      </Route>

      <Route path="/aboutus" exact>
        <AboutUsPage />
      </Route>

      <Route path="/termsandconditions" exact>
        <TermsConditions />
      </Route>

      <Route path="/privacypolicy" exact>
        <PrivacyPolicy />
      </Route>

      <Route path="*" component={(props) => {
        return <Redirect to={"/index"} />
      }}>
      </Route>
    </Switch>
  );
}

export default App;
