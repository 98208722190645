import classes from "./Body4.module.scss"
import amanin_desc2 from "../../assets/imgs/content/amanin_desc8.jpg";
import { generatePricePlan } from "./PricePlan";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

export const generateBody4 = (_this) => {
    return <div className={classes.container}>
        <div className={classes.container_content}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor First</span>
                <span className={classes.title2}>
                    {/* Memudahkan Anda memulai sebuah ujian */}
                    {Lang(`Memudahkan Anda memulai sebuah ujian`, LANG(), {
                        en: `Make it easy for you to start an exam`
                    })}
                </span>
                <span className={classes.content}>
                    <ul>
                        {/* <li>Anda cukup membuat ujian lalu membagi kode ujiannya ke peserta. Peserta dapat mengikuti ujian darimana saja</li>
                        <li>Pengawasan ujian berbasis web, ringan, mudah digunakan dan fleksibel</li> */}
                        <li>
                            {Lang(`Anda cukup membuat ujian lalu membagi kode ujiannya ke peserta. Peserta dapat mengikuti ujian darimana saja`, LANG(), {
                                en: `You simply create an exam and then share the exam code with the participants. Participants can take the exam from anywhere`
                            })}
                        </li>
                        <li>
                            {Lang(`Pengawasan ujian berbasis web, ringan, mudah digunakan dan fleksibel`, LANG(), {
                                en: `Web-based exam proctoring, lightweight, easy to use and flexible`
                            })}
                        </li>

                    </ul>
                </span>
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc2}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"}
                />
            </div>
        </div>
    </div>
}