import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _16_PemberitahuanJaminan = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("17. PENAFIAN JAMINAN", LANG(), { en: '17. DISCLAIMER OF WARRANTIES' })}
        </span>
        <p>
            {Lang("LAYANAN INI DISEDIAKAN “APA ADANYA”, TANPA JAMINAN APA PUN. TANPA MEMBATASI HAL-HAL DI ATAS, KAMI SECARA TEGAS MENOLAK SEMUA JAMINAN, BAIK TERSURAT, TERSIRAT MAUPUN HUKUM, TENTANG LAYANAN TERMASUK, TAK TERBATAS, JAMINAN APAPUN ATAS KELAYAKAN UNTUK DIPERDAGANGKAN, KESESUAIAN UNTUK TUJUAN TERTENTU, JUDUL, KEAMANAN, KEAKURATAN DAN KETIADAAN PELANGGARAN HAK. TANPA MEMBATASI HAL TERSEBUT, KAMI TIDAK MEMBERIKAN JAMINAN ATAU PERNYATAAN BAHWA AKSES ATAU PENGOPERASIAN LAYANAN TIDAK AKAN TERGANGGU ATAU BEBAS DARI KESALAHAN. ANDA BERTANGGUNG JAWAB PENUH DAN RISIKO KERUGIAN AKIBAT PENGUNDUHAN DAN/ATAU PENGGUNAAN FILE, INFORMASI, ISI ATAU MATERI LAINNYA YANG DIPEROLEH DARI LAYANAN. BEBERAPA YURISDIKSI MEMBATASI ATAU TIDAK MENGIZINKAN PENOLAKAN GARANSI, SEHINGGA KETENTUAN INI MUNGKIN TIDAK BERLAKU BAGI ANDA.", LANG(), { en: 'THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, REGARDING THE SERVICES INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY, AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM DOWNLOADING AND/OR USING FILES, INFORMATION, CONTENT, OR OTHER MATERIAL OBTAINED FROM THE SERVICES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF WARRANTIES, SO THIS PROVISION MAY NOT APPLY TO YOU.' })}
        </p>
    </div>
}