import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _1_InformasiYang = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("1. INFORMASI YANG KAMI KUMPULKAN", LANG(), { en: '1. INFORMATION WE COLLECT' })}</span>
        <p>
            {Lang("Kami mengumpulkan beberapa tipe informasi tentang Anda :", LANG(), { en: 'We collect various types of information about you:' })}
        </p>
        <ol type="a">
            <li>
                <b>{Lang("Informasi Akun", LANG(), { en: 'Account Information' })}</b> {Lang(": Informasi mengenai akun yang memiliki lisensi produk dan layanan Proktor Amanin. Informasi tersebut bisa berupa nama pemilik akun, informasi kontak, id akun, dan informasi penagihan.", LANG(),
                    { en: ': Information related to accounts that hold licenses for Proktor Amanin`s products and services. This may include the account holder’s name, contact details, account ID, and billing information.' })}
            </li>
            <li>
                <b>{Lang("Informasi Pengguna dan Peserta", LANG(), { en: 'User and Participant Information' })}</b> {Lang(":  Informasi yang berkaitan dengan pengguna yang memiliki lisensi dan menggunakan produk dan layanan Proktor Amanin atau pengguna yang tidak memiliki lisensi, atau peserta ujian online yang berpartisipasi pada ujian online yang diadakan oleh pengguna berlisensi. Informasi tersebut dapat meliputi nama, nama pengguna, foto, alamat email, nomor telepon, lokasi, ID pengguna, dan informasi lainnya yang disediakan oleh pengguna, atau peserta, ataupun oleh pemilik lisensi.", LANG(),
                    { en: ': Information concerning users with or without licenses for Proktor Amanin`s products and services or online exam participants involved in online exams conducted by licensed users. This information may include names, usernames, photos, email addresses, phone numbers, locations, user IDs, and other details provided by users or participants, or by license holders.' })}
            </li>
            <li>
                <b>{Lang("Pengaturan", LANG(), { en: 'Settings' })}</b> {Lang(": Informasi yang berkaitan dengan preferensi dan pengaturan setelan akun Anda, yang mungkin mencakup pengaturan suara dan video, penyimpanan file rekaman, pengaturan ujian online, pengaturan peserta ujian, dan pengaturan serta informasi konfigurasi lainnya.", LANG(), { en: ': Information related to your account preferences and configuration settings, which may include audio and video settings, recording file storage, online exam settings, exam participant settings, and other configuration and preference information.' })}
            </li>
            <li>
                <b>{Lang("Informasi Saat Registrasi", LANG(), { en: 'Registration Information' })}</b> {Lang(": Informasi yang Anda berikan saat mendaftar akun, saat mendaftar atau mengikuti ujian online, rekaman saat berlangsungnya ujian online, misalnya berupa nama, alamat email, foto ID, dan informasi lainnya yang diminta oleh pembuat/penyelenggara ujian.", LANG(), { en: ': Information you provide when creating an account, registering for, or participating in online exams, including records during online exams, such as names, email addresses, photo IDs, and other information required by the exam creators/organizers.' })}
            </li>
            <li>
                <b>{Lang("Informasi Perangkat Keras dan Perangkat Lunak", LANG(), { en: 'Hardware and Software Information:' })}</b> {Lang(":  Informasi tentang komputer dan perangkat lain yang digunakan saat berinteraksi dengan platform dan layanan Proktor Amanin, yang mungkin mencakup informasi tentang speaker, mikrofon, kamera, versi sistem operasi, ID hard disk, nama PC, alamat MAC, alamat IP (yang mungkin digunakan untuk menyimpulkan lokasi umum di tingkat kota atau negara), atribut perangkat (seperti versi sistem operasi dan tingkat baterai), informasi WiFi, dan informasi perangkat lainnya (seperti sinyal Bluetooth).", LANG(),
                    { en: ': Information about computers and other devices used when interacting with Proktor Amanin`s platform and services, which may include details about speakers, microphones, cameras, operating system versions, hard disk IDs, PC names, MAC addresses, IP addresses (which may be used to infer general location at the city or country level), device attributes (such as operating system versions and battery levels), WiFi information, and other device details (such as Bluetooth signals).' })}
            </li>
            <li>
                <b>{Lang("Informasi Isi dan Konten dari Ujian Online", LANG(), { en: 'Online Exam Content and Information' })}</b> {Lang(": Informasi mengenai berlangsungnya ujian online yang diadakan, soal ujian yang dikerjakan, termasuk tentang rekaman suara, rekaman video, tangkapan layar komputer, tangkapan layar kamera, pertukaran pesan yang berlangsung selama proses sebelum, saat dan sesudah ujian online berlangsung.", LANG(),
                    { en: ': Information related to the conduct of online exams, including exam questions worked on, as well as audio recordings, video recordings, computer screenshots, camera screenshots, and message exchanges that occur before, during, and after the online exam.' })}
            </li>
            <li>
                <b>{Lang("Informasi Kegiatan Ujian Online", LANG(), { en: 'Online Exam Activity Information' })}</b> {Lang(": Informasi ini mencakup data tentang bagaimana orang dan perangkat mereka berinteraksi dengan platform dan layanan Proktor Amanin, meliputi : informasi mengenai partisipasi peserta dan pihak lainnya saat berlangsungnya ujian online, informasi mengenai kinerja dan fitur-fitur produk dan layanan Proktor Amanin yang digunakan saat berlangsungnya ujian online, informasi tentang aplikasi atau konten pihak ketiga yang digunakan saat berlangsungnya ujian online, informasi interaksi antara pengguna dengan platform dan layanan Proktor Amanin, dan informasi penggunaan lainnya. Informasi-informasi tersebut akan membantu Proktor Amanin untuk memahami produk, mendesain produk, dan menambah fitur, serta meningkatkan kinerja produk dan layanan Proktor Amanin.", LANG(),
                    { en: ': This includes data about how individuals and their devices interact with Proktor Amanin`s platform and services, such as participation information of exam takers and other parties during online exams, performance data, and features of Proktor Amanin`s products and services used during online exams, information about third-party applications or content used during online exams, user interactions with Proktor Amanin`s platform and services, and other usage information. This information helps Proktor Amanin understand products, design products, add features, and enhance the performance of Proktor Amanin`s products and services.' })}
            </li>
            <li>
                <b>{Lang("Informasi Saat Berkomunikasi dengan Proktor Amanin", LANG(), { en: 'Communication with Proktor Amanin' })}</b> {Lang(": Informasi ini mencakup data tentang komunikasi Anda dengan Proktor Amanin, termasuk namun tidak terbatas pada:", LANG(), { en: ': This includes data about your communications with Proktor Amanin, including but not limited to:' })}
                <ol type="i">
                    <li>
                        {Lang("Pertanyaan Dukungan: Pertanyaan atau permintaan dukungan yang Anda ajukan kepada Proktor Amanin.", LANG(), { en: 'Support Inquiries: Questions or support requests submitted to Proktor Amanin.' })}
                    </li>
                    <li>
                        {Lang("Obrolan Virtual di Situs Web: Komunikasi melalui obrolan virtual di situs web Proktor Amanin.", LANG(), { en: 'Website Virtual Chats: Communications via virtual chat on Proktor Amanin’s website.' })}
                    </li>
                    <li>
                        {Lang("Akun Anda: Informasi terkait dengan akun Proktor Amanin Anda.", LANG(), { en: 'Your Account: Information related to your Proktor Amanin account.' })}
                    </li>
                    <li>
                        {Lang("Umpan Balik Pengguna: Tanggapan atau umpan balik yang diberikan oleh pengguna kepada Proktor Amanin tentang produk dan layanan Proktor Amanin.", LANG(), { en: 'User Feedback: Feedback or responses provided by users about Proktor Amanin`s products and services.' })}
                    </li>
                    <li>
                        {Lang("Pertanyaan Lainnya: Pertanyaan atau permintaan lain yang Anda ajukan kepada Proktor Amanin.", LANG(), { en: 'Other Inquiries: Other questions or requests you submit to Proktor Amanin.' })}
                    </li>
                </ol>
                {Lang("Informasi ini membantu Proktor Amanin dalam menyediakan dukungan yang lebih baik, memahami kebutuhan pengguna, dan meningkatkan produk dan layanan Kami berdasarkan umpan balik yang diterima dari pengguna.", LANG(), { en: 'This information helps Proktor Amanin provide better support, understand user needs, and improve our products and services based on feedback received from users.' })}
            </li>
            <li>
                <b>{Lang("Informasi dari Mitra", LANG(), { en: 'Information from Partners' })} </b>{Lang(": Proktor Amanin memperoleh informasi tentang pemilik akun , peserta ujian online, dan pengguna dari perusahaan pihak ketiga, seperti layanan pengayaan data pasar, yang mencakup informasi tentang ukuran atau industri perusahaan pemilik akun, informasi kontak, atau aktivitas di domain perusahaan tertentu. Proktor Amanin juga dapat memperoleh informasi dari mitra periklanan pihak ketiga yang menyampaikan iklan yang ditampilkan pada produk dan layanan Proktor Amanin, seperti apakah Anda mengklik iklan yang mereka tampilkan kepada Anda.", LANG(),
                    { en: ': Proktor Amanin obtains information about account holders, online exam participants, and users from third-party companies, such as market data enrichment services, which may include information about the size or industry of the account holder`s company, contact information, or activities within a specific company domain. Proktor Amanin may also obtain information from third-party advertising partners that deliver ads displayed on Proktor Amanin`s products and services, such as whether you clicked on the ads they showed you' })}
            </li>
        </ol>
    </div>
}