import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const ProktorFreedomParticipantFAQ = {
    title: Lang(`Proktor Freedom - Peserta`, LANG(), { en: 'Proktor Freedom - Participant' }),
    qna: {
        id: [
            {
                q: "Apakah fitur keamanan tetap ada di bagian Peserta Proktor Freedom ?",
                a: `Ya, seluruh fitur keamanan yang terdapat di Proktor First tetap ada di bagian Peserta Proktor Freedom. `
            },
            {
                q: "Apa saja perangkat keras dan perangkat lunak yang perlu disediakan oleh Peserta ?",
                a: `Anda membutuhkan sebuah PC dengan web kamera, sambungan internet, dan email. Peserta disarankan untuk menggunakan komputer dengan prosesor setara intel celeron dengan RAM setara 4GB. Untuk sistem operasi peserta bebas menggunakan apa saja asal browsernya menggunakan Google Chrome.`
            }
        ],
        en: [
            {
                q: "Do the security features still exist in the Participant section of Proktor Freedom?",
                a: `Yes, all security features available in Proktor First remain in the Participant section of Proktor Freedom.`
            },
            {
                q: "What hardware and software do participants need to provide?",
                a: `Participants need a PC with a webcam, internet connection, and email. It is recommended to use a computer with a processor equivalent to Intel Celeron and RAM equivalent to 4GB. Participants can use any operating system as long as the browser is Google Chrome.`
            }
        ]
    }
}

export const ProktorFreedomProctorerFAQ = {
    title: Lang(`Proktor Freedom - Penyelenggara Ujian`, LANG(), { en: 'Proktor Freedom - Exam Organizer' }),
    qna: {
        id: [
            {
                q: "Apa perbedaan Proktor Freedom dengan Proktor First dan Proktor Event ?",
                a: `Proktor Freedom merupakan produk yang dikhususkan bagi penyelenggara ujian yang ingin menyesuaikan platform sesuai dengan kebutuhannya yang lebih rumit, kompleks dan detail serta tidak dapat diakomodasi oleh pengaturan yang terdapat di fitur-fitur Proktor First maupun Proktor Event `
            },
            {
                q: "Apakah ada integrasi dengan LMS atau platform penyedia soal ?",
                a: `Ya, Anda dapat mengintegrasikan dengan LMS atau platform penyedia soal yang Anda pilih.`
            },
            {
                q: "Apakah saya bisa menyesuaikan antarmuka sesuai keinginan saya ?",
                a: `Ya, Anda dapat menyesuaikan antarmuka platform sesuai keinginan Anda `
            },
            {
                q: "Berapa biaya yang harus saya keluarkan jika memilih menggunakan Proktor Freedom ?",
                a: `Biaya yang dikeluarkan tentu disesuaikan dengan tingkat kompleksitas penyesuaian, tambahan fitur-fitur yang diinginkan dan penyesuaian-penyesuaian lainnya. Untuk lebih detailnya Anda dapat menghubungi tim kami.`
            },
            {
                q: "Apakah fitur-fitur yang terdapat di Proktor First tetap ada di Proktor Freedom ?",
                a: `Ya, Anda tetap mendapatkan fitur-fitur Proktor First di Proktor Freedom.`
            },
            {
                q: "Apakah tetap ada fitur Post Proktor ?",
                a: `Ya, Anda tetap mendapatkan fitur Post Proktor di Proktor Freedom.`
            }
        ], en: [
            {
                q: "What are the differences between Proktor Freedom, Proktor First, and Proktor Event?",
                a: "Proktor Freedom is a product designed for exam organizers who need to customize the platform to meet more complex, detailed, and specific needs that cannot be accommodated by the features of Proktor First or Proktor Event."
            },
            {
                q: "Is there integration with LMS or question provider platforms?",
                a: "Yes, you can integrate with LMS or the question provider platform of your choice."
            },
            {
                q: "Can I customize the interface according to my preferences?",
                a: "Yes, you can customize the platform interface according to your preferences."
            },
            {
                q: "What are the costs associated with using Proktor Freedom?",
                a: "The costs depend on the level of customization complexity, additional desired features, and other adjustments. For more details, you can contact our team."
            },
            {
                q: "Are the features available in Proktor First also available in Proktor Freedom?",
                a: "Yes, you still get the features of Proktor First in Proktor Freedom."
            },
            {
                q: "Does Post Proctor feature still exist?",
                a: "Yes, you still get the Post Proctor feature in Proktor Freedom."
            }
        ]
    }
}
