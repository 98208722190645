import React from "react";
import classes from "./Nav.module.scss";
import Brand from "./Brand";
import { DISABLE_START_FREE, handleScreenChange, LANG } from "../libs/utils";
import Menu from "./Menu";
import { withRouter } from "react-router-dom"
import { Lang } from "../libs/Language";
import Language from "./Language";

class NavPage extends React.Component {
    constructor(props) {
        super(props);

        this.options = [
            {
                enable: !props.location.pathname.includes("index") ? true : false,
                title: "Home",
                url: "/index",
                action: null
            },
            {
                enable: props.location.pathname.includes("index") ? true : false,
                title: Lang("Produk", LANG(), { en: `Products` }),
                url: "/index#product",
                action: (event) => {
                    event.preventDefault();
                    if (this.props._this === undefined || !this.props._this.scrollToProduct) {
                        window.location.href = '/'
                        return;
                    }

                    if (this.props._this.scrollToProduct) { this.props._this.scrollToProduct() }
                }
            },
            {
                enable: props.location.pathname.includes("index") ? true : false,
                title: Lang("Solusi", LANG(), { en: `Solutions` }),
                url: "/index#solution", action: (event) => {
                    event.preventDefault();
                    if (this.props._this === undefined || !this.props._this.scrollToProduct) {
                        window.location.href = '/'
                        return;
                    }
                    if (this.props._this.scrollToSolution) { this.props._this.scrollToSolution() }
                }
            },
            {
                enable: props.location.pathname.includes("index") ? true : false,
                title: Lang("Video", LANG(), { en: "Videos" }),
                url: "/index#video", action: (event) => {
                    event.preventDefault();
                    if (this.props._this === undefined || !this.props._this.scrollToProduct) {
                        window.location.href = '/'
                        return;
                    }
                    if (this.props._this.scrollToVideo) { this.props._this.scrollToVideo() }
                }
            },
            { enable: true, title: Lang("Blog", LANG(), { en: "Blogs" }), url: "/blog", action: null },
            { enable: true, title: Lang("Studi Kasus", LANG(), { en: "Case Studies" }), url: "/casestudy", action: null },
            { enable: true, title: Lang("Paket Harga", LANG(), { en: "Pricing" }), url: "/priceplan", action: null },
            { enable: true, title: Lang("Hubungi Kami", LANG(), { en: "Contact Us" }), url: "/contactus", action: null },
        ]
        this.optionsRight = [];
        if (DISABLE_START_FREE === false) {
            this.optionsRight = [
                { enable: true, title: Lang("Mulai Ujian dengan GRATIS", LANG(), { en: "Start For Free" }), url: "https://proktor.amanin.id", action: null }
            ]
        }
        this.state = {
            desktopView: true,
            menuOpen: false,

            resolution: { width: window.innerWidth, height: window.innerHeight },
        }
    }
    componentWillUnmount() {
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    handleResize = () => {
        this.setState({
            resolution: { width: window.innerWidth, height: window.innerHeight },
        });
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    generateOption(title, url = null, action = null) {
        return <div key={title} className={classes.item}>
            <a href={url === null ? "#" : url}
                onClick={action === null ? null : (e) => {
                    action(e)
                }}
            >
                {title}
            </a>
        </div>
    }

    renderWidthHeight() {
        return <>
            {/* {this.state.resolution.width}x{this.state.resolution.height} */}
        </>
    }
    renderDesktop() {
        return <div className={classes.container}>
            <div className={classes.container_content}>
                <div className={classes.brand}>
                    <Brand desktopView={this.state.desktopView} />
                    {this.renderWidthHeight()}
                </div>
                <div className={classes.options}>
                    {this.options.map((v) => {
                        return v.enable === true ?
                            this.generateOption(v.title, v.url, v.action) : <div key={v.title}></div>
                    })}
                </div>
                {
                    this.state.resolution.width > 815 && <div className={[classes.options, classes.right].join(" ")}>
                        {this.optionsRight.map((v) => {
                            return this.generateOption(v.title, v.url, v.action)
                        })}
                    </div>
                }
                <div>
                    <Language />
                </div>
            </div>
        </div>
    }

    renderMenuHover() {
        return <div className={[
            classes.menu,
            this.state.menuOpen ? classes.open : classes.close
        ].join(" ")}>
            {this.options.map((v) => {
                // return this.generateOption(v.title, v.url, v.action)
                return v.enable === true ?
                    this.generateOption(v.title, v.url, v.action) : <div key={v.title}></div>
            })}
            {this.optionsRight.map((v) => {
                return this.generateOption(v.title, v.url, v.action)
            })}
        </div>
    }

    renderMobile() {
        return <div className={[classes.container].join(" ")}>
            {this.renderMenuHover()}
            <div className={[classes.container_content, classes.mobile].join(" ")}>
                <div className={classes.brand}>
                    <Brand desktopView={this.state.desktopView} />
                    {this.renderWidthHeight()}
                </div>
                <div className={[classes.options, classes.right].join(" ")}>
                    <Menu
                        onOpen={() => {
                            this.setState({ menuOpen: true })
                        }}
                        onClose={() => {
                            this.setState({ menuOpen: false })
                        }}
                    />
                </div>
            </div>
        </div>
    }

    renderCustom() {
        return this.state.desktopView ? this.renderDesktop() : this.renderMobile();
    }

    render() {
        return this.renderCustom();
    }
}
export default withRouter(NavPage);