import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _20_Definisi = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("21. Definisi", LANG(), { en: '21. Definitions' })}
        </span>
        <p>
            {Lang("“Platform Proktor Amanin” adalah seluruh lingkungan, infrastruktur, web, produk, konten, teknologi, fitur, media, dan jejaring sosial yang dimiliki dan dikelola oleh Proktor Amanin.", LANG(), { en: '“Proktor Amanin Platform” refers to the entire environment, infrastructure, web products, content, technology, features, media, and social networks owned and operated by Proktor Amanin.' })}
        </p>
        <p>
            {Lang("“Layanan Proktor Amanin” adalah produk atau layanan yang disediakan dan semua hal yang dapat diakses atau digunakan oleh pengguna Proktor Amanin.", LANG(), { en: '“Proktor Amanin Services” means the products or services provided, and everything that can be accessed or used by users of Proktor Amanin.' })}
        </p>
        <p>
            {Lang("“Pengguna” berarti semua pihak yang mendaftar dan menggunakan Platform dan Layanan Proktor Amanin.", LANG(), { en: '“User” refers to any party that registers for and uses the Proktor Amanin Platform and Services.' })}
        </p>
        <p>
            {Lang("“Pengguna Akhir” berarti semua Peserta Ujian, Pengawas Ujian, dan Admin Ujian yang diadakan oleh Pembuat Ujian.", LANG(), { en: '“End User” means all Exam Participants, Exam Supervisors, and Exam Administrators involved in exams conducted by the Exam Creator.' })}
        </p>
        <p>
            {Lang("“Peserta Ujian” berarti semua pihak yang diberi akses oleh Pembuat Ujian  ke pelaksanaan ujian online sebagai peserta.", LANG(), { en: '“Exam Participant” refers to any party granted access by the Exam Creator to participate in the online exam.' })}
        </p>
        <p>
            {Lang("“Pengawas Ujian” berarti semua pihak yang diberi akses oleh Pembuat Ujian atau Penyelenggara Ujian ke pelaksanaan ujian online sebagai pengawas.", LANG(), { en: '“Exam Supervisor” refers to any party granted access by the Exam Creator or Exam Organizer to oversee the online exam.' })}
        </p>
        <p>
            {Lang('“Pembuat Ujian” atau "Penyelenggara Ujian" berarti semua pihak yang membuat ujian online di Platform dan Layanan Proktor Amanin.', LANG(), { en: '“Exam Creator” or “Exam Organizer” means any party that creates online exams on the Proktor Amanin Platform and Services.' })}
        </p>
        <p>
            {Lang("“Layanan” berarti semua layanan yang disediakan oleh Proktor Amanin.", LANG(), { en: '“Services” refers to all services provided by Proktor Amanin.' })}
        </p>
        <p>
            {Lang("“Data Pelanggan” berarti data dan informasi mengenai pengguna Platform dan Layanan Proktor Amanin.", LANG(), { en: '“Customer Data” means the data and information regarding users of the Proktor Amanin Platform and Services.' })}
        </p>
    </div>
}