import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _11_PembatasanPostingan = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("12. Pembatasan Postingan, Konten dan Perilaku Anda", LANG(), { en: '12. Limitation on Your Posts, Content, and Behavior' })}
        </span>
        <p>
            {Lang("Ketika Anda membuat akun , Anda sepenuhnya bertanggung jawab atas postingan, konten, tautan, dan informasi yang Anda posting, unggah, tautkan, atau sebaliknya tersedia melalui Platform dan Layanan.", LANG(),
                { en: 'When you create an account, you are fully responsible for the posts, content, links, and information you post, upload, link to, or otherwise make available through the Platform and Service.' })}
        </p>
        <p>
            {Lang("Anda setuju bahwa Kami hanya bertindak sebagai saluran pasif untuk distribusi online dan publikasi konten, tautan, dan informasi Anda. Namun, Kami berhak untuk menghapus konten Anda apa pun dari Platform dan Layanan atas kebijakan tunggalnya.", LANG(), { en: 'You agree that we only act as a passive conduit for online distribution and publication of your content, links, and information. However, we reserve the right to remove any of your content from the Platform and Service at our sole discretion.' })}
        </p>
        <p>
            {Lang("Kami memberi Anda izin untuk menggunakan dan mengakses Platform dan Layanan, dengan syarat-syarat berikut seputar postingan, konten dan perilaku Anda. Anda setuju bahwa kegagalan untuk mematuhi salah satu dari ketentuan ini merupakan pelanggaran material terhadap Syarat dan Ketentuan ini.", LANG(),
                { en: 'We grant you permission to use and access the Platform and Service, subject to the following terms regarding your posts, content, and behavior. You agree that failure to comply with any of these terms constitutes a material breach of these Terms and Conditions.' })}
        </p>
        <p>
            {Lang(" Dengan mengirimkan dan mengirimkan postingan, konten, tautan, atau informasi apa pun saat menggunakan Platform dan Layanan, Anda setuju sebagai berikut:", LANG(), { en: 'By submitting and transmitting any posts, content, links, or information when using the Platform and Service, you agree as follows:' })}
        </p>
        <ol type="a">
            <li>
                {Lang("Anda sepenuhnya bertanggung jawab atas akun Anda dan aktivitas yang terjadi saat masuk atau menggunakan akun Anda;", LANG(), { en: 'You are fully responsible for your account and the activities that occur when logged in or using your account;' })}
            </li>
            <li>
                {Lang("Anda tidak akan memposting informasi yang bersifat jahat, fitnah, palsu, atau tidak akurat;", LANG(), { en: 'ou will not post malicious, defamatory, false, or inaccurate information;' })}
            </li>
            <li>
                {Lang("Anda tidak akan memposting informasi yang bersifat merugikan, mengancam, cabul, fitnah, atau secara rasial, seksual, keagamaan, atau sebaliknya tidak menyenangkan dan menyinggung;", LANG(), { en: 'You will not post harmful, threatening, obscene, defamatory, or racially, sexually, religiously, or otherwise offensive and objectionable information;' })}
            </li>
            <li>
                {Lang("Anda tidak akan mengirimkan konten yang dilindungi hak cipta atau tunduk pada hak milik pihak ketiga, termasuk privasi, publisitas, rahasia dagang, atau hak lainnya, kecuali jika Anda adalah pemilik hak tersebut atau memiliki izin yang sesuai dari pemilik sah untuk secara khusus mengirimkan konten tersebut; dan", LANG(), { en: 'You will not send content protected by copyright or subject to the ownership rights of third parties, including privacy, publicity, trade secret, or other rights, unless you are the owner of such rights or have appropriate permission from the rightful owner to specifically submit such content; and' })}
            </li>
            <li>
                {Lang("Anda dengan ini menyetujui bahwa Kami berhak menentukan apakah pengiriman postingan, konten, tautan, dan informasi Anda sesuai dan mematuhi Syarat dan Ketentuan Kami ini, menghapus salah satu atau semua pengiriman Anda, dan mengakhiri akun Anda dengan atau tanpa pemberitahuan sebelumnya.", LANG(), { en: 'You hereby agree that we have the right to determine whether your submission of posts, content, links, and information complies with and complies with our Terms and Conditions, remove any or all of your submissions, and terminate your account with or without prior notice.' })}
            </li>
            <li>
                {Lang("Anda memahami dan menyetujui bahwa setiap tanggung jawab, kerugian, atau kerusakan yang terjadi sebagai hasil dari penggunaan postingan, konten, tautan, dan informasi  apa pun yang Anda sediakan atau akses melalui penggunaan Platform dan Layanan sepenuhnya menjadi tanggung jawab Anda. Kami tidak bertanggung jawab atas tampilan publik atau penyalahgunaan postingan, konten, tautan, dan informasi Anda. Kami tidak, dan tidak dapat, melakukan pra-penayangan atau pemantauan semua postingan, konten, tautan, dan informasi Anda. Namun, atas kebijakan Kami, atau teknologi yang Kami terapkan, Kami atau teknologi tersebut dapat memantau dan/atau merekam interaksi Anda dengan Platform dan Layanan atau dengan Pengguna lain.", LANG(), { en: 'You understand and agree that any liability, loss, or damage that arises as a result of your use of posts, content, links, and information that you provide or access through the use of the Platform and Service is entirely your responsibility. We are not responsible for the public display or misuse of your posts, content, links, and information. We cannot and do not perform pre-screening or monitoring of all your posts, content, links, and information. However, at our discretion, or technology we apply, we or that technology may monitor and/or record your interactions with the Platform and Service or with other users.' })}
            </li>
        </ol>
    </div>
}