import classes from "./Footer.module.scss"
import React from "react"
import { COMPANY_NAME, handleScreenChange, LANG } from "../libs/utils";
import { Lang } from "../libs/Language";
import Language from "./Language";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desktopView: true,
        }
    }
    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    generateItem = (value, title) => {
        return <div>
            <div>
                <span className={classes.v}>{value}</span>
            </div>
            <span>{title}</span>
        </div>
    }

    generateText = (text, type = null) => {
        return <span className={type === "title" ? classes.title : null} >{text}</span>
    }

    generateTriangle = (up = true) => {
        return <div className={up ? classes.triangle_up : classes.triangle_down}></div>
    }

    generateUnlist = () => {
        const vd = this.state.desktopView;
        return <div
        
        >

        </div>
    }

    generateList = (title = "Tentang", content = {
        "Demo": "/sales?action=requestfordemo"
    }) => {
        const vd = this.state.desktopView;
        return <div
            className={[
                vd ? null : classes.mobile,
                this.state.chosenItem === title ? classes.open : classes.close
            ].join(" ")}

            style={{ height: vd ? "auto" : this.state.chosenItem !== title ? "40px" : `${(Object.keys(content).length + 1) * 40 + 10}px` }}
        >
            <span
                onClick={() => {
                    this.setState({
                        chosenItem: this.state.chosenItem === title ? "" : title
                    })
                }}
                className={[classes.title, vd ? null : classes.mobile,
                ].join(" ")}
            >
                {title}
                {this.state.desktopView ?
                    null :
                    this.generateTriangle(this.state.chosenItem === title ? true : false)}
            </span>
            {
                this.state.chosenItem === title || this.state.desktopView ? <>
                    <div style={{ height: "10px" }}></div>
                    {Object.entries(content).map(([key, value], index) => {
                        return <a className={[classes.item, classes.fadein].join(" ")} key={index}
                            // onClick={value} 
                            href={value}>
                            {key}
                        </a>;
                    })}
                </> : null
            }
        </div>
    }

    menuAbout = {
        [Lang("Tentang Kami", LANG(), { en: 'About Us' })]: "/aboutus",
        [Lang("Demo", LANG(), { en: 'Demo' })]: "/sales?action=requestfordemo"
    }

    menuDownload = {
        [Lang("User Manual - Peserta", LANG(), { en: 'User Manual - Participant' })]: "/download",
        [Lang("User Manual - Pengawas", LANG(), { en: 'User Manual - Proctor' })]: "/download",
        [Lang("User Manual - Post Proctoring", LANG(), { en: 'User Manual - Post Proctoring' })]: "/download",
        [Lang("Safe Exam Browser", LANG(), { en: ' Safe Exam Browser' })]: "/download"
    }

    menuSupport = {
        [Lang("FAQ", LANG(), { en: 'FAQ' })]: "/faq",
        [Lang("Panduan & Troubleshooting", LANG(), { en: 'Guide & Troubleshooting' })]: "/troubleshooting",
        [Lang("Hubungi Kami", LANG(), { en: 'Contact Us' })]: "/contactus"
    }

    menuLegal = {
        [Lang("Syarat & Ketentuan Pengguna", LANG(), { en: 'Terms & Conditions' })]: "/termsandconditions",
        [Lang("Kebijakan Privasi", LANG(), { en: 'Privacy Policy' })]: "/privacypolicy"
    }

    contents() {
        return <>
            {this.generateList(Lang("Tentang", LANG(), { en: `About` }), this.menuAbout)}
            {this.generateList(Lang("Download", LANG(), { en: `Downloads` }), this.menuDownload)}
            {this.generateList(Lang("Dukungan", LANG(), { en: `Supports` }), this.menuSupport)}
            {this.generateList(Lang("Informasi Legal", LANG(), { en: `Legal` }), this.menuLegal)}

        </>
    }
    generateContent = () => {
        return <div className={classes.container}>
            <div className={[classes.container_lang, classes.mb].join(" ")}>
                <Language
                />
            </div>

            <div className={classes.container_top}>
                {this.contents()}
            </div>
            <span className={classes.container_company}>{Lang("Hak Cipta © 2023", LANG(), { en: 'Copyright © 2023' })} {COMPANY_NAME}</span>
        </div>
    }

    generateContentMobile = () => {
        return <div className={classes.container}>
            <div className={[classes.container_lang, classes.mb].join(" ")}>
                <Language
                // onChangeLang={() => {
                // console.log("on change lang");
                // window.location.reload();
                // }}
                />
            </div>
            <div className={[classes.container_top, classes.mb].join(" ")}>
                {this.contents()}
            </div>
            <span className={classes.container_company}>{Lang("Hak Cipta © 2023", LANG(), { en: 'Copyright © 2023' })} {COMPANY_NAME}</span>
        </div>
    }

    render() {
        return this.state.desktopView ?
            this.generateContent() :
            this.generateContentMobile()
    }
}

export default Footer;
