import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";
import classes from "./Portofolio.module.scss";

export const __Portofolio__ = function (_this) {
    this.render = () => {
        const generateStory = (by, by_title, institution, logo_institution, img_src, text) => {
            return <div key={by} className={[classes.story, _this.state.desktopView ? null : classes.mobile].join(" ")}>
                <div className={classes.story_title}>
                    <img alt="institution" src={logo_institution} />
                    <span className={
                        _this.state.desktopView ? null : classes.mb
                    }>{institution}</span>
                </div>
                <div className={[
                    classes.story_content,
                    _this.state.desktopView ? null : classes.mb
                ].join(" ")}>
                    <pre className={_this.state.desktopView ? null : classes.mbpre}>
                        {text}
                    </pre>
                    <div className={classes.story_content_by}>
                        {img_src !== null ?
                            <img alt="face" src={img_src} />
                            : null}
                        <div>
                            <span className={[_this.state.desktopView ? null : classes.mb
                            ].join(" ")}>
                                {by}
                            </span>
                            <span className={[classes.title, _this.state.desktopView ? null : classes.mb
                            ].join(" ")}>
                                {by_title}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        }

        const theStories = [
            {
                by: `Theodore Radithya`,
                by_title: `Project Manager at Adeline Foundation`,
                institution: `Adeline Foundation`,
                logo_institution: `https://amanin.id/logos/adeline2.png`,
                face_img_src: null,
                text: Lang(`Proktor dibutuhkan untuk menjaga mahasiswa untuk tidak berbuat curang ketika ujian, khususnya ujian secara online dan asynchronous

⁠Proktor amanin integrasinya bagus, untuk fitur juga sudah sangat lengkap dan A.I yang digunakan sudah sangat responsif, bantuan yang diberikan saat melakukan pengujian juga sangat membantu dan arahan arahan teknis yang diberikan juga sudah sangat jelas. Fitur seperti notes dan pengecekan kamera dan screen saat dan setelah ujian juga sangat bagus untuk mengecek kembali apabila ada kecurigaan pada kecurangan pada saat ujian dan review ulang mahasiswa dibutuhkan. 
`, LANG(), {
                    en: `Proctoring is needed to ensure that students do not cheat during exams, especially online and asynchronous exams.

Amanin's proctoring has great integration, the features are very comprehensive, and the AI used is very responsive. The assistance provided during testing is also very helpful, and the technical guidance given is very clear. Features like notes and camera and screen checks during and after the exam are also excellent for rechecking in case of suspicion of cheating during the exam and for reviewing students again if needed.`
                })
            },
            {
                by: `Radyum Ikono`,
                by_title: `CEO at Schoters`,
                institution: `Schoters`,
                logo_institution: `https://amanin.id/logos/schoters2.png`,
                face_img_src: null,
                text: Lang(`Mantab, Proktor sangat bermanfaat untuk siswa kami dan kami selaku penyelenggara bimbingan. Tim Proktor jg sangat responsif shingga memudahkan untuk kordinasi dengan tim operasional kami. Overall kami puas dengan layanan Proktor by Amanin.`,
                    LANG(),
                    {
                        en: `Great, Proktor has been very beneficial for our students and for us as the organizers of the guidance program. The Proktor team is also very responsive, making it easier to coordinate with our operational team. Overall, we are satisfied with the Proktor by Amanin service.`
                    }
                )
            },
            {
                by: `Irvan Christiawan, S.T.`,
                by_title: Lang(`Kasubdit Administrasi Penerimaan Mahasiswa`, LANG(), { en: 'Head of the Sub-Directorate of Student Admission Administration' }),
                institution: `Institut Teknologi Bandung`,
                logo_institution: `https://amanin.id/logos/logo_itb.png`,
                face_img_src: null, //`https://amanin.id/photos/deddihp.jpeg`,
                text:
                    Lang(` ITB menggunakan sistem proktor Amanin untuk mengelola ujian daring penerimaan mahasiswa baru melalui jalur Seleksi Mandiri ITB dan ITB International Undergraduate Program.

Berdasarkan pengalaman tersebut, sistem proktor Amanin memiliki kemampuan yang sangat baik untuk mengelola pengawasan peserta ujian daring dengan jumlah peserta besar. Pengawasan peserta ujian dibantu oleh AI, sehingga dapat meminimumkan kesalahan dari pengawas ujian.
        
Pengalaman menunjukkan bahwa sistem proktor yang digunakan mampu mengelola hingga lebih dari 5000 peserta dalam satu sesi ujian tanpa masalah. Antarmuka bagi peserta ujian dan pengawas ujian cukup mudah digunakan, serta tidak ada masalah berarti yang dihadapi baik oleh peserta maupun pengawas ujian.

Penggunaan sistem proktor yang terintegrasi dengan browser internet hanya memerlukan bandwidth kecil, sehingga memudahkan peserta dan pengawas ujian untuk mengikuti sesi dari mana saja.
        
Sistem proktor tersebut tidak memerlukan perangkat dan koneksi internet dengan spesifikasi tinggi, sehingga relatif murah bagi peserta ujian.

Setelah beberapa kali menggunakan sistem proktor Amanin untuk mengelola ujian secara daring, ITB merasa cukup puas atas kualitas dan layanan yang diberikan Amanin.
                `,
                        LANG(),
                        {
                            en: `ITB utilizes the Amanin proctoring system to manage online exams for new student admissions through the ITB Independent Selection and ITB International Undergraduate Program pathways.
                    
Based on this experience, the Amanin proctoring system has shown excellent capabilities in overseeing online exam participants with a large number of candidates. Participant monitoring is assisted by AI, thereby minimizing errors from exam supervisors.
                    
Experience has shown that the proctoring system used is able to manage over 5000 participants in a single exam session without issues. The interface for exam participants and supervisors is user-friendly, with no significant problems faced by either party.
                    
The use of a proctoring system integrated with internet browsers only requires low bandwidth, making it convenient for both exam participants and supervisors to attend sessions from anywhere.
                    
The proctoring system does not require high-spec devices or internet connections, making it relatively affordable for exam participants.
                    
After several uses of the Amanin proctoring system for managing online exams, ITB is satisfied with the quality and service provided by Amanin.`
                        })
            },
            {
                by: `Dr. Dedy Rahman W.`,
                by_title: Lang(`Dosen dan Direktur Pusat Teknologi Informasi`, LANG(), { en: 'Lecturer and Director of the Information Technology Center' }),
                institution: ``,
                logo_institution: `https://amanin.id/logos/amanin.png`,
                face_img_src: null,
                text:
                    //                  `Amanin membantu menerapkan sistem proktor online untuk ujian mahasiswa menjadi hal yang sangat bermanfaat. 

                    // Sistem ini memungkinkan pengawasan ujian secara efisien tanpa perlu kehadiran fisik pengawas. Hal ini membantu meningkatkan integritas ujian sambil memberikan kemudahan dalam pelaksanaannya.

                    // Dengan adopsi proktor online, skala besar ujian dapat diatasi dengan lebih efisien, mengurangi kebutuhan akan sumber daya fisik yang besar seperti jumlah pengawas yang dibutuhkan. 

                    // Penggunaan teknologi ini juga membantu dalam pemantauan kecurangan atau perilaku yang mencurigakan selama ujian.

                    // Penggunaan proktor online dalam ujian membawa banyak manfaat dalam meningkatkan efisiensi dan integritas ujian di lingkungan pendidikan. `

                    Lang(`Amanin membantu menerapkan sistem proktor online untuk ujian mahasiswa menjadi hal yang sangat bermanfaat. 
                
Sistem ini memungkinkan pengawasan ujian secara efisien tanpa perlu kehadiran fisik pengawas. Hal ini membantu meningkatkan integritas ujian sambil memberikan kemudahan dalam pelaksanaannya.
                
Dengan adopsi proktor online, skala besar ujian dapat diatasi dengan lebih efisien, mengurangi kebutuhan akan sumber daya fisik yang besar seperti jumlah pengawas yang dibutuhkan. 
                
Penggunaan teknologi ini juga membantu dalam pemantauan kecurangan atau perilaku yang mencurigakan selama ujian.
                
Penggunaan proktor online dalam ujian membawa banyak manfaat dalam meningkatkan efisiensi dan integritas ujian di lingkungan pendidikan.`,
                        LANG(),
                        {
                            en: `Amanin assists in implementing an online proctoring system for student exams, which proves to be highly beneficial.

This system allows for efficient exam monitoring without the need for physical presence of supervisors, thereby enhancing the integrity of exams while providing convenience in their administration.
                
With the adoption of online proctoring, large- scale exams can be handled more efficiently, reducing the need for extensive physical resources such as a large number of supervisors.
                
The use of this technology also aids in monitoring cheating or suspicious behavior during exams.
                
The use of online proctoring in exams brings many benefits in enhancing the efficiency and integrity of exams in educational environments`
                        })
            },
            // {
            //     by: `Irvan Christiawan, S.T.`,
            //     by_title: `Kasubdit Administrasi Penerimaan Mahasiswa`,
            //     institution: `Institut Teknologi Bandung`,
            //     logo_institution: `https://amanin.id/logos/logo_itb.png`,
            //     face_img_src: `https://amanin.id/photos/deddihp.jpeg`,
            //     text: `ITB menggunakan sistem proktor Amanin untuk mengelola ujian daring penerimaan mahasiswa baru melalui jalur Seleksi Mandiri ITB dan ITB International Undergraduate Program.

            // Berdasarkan pengalaman tersebut, sistem proktor Amanin memiliki kemampuan yang sangat baik untuk mengelola pengawasan peserta ujian daring dengan jumlah peserta besar. Pengawasan peserta ujian dibantu oleh AI, sehingga dapat meminimumkan kesalahan dari pengawas ujian.

            // Pengalaman menunjukkan bahwa sistem proktor yang digunakan mampu mengelola hingga lebih dari 5000 peserta dalam satu sesi ujian tanpa masalah. Antarmuka bagi peserta ujian dan pengawas ujian cukup mudah digunakan, serta tidak ada masalah berarti yang dihadapi baik oleh peserta maupun pengawas ujian.
            // Penggunaan sistem proktor yang terintegrasi dengan browser internet hanya memerlukan bandwidth kecil, sehingga memudahkan peserta dan pengawas ujian untuk mengikuti sesi dari mana saja.

            // Sistem proktor tersebut tidak memerlukan perangkat dan koneksi internet dengan spesifikasi tinggi, sehingga relatif murah bagi peserta ujian.
            // Setelah beberapa kali menggunakan sistem proktor Amanin untuk mengelola ujian secara daring, ITB merasa cukup puas atas kualitas dan layanan yang diberikan Amanin.
            // `
            // },
            // {
            //     by: `Irvan Christiawan, S.T.`,
            //     by_title: `Kasubdit Administrasi Penerimaan Mahasiswa`,
            //     institution: `Institut Teknologi Bandung`,
            //     logo_institution: `https://amanin.id/logos/logo_itb.png`,
            //     face_img_src: `https://amanin.id/photos/deddihp.jpeg`,
            //     text: `ITB menggunakan sistem proktor Amanin untuk mengelola ujian daring penerimaan mahasiswa baru melalui jalur Seleksi Mandiri ITB dan ITB International Undergraduate Program.

            // Berdasarkan pengalaman tersebut, sistem proktor Amanin memiliki kemampuan yang sangat baik untuk mengelola pengawasan peserta ujian daring dengan jumlah peserta besar. Pengawasan peserta ujian dibantu oleh AI, sehingga dapat meminimumkan kesalahan dari pengawas ujian.

            // Pengalaman menunjukkan bahwa sistem proktor yang digunakan mampu mengelola hingga lebih dari 5000 peserta dalam satu sesi ujian tanpa masalah. Antarmuka bagi peserta ujian dan pengawas ujian cukup mudah digunakan, serta tidak ada masalah berarti yang dihadapi baik oleh peserta maupun pengawas ujian.
            // Penggunaan sistem proktor yang terintegrasi dengan browser internet hanya memerlukan bandwidth kecil, sehingga memudahkan peserta dan pengawas ujian untuk mengikuti sesi dari mana saja.

            // Sistem proktor tersebut tidak memerlukan perangkat dan koneksi internet dengan spesifikasi tinggi, sehingga relatif murah bagi peserta ujian.
            // Setelah beberapa kali menggunakan sistem proktor Amanin untuk mengelola ujian secara daring, ITB merasa cukup puas atas kualitas dan layanan yang diberikan Amanin.
            // `
            // },
            //         {
            //             by: `Irvan Christiawan, S.T.`,
            //             by_title: `Kasubdit Administrasi Penerimaan Mahasiswa`,
            //             institution: `Institut Teknologi Bandung`,
            //             logo_institution: `https://amanin.id/logos/logo_itb.png`,
            //             face_img_src: `https://amanin.id/photos/deddihp.jpeg`,
            //             text: `ITB menggunakan sistem proktor Amanin untuk mengelola ujian daring penerimaan mahasiswa baru melalui jalur Seleksi Mandiri ITB dan ITB International Undergraduate Program.

            // Berdasarkan pengalaman tersebut, sistem proktor Amanin memiliki kemampuan yang sangat baik untuk mengelola pengawasan peserta ujian daring dengan jumlah peserta besar. Pengawasan peserta ujian dibantu oleh AI, sehingga dapat meminimumkan kesalahan dari pengawas ujian.

            // Pengalaman menunjukkan bahwa sistem proktor yang digunakan mampu mengelola hingga lebih dari 5000 peserta dalam satu sesi ujian tanpa masalah. Antarmuka bagi peserta ujian dan pengawas ujian cukup mudah digunakan, serta tidak ada masalah berarti yang dihadapi baik oleh peserta maupun pengawas ujian.
            // Penggunaan sistem proktor yang terintegrasi dengan browser internet hanya memerlukan bandwidth kecil, sehingga memudahkan peserta dan pengawas ujian untuk mengikuti sesi dari mana saja.

            // Sistem proktor tersebut tidak memerlukan perangkat dan koneksi internet dengan spesifikasi tinggi, sehingga relatif murah bagi peserta ujian.
            // Setelah beberapa kali menggunakan sistem proktor Amanin untuk mengelola ujian secara daring, ITB merasa cukup puas atas kualitas dan layanan yang diberikan Amanin.
            // `
            //         },
            //         {
            //             by: `Irvan Christiawan, S.T.`,
            //             by_title: `Kasubdit Administrasi Penerimaan Mahasiswa`,
            //             institution: `Institut Teknologi Bandung`,
            //             logo_institution: `https://amanin.id/logos/logo_itb.png`,
            //             face_img_src: `https://amanin.id/photos/deddihp.jpeg`,
            //             text: `ITB menggunakan sistem proktor Amanin untuk mengelola ujian daring penerimaan mahasiswa baru melalui jalur Seleksi Mandiri ITB dan ITB International Undergraduate Program.

            // Berdasarkan pengalaman tersebut, sistem proktor Amanin memiliki kemampuan yang sangat baik untuk mengelola pengawasan peserta ujian daring dengan jumlah peserta besar. Pengawasan peserta ujian dibantu oleh AI, sehingga dapat meminimumkan kesalahan dari pengawas ujian.

            // Pengalaman menunjukkan bahwa sistem proktor yang digunakan mampu mengelola hingga lebih dari 5000 peserta dalam satu sesi ujian tanpa masalah. Antarmuka bagi peserta ujian dan pengawas ujian cukup mudah digunakan, serta tidak ada masalah berarti yang dihadapi baik oleh peserta maupun pengawas ujian.
            // Penggunaan sistem proktor yang terintegrasi dengan browser internet hanya memerlukan bandwidth kecil, sehingga memudahkan peserta dan pengawas ujian untuk mengikuti sesi dari mana saja.

            // Sistem proktor tersebut tidak memerlukan perangkat dan koneksi internet dengan spesifikasi tinggi, sehingga relatif murah bagi peserta ujian.
            // Setelah beberapa kali menggunakan sistem proktor Amanin untuk mengelola ujian secara daring, ITB merasa cukup puas atas kualitas dan layanan yang diberikan Amanin.
            // `
            //         },
        ]

        const generateModal = () => {
            return <div className={classes.bg2_modal}>
                <img alt="logo"
                    src={_this.state.body4.chosenCompleteReview.logo_institution}
                    height="150px"
                />
                <span>{_this.state.body4.chosenCompleteReview.institution}</span>
                <div>
                    <div>
                        {/* <img
                        alt="institution"
                        src={_this.state.body4.chosenCompleteReview.img_src}
                        width="150px"
                    /> */}
                        <span>{_this.state.body4.chosenCompleteReview.by}</span>
                        <span className={classes.title}>{_this.state.body4.chosenCompleteReview.by_title}</span>
                    </div>
                    <pre>
                        {_this.state.body4.chosenCompleteReview.text}
                    </pre>
                </div>
                <div className={classes.btnOk} onClick={() => {
                    let body4 = _this.state.body4;
                    body4.chosenCompleteReviewFadeOut = true
                    _this.setState({ body4: body4 })
                    setTimeout(() => {
                        let body4 = _this.state.body4;
                        body4.chosenCompleteReview = null
                        body4.chosenCompleteReviewFadeOut = false
                        _this.setState({
                            body4: body4
                        })

                    }, 1000)
                }}>tutup</div>
            </div>
        }

        const generateModalMobile = () => {
            return <div className={classes.bg2_modal}>
                {/* <img alt="logo"
                src={_this.state.body4.chosenCompleteReview.logo_institution}
                height="150px"
            /> */}
                {/* <span >{_this.state.body4.chosenCompleteReview.institution}</span> */}
                <div>
                    {/* <div>
                    <img
                        alt="photo"
                        src={_this.state.body4.chosenCompleteReview.img_src}
                        width="150px"
                    />
                    <span>{_this.state.body4.chosenCompleteReview.by}</span>
                    <span className={classes.title}>{_this.state.body4.chosenCompleteReview.by_title}</span>
                </div> */}
                    <pre className={classes.mb}>
                        {_this.state.body4.chosenCompleteReview.text}
                    </pre>
                </div>
                <div className={classes.btnOk} onClick={() => {
                    let body4 = _this.state.body4;
                    body4.chosenCompleteReviewFadeOut = true
                    _this.setState({ body4: body4 })
                    setTimeout(() => {
                        let body4 = _this.state.body4;
                        body4.chosenCompleteReview = null
                        body4.chosenCompleteReviewFadeOut = false
                        _this.setState({
                            body4: body4
                        })

                    }, 1000)
                }}>tutup</div>
            </div>
        }

        const generateContent = () => {
            const company_logos = [
                {
                    title: 'PT. Telkom',
                    src: "https://amanin.id/logos/telkom2.png"
                },
                {
                    title: 'ITB',
                    src: "https://amanin.id/logos/itb.png"
                }, {
                    title: 'Schoters',
                    src: "https://amanin.id/logos/schoters2.png"
                }, {
                    title: 'Adeline Foundation',
                    src: "https://amanin.id/logos/adeline2.png"
                },
            ]
            const comel = [];
            for (let c in company_logos) {
                comel.push(<div key={`c_${c}`}>
                    <img alt="logos" src={company_logos[c].src} />
                    <span>{company_logos[c].title}</span>
                </div>)
            }
            return <>
                <div className={[classes.container_comimage]}>
                    {comel}
                </div>
                <div
                    className={[classes.container,
                    _this.state.desktopView ? null : classes.mobile
                    ].join(" ")}
                >
                    <div className={[
                        classes.content,
                        _this.state.desktopView ? null : classes.mobile
                    ].join(" ")}>
                        {theStories.length > 3 ? <>
                            <div className={classes.content_direction_l}
                                onClick={() => {
                                    if (_this.contentRef.current.scrollLeft > 0);
                                    _this.contentRef.current.scrollLeft -= 300;
                                }}
                            >
                                {"<"}
                            </div>
                            <div className={classes.content_direction_r}
                                onClick={() => {
                                    const maxScrollLeft = _this.contentRef.current.scrollWidth - _this.contentRef.current.clientWidth;
                                    if (_this.contentRef.current.scrollLeft < maxScrollLeft) {
                                        _this.contentRef.current.scrollLeft += 300;
                                    }
                                }}
                            >
                                {">"}
                            </div>
                        </> : null}
                        <div
                            ref={_this.contentRef}
                            className={[
                                classes.content_c,
                                _this.state.desktopView ? null : classes.mobile
                            ].join(" ")}>
                            <div style={{
                                minWidth: "30px",
                            }} />
                            {theStories.map((v) => {
                                return generateStory(
                                    v.by,
                                    v.by_title,
                                    v.institution,
                                    v.logo_institution,
                                    v.face_img_src,
                                    v.text
                                )
                            })}
                            <div style={{
                                minWidth: "30px",
                            }} />
                        </div>
                    </div>
                    {_this.state.body4.chosenCompleteReview !== null && <div className={[
                        classes.container_bg,
                        classes.bg2,
                        _this.state.body4.chosenCompleteReviewFadeOut ? classes.fadeout : classes.fadein
                    ].join(" ")}
                    >
                        {_this.state.desktopView ?
                            generateModal() :
                            generateModalMobile()}
                    </div>
                    }
                </div>
            </>
        }

        return generateContent();
    }
}