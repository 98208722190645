import React from "react"
import classes from "./__index__.module.scss"
import NavPage from "../../components/Nav";
import Footer from "../../components/Footer";
import { renderArticle12 } from "./articles/12";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { renderArticle1 } from "./articles/1";
import { renderArticle2 } from "./articles/2";
import { renderArticle3 } from "./articles/3";
import { renderArticle6 } from "./articles/6";
import { renderArticle7 } from "./articles/7";
import { renderArticle8 } from "./articles/8";
import { renderArticle9 } from "./articles/9";
import { renderArticle10 } from "./articles/10";
import { renderArticle11 } from "./articles/11";
import { renderArticle13 } from "./articles/13";
import { renderArticle14 } from "./articles/14";
import { renderArticle15 } from "./articles/15";
import { renderArticle16 } from "./articles/16";
import { renderArticle17 } from "./articles/17";
import { renderArticle18 } from "./articles/18";
import { renderArticle4 } from "./articles/4";
import { renderArticle5 } from "./articles/5";
import { footerText } from "./footer";

class Blogs extends React.Component {
    constructor(props) {
        super(props);


        // this.action = searchParams.get('action');
        console.log(this.props);
        this.postId = this.props.match.params.postId;
        console.log("post id = ", this.postId);

        this.articles = [
            {
                title: "Article 1",
                content: renderArticle1,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle2,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle3,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle4,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle5,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle6,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle7,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle8,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle9,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle10,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle11,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle12,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle13,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle14,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle15,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle16,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle17,
                date: `12 Januari 2024`
            },
            {
                title: "Article 1",
                content: renderArticle18,
                date: `12 Januari 2024`
            }
        ]
    }

    render() {
        return <div className={classes.container}>
            <NavPage />
            <div className={classes.container_content}>
                {this.postId === undefined && this.articles.map((v, idx) => {
                    return <div key={idx} className={[classes.article, classes.fadein1s].join(" ")}>
                        <img alt="content" src={v.content.img} />
                        <a href={`/blog/${idx}`}>{v.content.title}</a>
                        <div>
                            {v.content.shorttext.substring(0, 119)}...
                        </div>
                    </div>
                })}
                {this.postId !== undefined && <div className={classes.articlefull}>
                    <span>{this.articles[this.postId].content.title}</span>
                    <div>
                        {this.articles[this.postId].content.content}
                    </div>
                    <div className={classes.footer}>
                        {footerText()}
                    </div>
                </div>}
            </div>
            <Footer />
        </div>
    }
}

export default withRouter(Blogs);