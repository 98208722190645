import React from "react";

import classes from "./ProktorFreedomLM.module.scss";
import NavPage from "../../components/Nav";
import { handleScreenChange } from "../../libs/utils";
import { generateBody1 } from "./Body1";
import { generateBody2 } from "./Body2";
import { generateBody3 } from "./Body3";
// import { generateFooter } from "../HomePage/Footer";
import { generateBody4 } from "./Body4";
import { generateBody5 } from "./Body5";
import Footer, { generateFooter } from "../../components/Footer";
import { casestudytelu } from "../../assets/imgs";


class ProktorFreedomLM extends React.Component {
    constructor(props) {
        super(props);

        this.caseRef = React.createRef();
        this.state = {
            desktopView: true,
        }
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    generateCaseStudy() {
        const vd = this.state.desktopView;
        return <div
            ref={this.caseRef}
            className={[classes.casestudy, vd ? null : classes.mobile].join(" ")}>
            <img alt="case" src={casestudytelu}
            />
        </div>
    }

    generateContent() {
        const vd = this.state.desktopView;
        return <div className={[classes.container, vd ? null : classes.mobile].join(" ")}>
            <NavPage desktopView={this.state.desktopView} />
            {generateBody1(this)}
            {generateBody2(this)}
            {generateBody3(this)}

            {/* not used */}
            {/* {generateBody4(this)} */}
            {/* {generateBody5(this)} */}


            {this.generateCaseStudy()}
            <Footer />
        </div>
    }
    render() {
        return this.generateContent();
    }
}

export default ProktorFreedomLM;