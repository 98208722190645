import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _2_KetentuanUmum = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("2. Ketentuan Umum", LANG(), { en: '2. General Terms' })}</span>
        <p>
            {Lang("Syarat dan Ketentuan Proktor Amanin ini merupakan perjanjian antara pengguna Platform (“Anda” atau “Pengguna”) dan PT Amanin Inteligensia Internasional dan afiliasinya ('Proktor Amanin,' 'Kami', atau 'Kita) dan mengatur akses dan penggunaan Anda terhadap Platform dan Layanan Kami, kecuali jika Anda dan Proktor Amanin telah memasuki Perjanjian lain, dalam hal tersebut Perjanjian tersebut akan mengatur akses dan penggunaan Anda terhadap Platform dan Layanan, bukan Syarat dan Ketentuan ini. Anda dapat memasuki Perjanjian ini atas nama diri Anda sendiri atau atas nama entitas hukum. Jika Anda memasuki Perjanjian ini atas nama entitas hukum, Anda menyatakan bahwa Anda adalah perwakilan yang sah dengan kewenangan untuk mengikat entitas hukum tersebut pada Perjanjian ini. Semua referensi kepada 'Anda' dalam Perjanjian ini mengacu pada orang yang menerima Perjanjian ini sebagai individu atau entitas hukum yang diwakili oleh perwakilan tersebut. Istilah yang diawali huruf kapital dalam Perjanjian ini akan memiliki definisi sebagaimana diatur dalam bagian yang berlaku di mana mereka didefinisikan, atau dalam Bagian 20 di bawah ini.", LANG(),
                { en: 'Terms and Conditions of Proktor Amanin constitute an agreement between the Platform user ("You" or "User") and PT Amanin Inteligensia Internasional and its affiliates ("Proktor Amanin," "We," or "Us") and govern your access and use of our Platform and Service, unless you and Proktor Amanin have entered into another agreement, in which case that agreement will govern your access and use of the Platform and Service, not these Terms and Conditions. You may enter into this Agreement on behalf of yourself or a legal entity. If you enter into this Agreement on behalf of a legal entity, you represent that you are a duly authorized representative with the authority to bind such legal entity to this Agreement. All references to "You" in this Agreement refer to the person accepting this Agreement as an individual or the legal entity represented by such representative. Terms beginning with capital letters in this Agreement will have definitions as set forth in the applicable section where they are defined, or in Section 20 below.' })}

        </p>
        <p>
            {Lang("Anda menyatakan dan menjamin bahwa Anda telah berusia 18 (delapan belas) tahun atau lebih atau sudah menikah dan tidak berada di bawah perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Syarat dan Ketentuan ini. Jika Anda tidak memenuhi ketentuan tersebut, maka tindakan Anda dalam mendaftar, mengakses, menggunakan, atau melakukan aktivitas lain dalam Platform dan/atau Layanan yang Kami sediakan harus dalam sepengetahuan, pengawasan dan persetujuan yang sah dari orang tua, wali atau pengampu Anda. Orang tua, wali atau pengampu yang memberikan persetujuan bagi Anda yang berusia di bawah 18 (delapan belas) tahun bertanggung jawab secara penuh atas seluruh tindakan dan aktivitas Anda dalam penggunaan dan akses terhadap Platform dan/atau Layanan. Anda secara tegas mengesampingkan setiap hak berdasarkan hukum untuk membatalkan atau mencabut setiap dan seluruh persetujuan yang Anda berikan berdasarkan Syarat dan Ketentuan ini pada waktu Anda dianggap telah dewasa secara hukum.", LANG(),
                { en: 'You represent and warrant that you are 18 (eighteen) years of age or older or married and not under guardianship or trusteeship and you legally have the capacity and right to enter into these Terms and Conditions. If you do not meet these requirements, then your actions in registering, accessing, using, or engaging in other activities within the Platform and/or Service we provide must be with the knowledge, supervision, and lawful consent of your parents, guardian, or trustee. Parents, guardians, or trustees providing consent for you under the age of 18 (eighteen) years are fully responsible for all of your actions and activities in using and accessing the Platform and/or Service. You expressly waive any legal rights to revoke or withdraw any and all consent you grant under these Terms and Conditions at the time you are deemed legally an adult.' })}

        </p>
        <p>
            {Lang("Dengan menyetujui Syarat dan Ketentuan Proktor Amanin ini, Anda juga menyetujui Syarat dan Ketentuan tambahan, termasuk Syarat dan Ketentuan atas setiap Layanan, dan perubahannya yang merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini (selanjutnya, Syarat dan Ketentuan Proktor Amanin, Syarat dan Ketentuan tambahan, dan perubahannya secara bersama-sama disebut sebagai “Syarat dan Ketentuan”). Meskipun merupakan satu kesatuan, Syarat dan Ketentuan tambahan akan berlaku dalam hal terdapat perbedaan dengan Syarat dan Ketentuan.", LANG(),
                { en: 'By agreeing to these Terms and Conditions of Proktor Amanin, you also agree to additional terms and conditions, including terms and conditions for each Service and any amendments thereto, which are an integral part of these Terms and Conditions (hereinafter, collectively referred to as the "Terms and Conditions of Proktor Amanin," additional terms and conditions, and their amendments). Although they are an integral whole, additional terms and conditions will apply in the event of discrepancies with these Terms and Conditions.' })}

        </p>
        <p>
            {Lang("Dengan mendaftar dan menggunakan Platform dan Layanan Proktor Amanin ini, maka Anda atau wali atau orang tua atau pengampu Anda (jika Anda berusia di bawah 18 tahun) menyatakan bahwa Anda telah membaca, mengerti, memahami, dan mematuhi Syarat dan Ketentuan ini. Anda atau wali atau orang tua atau pengampu Anda (jika Anda berusia di bawah 18 tahun) menyatakan bahwa Anda secara umum memiliki kemampuan dan kewenangan penuh untuk memasuki dan mematuhi syarat, kondisi, kewajiban, pernyataan, representasi, dan jaminan yang diuraikan dalam Perjanjian ini. Akses Anda dapat diakhiri tanpa pemberitahuan jika kami mengetahui bahwa Anda  tidak memenuhi syarat untuk memasuki Perjanjian ini atau menggunakan Platform dan Layanan.", LANG(),
                { en: 'By registering and using the Platform and Service of Proktor Amanin, you or your guardian or parent or trustee (if you are under 18 years old) represent that you have read, understood, comprehended, and comply with these Terms and Conditions. You or your guardian or parent or trustee (if you are under 18 years old) represent that you generally have the ability and full authority to enter into and comply with the terms, conditions, obligations, statements, representations, and warranties set forth in this Agreement. Your access may be terminated without notice if we find that you do not qualify to enter into this Agreement or use the Platform and Service.' })}

        </p>
        <p>
            {Lang("Syarat dan Ketentuan Proktor Amanin ini berlaku ketika Anda melihat atau menggunakan Platform dan Layanan melalui situs web Kami yang terletak di amanin.id atau web lainnya, atau bentuk lain yang disediakan oleh Proktor Amanin. Harap tinjau dengan cermat syarat berikut. Dengan mengakses atau menggunakan Platform dan Layanan, Anda menandakan persetujuan Anda terhadap Syarat Penggunaan ini. Jika Anda tidak setuju untuk terikat oleh Syarat dan Ketentuan ini sepenuhnya, Anda sebaiknya berhenti mengakses atau menggunakan Platform dan Layanan Kami.", LANG(),
                { en: 'These Terms and Conditions of Proktor Amanin apply when you view or use the Platform and Service through our website located at amanin.id or other websites, or other forms provided by Proktor Amanin. Please carefully review the following terms. By accessing or using the Platform and Service, you signify your agreement to these Terms of Use. If you do not fully agree to be bound by these Terms and Conditions, you should refrain from accessing or using our Platform and Service.' })}

        </p>
    </div>
}