import React from "react";
import classes from "./__index__.module.scss"
import { DISABLE_START_FREE, LANG, handleScreenChange } from "../../libs/utils";
import badge from "../../assets/imgs/badge.png";
import Footer from "../../components/Footer";
import new1 from "../../assets/imgs/new.png";
import favorite from "../../assets/imgs/favorite.png";
import NavPage from "../../components/Nav";
import { Lang } from "../../libs/Language";

class PricePlanPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            desktopView: true,
        }

        this.plans = {
            basic: [
                {
                    title: Lang(`✔ Pengawasan Ujian Online`, LANG(), { en: `✔ Online Exam Supervision` }),
                    features: [
                        Lang(`Akumulasi Total Waktu Ujian adalah 2 jam/hari`, LANG(), { en: `Total accumulated exam time is 2 hours/day` }),
                        Lang(`Max. 25 peserta/ujian`, LANG(), { en: `Max. 25 participants/exam` }),
                        Lang(`$Notifikasi Warning secara Real-Time selama Ujian berlangsung`, LANG(), { en: `$Real-Time Warning Notifications during the Exam` }),
                        Lang(`Perekaman data gambar, video dan data digital maksimal 10 menit/peserta`, LANG(), { en: `Image, video and digital data recording maximum 10 minutes/participant` }),
                        Lang(`*Perekaman Disimpan maks. 1 hari`, LANG(), { en: `Recording Stored max. 1 day` }),
                        Lang(`#Bebas mengadakan ujian berkali-kali dalam sehari`, LANG(), { en: `#Free to hold multiple exams in a day` }),
                        Lang(`#Jumlah Ujian Bersamaan adalah 1 Ujian`, LANG(), { en: `#Number of concurrent exams is 1 exam` })

                    ]
                },
                {
                    title: Lang(`✔ Kompatibilitas`, LANG(), {
                        en: `✔ Compatibility`
                    }),
                    features: [
                        Lang(`$Kompatibel dengan berbagai macam aplikasi penyedia soal dan LMS, misalnya Moodle, Canva, Google Drive, Socrates, dan lain-lain`, LANG(), {
                            en: `$Compatible with various question providers and LMS applications, such as Moodle, Canva, Google Drive, Socrates, etc.`
                        }),
                        // `$Kompatibel dengan berbagai macam aplikasi penyedia soal dan LMS, misalnya Moodle, Canva, Google Drive, Socrates, dan lain-lain`
                    ]
                },
                {
                    title: Lang(`✔ Konfigurasi Ujian`, LANG(), {
                        en: `✔ Exam Configuration`
                    }),
                    features: [
                        Lang(`Mengkonfigurasi ujian online yang dilaksanakan`, LANG(), {
                            en: `Configure online exams that are administered`
                        }),

                    ]
                },
                {
                    title: `✔ Broadcast Alert`,
                    features: [
                        Lang(`$Mengirimkan pesan ke seluruh peserta ujian online`, LANG(), {
                            en: `Send a message to all online test participants`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Penandaan Peserta`, LANG(), {
                        en: `✔ Participant Marking`
                    }),
                    features: [
                        Lang(`Notes, memberikan catatan khusus terhadap peserta ujian online`, LANG(), { en: `Notes, provide special notes to online test takers` }),
                        Lang(`Screenshot, menscreenshot tangkapan wajah peserta dan tangkapan layar peserta pada saat tertentu`, LANG(), { en: `Screenshot, screenshot the participant's face and screenshot the participant at a certain time` }),
                        Lang(`Flag, menandai peserta yang dianggap mencurigakan`, LANG(), { en: `Flag, marks participants who are considered suspicious` })
                    ]
                },
                {
                    title: Lang(`✔ Penjadwalan Ujian`, LANG(), {
                        en: `✔ Exam Scheduling`
                    }),
                    features: [
                        Lang(`*Menjadwalkan waktu pelaksanaan ujian`, LANG(), {
                            en: `*Schedule the time of the exam`
                        }),

                    ]
                },
                {
                    title: Lang(`✔ Deteksi Kecurangan Berbasis AI`, LANG(), {
                        en: `✔ AI-based Cheating Detection`
                    }),
                    features: [
                        Lang(`*Mendeteksi dan mencatat kecurangan - kecurangan yang mungkin dilakukan peserta selama  ujian berlangsung`, LANG(), {
                            en: `*Detecting and recording possible cheating behaviors by participants during the exam.`
                        }),

                    ]
                },
                {
                    title: Lang(`✔ Dukungan berbasis percakapan`, LANG(), {
                        en: `✔ Conversation-based support`
                    }),
                    features: [
                        Lang(`Peserta dapat mengirimkan permintaan bantuan `, LANG(), { en: `Participants can send help requests` }),
                        Lang(`*Peserta dan pengawas dapat saling mengirimkan pesan lewat chatbox`, LANG(), { en: `Participants and proctors can send messages to each other through a chatbox` })
                    ]
                },
                {
                    title: `✔ Post Proktor`,
                    features: [
                        Lang(`Tempat untuk mengelola, mengatur, dan mengkonfigurasi ujian online yang diselenggarakan`, LANG(), { en: `A place to manage, organize, and configure organized online exams` }),
                        Lang(`Monitor statistik kegiatan peserta secara live`, LANG(), { en: `Monitor live participant activity statistics` }),
                        Lang(`Melihat kembali pelaksanaan ujian yang telah dan sedang berlangsung`, LANG(), { en: `Look back on past and ongoing exams` }),
                        Lang(`*Laporan berupa gambar berseri`, LANG(), { en: `Report in the form of serialized images` })
                    ]
                },
            ],
            standard: [
                {
                    title: Lang(`✔ Pengawasan Ujian Online`, LANG(), {
                        en: `✔ Online Exam Supervision`
                    }),
                    features: [
                        Lang(`#Akumulasi Total Waktu Ujian adalah 3 jam/hari`, LANG(), { en: `Total accumulated exam time is 3 hours/day` }),
                        Lang(`#Max. 50 peserta/ujian`, LANG(), { en: `Max. 50 participants/exam` }),
                        Lang(`$Notifikasi Warning secara Real-Time selama Ujian berlangsung`, LANG(), { en: `Real-Time Warning Notification during the Exam` }),
                        Lang(`Perekaman data gambar, video dan data digital maksimal 10 menit/peserta`, LANG(), { en: `Image, video and digital data recording maximum 10 minutes/participant` }),
                        Lang(`*Perekaman Disimpan maks. 1 hari`, LANG(), { en: `Recording Stored max. 1 day` }),
                        Lang(`Bebas mengadakan ujian berkali-kali dalam sehari`, LANG(), { en: `Free to hold multiple exams in a day` }),
                        Lang(`#Jumlah Ujian Bersamaan adalah 2 Ujian`, LANG(), { en: `Number of simultaneous exams is 2` }),
                        Lang(`*Bebas mengkonfigurasi kualitas video`, LANG(), { en: `Free to configure video quality` })
                    ]
                },
                {
                    title: Lang(`✔ Kompatibilitas`, LANG(), {
                        en: `✔ Compatibility`
                    }),
                    features: [
                        Lang(`$Kompatibel dengan berbagai macam aplikasi penyedia soal dan LMS, misalnya Moodle, Canva, Google Drive, Socrates, dan lain-lain`, LANG(), {
                            en: `$Compatible with various question providers and LMS applications, such as Moodle, Canva, Google Drive, Socrates, etc.`
                        }),

                    ]
                },
                {
                    title: Lang(`✔ Konfigurasi Ujian`, LANG(), {
                        en: `✔ Exam Configuration`
                    }),
                    features: [
                        Lang(`Mengkonfigurasi ujian online yang dilaksanakan`, LANG(), {
                            en: `Configure online exams that are administered`
                        }),

                    ]
                },
                {
                    title: `✔ Broadcast Alert`,
                    features: [
                        Lang(`$Mengirimkan pesan ke seluruh peserta ujian online`, LANG(), {
                            en: `$Send a message to all online test participants`
                        }),

                    ]
                },
                {
                    title: Lang(`✔ Penandaan Peserta`, LANG(), {
                        en: `✔ Participant Marking`
                    }),
                    features: [
                        Lang(`Notes, memberikan catatan khusus terhadap peserta ujian online`, LANG(), { en: `Notes, provide special notes to online test takers` }),
                        Lang(`Screenshot, menscreenshot tangkapan wajah peserta dan tangkapan layar peserta pada saat tertentu`, LANG(), { en: `Screenshots, screenshots the participant's face and screenshots the participant at any given moment` }),
                        Lang(`Flag, menandai peserta yang dianggap mencurigakan`, LANG(), { en: `Flag, marks participants who are considered suspicious` })
                    ]
                },
                {
                    title: Lang(`✔ Penjadwalan Ujian`, LANG(), {
                        en: `✔ Exam Scheduling`
                    }),
                    features: [
                        Lang(`*Kalender`, LANG(), { en: `*Calendar` }),
                        Lang(`Menjadwalkan waktu pelaksanaan ujian`, LANG(), {
                            en: `Schedule the time of the exam`
                        }),

                    ]
                },
                {
                    title: Lang(`✔ Deteksi Kecurangan Berbasis AI`, LANG(), {
                        en: `✔ AI-based Cheating Detection`
                    }),
                    features: [
                        Lang(`Deteksi wajah`, LANG(), { en: `Face Detection` }),
                        Lang(`Mendeteksi dan mencatat kecurangan - kecurangan yang mungkin dilakukan peserta selama  ujian berlangsung`, LANG(), {
                            en: `Detecting and recording possible cheating behaviors by participants during the exam.`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Dukungan berbasis percakapan`, LANG(), {
                        en: `✔ Conversation-based support`
                    }),
                    features: [
                        Lang(` Peserta dapat mengirimkan permintaan bantuan`, LANG(), { en: `Participants can send help requests` }),
                        Lang(`Peserta dan pengawas dapat saling mengirimkan pesan lewat chatbox`, LANG(), { en: `Participants and proctors can message each other via chatbox` })
                    ]
                },
                {
                    title: `✔ Post Proktor`,
                    features: [
                        Lang(`Tempat untuk mengelola, mengatur, dan mengkonfigurasi ujian online yang diselenggarakan`, LANG(), { en: `A place to manage, organize, and configure organized online exams` }),
                        Lang(`Monitor statistik kegiatan peserta secara live`, LANG(), { en: `Monitor live participant activity statistics` }),
                        Lang(`Melihat kembali pelaksanaan ujian yang telah dan sedang berlangsung`, LANG(), { en: `Look back on past and ongoing exams` }),
                        Lang(`Laporan berupa gambar berseri`, LANG(), { en: `Report in the form of serialized images` })
                    ]
                },
                {
                    title: Lang(`*✔ Laporan dikirimkan ke email `, LANG(), {
                        en: `*✔ Reports sent to email`
                    }),

                }
            ],
            pro: [
                {
                    title: Lang("✔ Pengawasan Ujian Online", LANG(), {
                        en: `✔ Online Exam Supervision`
                    }),
                    features: [
                        Lang(`#Akumulasi Total Waktu Ujian adalah 6 jam/hari`, LANG(), {
                            en: `#Total accumulated exam time is 6 hours/day`
                        }),
                        Lang(`#Max. 100 peserta/ujian`, LANG(), {
                            en: `#Max. 100 participants/exam`
                        }),
                        Lang(`$Notifikasi Warning secara Real-Time selama Ujian berlangsung`, LANG(), {
                            en: `$Real-Time Warning Notification during the Exam`
                        }),
                        Lang(`Perekaman data gambar, video dan data digital maksimal 10 menit/peserta atau sesuai dengan Add-On yang diterapkan`, LANG(), {
                            en: `Image, video and digital data recording max 10 minutes/participant or according to the Add-On applied`
                        }),
                        Lang(`*Perekaman Disimpan maks. 1 hari, atau sesuai dengan Add-On yang diterapkan`, LANG(), {
                            en: `*Recording Stored max. 1 day, or according to the Add-On applied`
                        }),
                        Lang(`Bebas mengadakan ujian berkali-kali dalam sehari`, LANG(), {
                            en: `Free to hold multiple exams in a day`
                        }),
                        Lang(`#Jumlah Ujian Bersamaan adalah 3 Ujian`, LANG(), {
                            en: `#Number of concurrent exams is 3`
                        }),
                        Lang(`Bebas mengkonfigurasi kualitas video`, LANG(), {
                            en: `Free to configure video quality`
                        }),
                        Lang(`*Mengawasi pengawas`, LANG(), {
                            en: `*Supervising proctor`
                        })
                        // `#Akumulasi Total Waktu Ujian adalah 6 jam/hari`,
                        // `#Max. 100 peserta/ujian`,
                        // `$Notifikasi Warning secara Real-Time selama Ujian berlangsung`,
                        // `Perekaman data gambar, video dan data digital maksimal 10 menit/peserta atau sesuai dengan Add-On yang diterapkan`,
                        // `*Perekaman Disimpan maks. 1 hari, atau sesuai dengan Add-On yang diterapkan`,
                        // `Bebas mengadakan ujian berkali-kali dalam sehari`,
                        // `#Jumlah Ujian Bersamaan adalah 3 Ujian`,
                        // `Bebas mengkonfigurasi kualitas video`,
                        // `*Mengawasi pengawas`
                    ]
                },
                {
                    title: Lang(`✔ Kompatibilitas`, LANG(), {
                        en: `✔ Compatibility`
                    }),
                    features: [
                        Lang(`$Kompatibel dengan berbagai macam aplikasi penyedia soal dan LMS, misalnya Moodle, Canva, Google Drive, Socrates, dan lain-lain`, LANG(), {
                            en: `$Compatible with various question providers and LMS applications, such as Moodle, Canva, Google Drive, Socrates, etc.`
                        })
                        // `$Kompatibel dengan berbagai macam aplikasi penyedia soal dan LMS, misalnya Moodle, Canva, Google Drive, Socrates, dan lain-lain`
                    ]
                },
                {
                    title: Lang(`✔ Konfigurasi Ujian`, LANG(), {
                        en: `✔ Exam Configuration`
                    }),
                    features: [
                        Lang(`Mengkonfigurasi ujian online yang dilaksanakan`, LANG(), {
                            en: `Configure the online exam being administered`
                        }),
                        Lang(`*Menambahkan pengawas`, LANG(), {
                            en: `*Add proctor`
                        })
                        // `Mengkonfigurasi ujian online yang dilaksanakan`,
                        // `*Menambahkan pengawas`
                    ]
                },
                {
                    title: `✔ Broadcast Alert`,
                    features: [
                        Lang(`$Mengirimkan pesan ke seluruh peserta ujian online`, LANG(), {
                            en: `$Send a message to all online test takers`
                        })
                        // `$Mengirimkan pesan ke seluruh peserta ujian online`,
                    ]
                },
                {
                    title: Lang(`✔ Penandaan Peserta`, LANG(), {
                        en: `✔ Participant Marking`
                    }),
                    features: [
                        Lang(`Notes, memberikan catatan khusus terhadap peserta ujian online`, LANG(), {
                            en: `Notes, provide special notes to online test participants`
                        }),
                        Lang(`Screenshot, menscreenshot tangkapan wajah peserta dan tangkapan layar peserta pada saat tertentu`, LANG(), {
                            en: `Screenshots, screenshots the participant's face and screenshots the participant at any given moment`
                        }),
                        Lang(`Flag, menandai peserta yang dianggap mencurigakan`, LANG(), {
                            en: `Flag, marks participants who are considered suspicious`
                        })
                        // `Notes, memberikan catatan khusus terhadap peserta ujian online`,
                        // `Screenshot, menscreenshot tangkapan wajah peserta dan tangkapan layar peserta pada saat tertentu`,
                        // `Flag, menandai peserta yang dianggap mencurigakan`
                    ]
                },
                {
                    title: Lang(`✔ Penjadwalan Ujian`, LANG(), {
                        en: `✔ Exam Scheduling`
                    }),
                    features: [
                        Lang(`*Kalender`, LANG(), {
                            en: `Calendar`
                        }),
                        Lang(`*Notifikasi Penjadwalan via Email`, LANG(), {
                            en: `*Scheduling Notification via Email`
                        }),
                        Lang(`Menjadwalkan waktu pelaksanaan ujian`, LANG(), {
                            en: `Schedule exam time`
                        })
                        // `*Kalender`,
                        // `*Notifikasi Penjadwalan via Email`,
                        // `Menjadwalkan waktu pelaksanaan ujian`
                    ]
                },
                {
                    title: Lang(`✔ Deteksi Kecurangan Berbasis AI`, LANG(), {
                        en: ``
                    }),
                    features: [
                        Lang(`Deteksi Wajah`, LANG(), {
                            en: `Face Detection`
                        }),
                        Lang(`*Analisa Suara`, LANG(), {
                            en: `*Voice Analysis`
                        }),
                        Lang(`*Analisa Wajah`, LANG(), {
                            en: `*Face Analysis`
                        }),
                        Lang(`*Fitur AI yang di tambahkan`, LANG(), {
                            en: `*Added AI features`
                        }),
                        Lang(`Mendeteksi dan mencatat kecurangan - kecurangan yang mungkin dilakukan peserta selama  ujian berlangsung`, LANG(), {
                            en: `Detect and record cheating - cheating that participants may do during the exam`
                        })
                        // `Deteksi Wajah`,
                        // `*Analisa Suara`,
                        // `*Analisa Wajah`,
                        // `*Fitur AI yang di tambahkan`,
                        // `Mendeteksi dan mencatat kecurangan - kecurangan yang mungkin dilakukan peserta selama  ujian berlangsung`
                    ]
                },
                {
                    title: Lang(`✔ Dukungan berbasis percakapan`, LANG(), {
                        en: `✔ Conversation-based support`
                    }),
                    features: [
                        Lang(`Peserta dapat mengirimkan permintaan bantuan `, LANG(), {
                            en: `Participants can send help requests`
                        }),
                        Lang(`Peserta dan pengawas dapat saling mengirimkan pesan lewat chatbox`, LANG(), {
                            en: `Participants and invigilators can message each other via chatbox`
                        }),
                        Lang(`*Percakapan dalam tim`, LANG(), {
                            en: `*Team conversation`
                        })
                        // `Peserta dapat mengirimkan permintaan bantuan `,
                        // `Peserta dan pengawas dapat saling mengirimkan pesan lewat chatbox`,
                        // `*Percakapan dalam tim`
                    ]
                },
                {
                    title: Lang(`✔ Post Proktor`, LANG(), {
                        en: ``
                    }),
                    features: [
                        Lang(`Tempat untuk mengelola, mengatur, dan mengkonfigurasi ujian online yang diselenggarakan`, LANG(), {
                            en: `A place to manage, organize, and configure organized online exams`
                        }),
                        Lang(`Monitor statistik kegiatan peserta secara live`, LANG(), {
                            en: `Monitor live participant activity statistics`
                        }),
                        Lang(`Melihat kembali pelaksanaan ujian yang telah dan sedang berlangsung`, LANG(), {
                            en: `Look back on past and ongoing exams`
                        }),
                        Lang(`Laporan berupa gambar berseri`, LANG(), {
                            en: `Report in the form of serialized images`
                        }),
                        Lang(`*Laporan video`, LANG(), {
                            en: `*Video report`
                        })
                        // `Tempat untuk mengelola, mengatur, dan mengkonfigurasi ujian online yang diselenggarakan`,
                        // `Monitor statistik kegiatan peserta secara live`,
                        // `Melihat kembali pelaksanaan ujian yang telah dan sedang berlangsung`,
                        // `Laporan berupa gambar berseri`,
                        // `*Laporan video`
                    ]
                },
                {
                    title: Lang(`*✔ Laporan dikirimkan ke email `, LANG(), {
                        en: `*✔ Reports sent to email`
                    })
                },
                {
                    title: Lang(`*✔ Cloud Storage Tergantung Add-On`, LANG(), {
                        en: `*✔ Cloud Storage Dependent Add-Ons`
                    })
                }
            ],
            freedom: [
                {
                    title: Lang(`✔ Pengawasan Ujian Online`, LANG(), {
                        en: `✔ Online Exam Supervision`
                    }),
                    features: [
                        Lang(`#Bebas mengatur durasi ujian`, LANG(), {
                            en: `#Free to set exam duration`
                        }),
                        Lang(`#Max. 200 peserta/ujian`, LANG(), {
                            en: `#Max. 200 participants/exam`
                        }),
                        Lang(`$Notifikasi Warning secara Real-Time selama Ujian berlangsung`, LANG(), {
                            en: `$Real-Time Warning Notifications during the Exam`
                        }),
                        Lang(`Perekaman data gambar, video dan data digital tidak terbatas`, LANG(), {
                            en: `Unlimited recording of images, videos and digital data`
                        }),
                        Lang(`#Bebas mengadakan ujian berkali-kali dalam sehari`, LANG(), {
                            en: `#Free to hold multiple exams in a day`
                        }),
                        Lang(`#Jumlah Ujian Bersamaan tidak terbatas`, LANG(), {
                            en: `#Unlimited number of simultaneous exams`
                        }),
                        Lang(`Bebas mengkonfigurasi kualitas video`, LANG(), {
                            en: `Free to configure video quality`
                        }),
                        Lang(`#Jumlah Pengawas tidak terbatas`, LANG(), {
                            en: `#Unlimited number of proctors`
                        }),
                        Lang(`#Mengawasi pengawas`, LANG(), {
                            en: `#Supervise supervisors`
                        })
                        // `#Bebas mengatur durasi ujian`,
                        // `#Max. 200 peserta/ujian`,
                        // `$Notifikasi Warning secara Real-Time selama Ujian berlangsung`,
                        // `Perekaman data gambar, video dan data digital tidak terbatas`,
                        // `#Bebas mengadakan ujian berkali-kali dalam sehari`,
                        // `#Jumlah Ujian Bersamaan tidak terbatas`,
                        // `Bebas mengkonfigurasi kualitas video`,
                        // `#Jumlah Pengawas tidak terbatas`,
                        // `#Mengawasi pengawas`
                    ]
                },
                // {
                //     title: Lang(`*✔ Extras`, LANG(), {
                //         en: `✔ Extras`
                //     }),
                //     features: [
                //         Lang(`*Single sign-on`, LANG(), {
                //             en: `*Single sign-on`
                //         }),
                //         Lang(`*Penyesuaian antarmuka`, LANG(), {
                //             en: `*Interface customization`
                //         })
                //     ]
                // },
                // {
                //     title: `*✔ Extras`,
                // features: [
                //     Lang(``, LANG(), {
                //         en: ``
                //     }),
                //     Lang(``, LANG(), {
                //         en: ``
                //     }),
                //     Lang(``, LANG(), {
                //         en: ``
                //     })
                //     `*Single sign-on`,
                //     `*Penyesuaian antarmuka`,
                //     `$Mendukung On-Premise ataupun On-Cloud`
                // ]
                // },
                {
                    title: Lang(`✔ Kompatibilitas`, LANG(), {
                        en: `✔ Compatibility`
                    }),
                    features: [
                        Lang(`$Kompatibel dengan berbagai macam aplikasi penyedia soal dan LMS, misalnya Moodle, Canva, Google Drive, Socrates, dan lain-lain`, LANG(), {
                            en: `$Compatible with various question providers and LMS applications, such as Moodle, Canva, Google Drive, Socrates, etc.`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Kustomisasi Ujian`, LANG(), {
                        en: `✔ Exam Customization`
                    }),
                    features: [
                        Lang(`*Hubungi kami untuk penyesuaian sesuai kebutuhan Anda`, LANG(), {
                            en: `*Contact us for customization according to your needs`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Konfigurasi Ujian`, LANG(), {
                        en: `✔ Exam Configuration`
                    }),
                    features: [
                        Lang(`Mengkonfigurasi ujian online yang dilaksanakan`, LANG(), {
                            en: `Configure the online exam being administered`
                        }),
                        Lang(`*Menambahkan pengawas`, LANG(), {
                            en: `*Add proctors`
                        })
                    ]
                },
                {
                    title: `✔ Broadcast Alert`,
                    features: [
                        Lang(`$Mengirimkan pesan ke seluruh peserta ujian online`, LANG(), {
                            en: `$Send a message to all online test participants`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Penandaan Peserta`, LANG(), {
                        en: `✔ Participant Marking`
                    }),
                    features: [
                        Lang(`Notes, memberikan catatan khusus terhadap peserta ujian online`, LANG(), {
                            en: `Notes, provide special notes to online test participants`
                        }),
                        Lang(`Screenshot, menscreenshot tangkapan wajah peserta dan tangkapan layar peserta pada saat tertentu`, LANG(), {
                            en: `Screenshots, screenshots the participant's face and screenshots the participant at any given moment`
                        }),
                        Lang(`Flag, menandai peserta yang dianggap mencurigakan`, LANG(), {
                            en: `Flag, marks participants who are considered suspicious`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Penjadwalan Ujian`, LANG(), {
                        en: `✔ Exam Scheduling`
                    }),
                    features: [
                        Lang(`*Kalender`, LANG(), {
                            en: `*Calendar`
                        }),
                        Lang(`*Notifikasi Penjadwalan via Email`, LANG(), {
                            en: `*Scheduling Notification via Email`
                        }),
                        Lang(`Menjadwalkan waktu pelaksanaan ujian`, LANG(), {
                            en: `Schedule exam time`
                        })
                        // `*Kalender`,
                        // `*Notifikasi Penjadwalan via Email`,
                        // `Menjadwalkan waktu pelaksanaan ujian`
                    ]
                },
                {
                    title: Lang(`✔ Deteksi Kecurangan Berbasis AI`, LANG(), {
                        en: `✔ AI-based Cheating Detection`
                    }),
                    features: [
                        Lang(`Deteksi Wajah`, LANG(), {
                            en: `Face Detection`
                        }),
                        Lang(`Analisa Suara`, LANG(), {
                            en: `Voice Analysis`
                        }),
                        Lang(`Analisa Wajah`, LANG(), {
                            en: `Face Analysis`
                        }),
                        Lang(`Fitur AI Yang Ditambahkan`, LANG(), {
                            en: `Added AI Features`
                        }),
                        Lang(`Mendeteksi dan mencatat kecurangan - kecurangan yang mungkin dilakukan peserta selama  ujian berlangsung`, LANG(), {
                            en: `Detect and record cheating - cheating that participants may do during the test`
                        })
                        // `Deteksi Wajah`,
                        // `Analisa Suara`,
                        // `Analisa Wajah`,
                        // `Fitur AI Yang Ditambahkan`,
                        // `Mendeteksi dan mencatat kecurangan - kecurangan yang mungkin dilakukan peserta selama  ujian berlangsung`
                    ]
                },
                {
                    title: Lang(`✔ Dukungan berbasis percakapan`, LANG(), {
                        en: `✔ Conversation-based support`
                    }),
                    features: [
                        Lang(`Peserta dapat mengirimkan permintaan bantuan `, LANG(), {
                            en: `Participants can send help requests`
                        }),
                        Lang(`Peserta dan pengawas dapat saling mengirimkan pesan lewat chatbox`, LANG(), {
                            en: `Participants and proctors can send messages to each other through chat boxes`
                        }),
                        Lang(`Percakapan dalam tim`, LANG(), {
                            en: `Team conversation`
                        })
                        // `Peserta dapat mengirimkan permintaan bantuan `,
                        // `Peserta dan pengawas dapat saling mengirimkan pesan lewat chatbox`,
                        // `Percakapan dalam tim`
                    ]
                },
                {
                    title: `✔ Post Proktor`,
                    features: [
                        Lang(`Tempat untuk mengelola, mengatur, dan mengkonfigurasi ujian online yang diselenggarakan`, LANG(), {
                            en: `A place to manage, organize, and configure organized online exams`
                        }),
                        Lang(`Monitor statistik kegiatan peserta secara live`, LANG(), {
                            en: `Monitor live participant activity statistics`
                        }),
                        Lang(`Melihat kembali pelaksanaan ujian yang telah dan sedang berlangsung`, LANG(), {
                            en: `Look back on past and ongoing exams`
                        }),
                        Lang(`Laporan video dan gambar`, LANG(), {
                            en: `Video and image reports`
                        })
                        // `Tempat untuk mengelola, mengatur, dan mengkonfigurasi ujian online yang diselenggarakan`,
                        // `Monitor statistik kegiatan peserta secara live`,
                        // `Melihat kembali pelaksanaan ujian yang telah dan sedang berlangsung`,
                        // `Laporan video dan gambar`
                    ]
                },
                {
                    title: Lang(`*✔ Laporan dikirimkan ke email`, LANG(), {
                    })
                },
                {
                    title: Lang(`*✔ Cloud Storage Tergantung Add-On`, LANG(), {
                        en: `*✔ Cloud Storage Dependent Add-On`
                    })
                },
            ],
            event: [
                {
                    title: Lang(`✔ Pengawasan Ujian Online`, LANG(), {
                        en: `Online Exam Proctoring`
                    }),
                    features: [
                        Lang(`*Untuk jumlah peserta dan lamanya ujian, silakan Hubungi Kami untuk informasi lebih lanjut`, LANG(), {
                            en: `*For the number of participants and duration of the exam, please Contact Us for more information`
                        }),
                        Lang(`$Notifikasi Warning secara Real-Time selama Ujian berlangsung`, LANG(), {
                            en: `$Real-Time Warning Notifications during the Exam`
                        }),
                        Lang(`Perekaman data gambar, video dan data digital tidak terbatas`, LANG(), {
                            en: `Unlimited image, video and digital data recording`
                        }),
                        Lang(`Bebas mengkonfigurasi kualitas video`, LANG(), {
                            en: `Free to configure video quality`
                        }),
                        Lang(`Jumlah Pengawas tidak terbatas`, LANG(), {
                            en: `Unlimited proctors`
                        }),
                        Lang(`Mengawasi pengawas`, LANG(), {
                            en: `Supervise proctors`
                        })
                        // `*Untuk jumlah peserta dan lamanya ujian, silakan Hubungi Kami untuk informasi lebih lanjut`,
                        // `$Notifikasi Warning secara Real-Time selama Ujian berlangsung`,
                        // `Perekaman data gambar, video dan data digital tidak terbatas`,
                        // `Bebas mengkonfigurasi kualitas video`,
                        // `Jumlah Pengawas tidak terbatas`,
                        // `Mengawasi pengawas`
                    ]
                },
                // {
                //     title: Lang(`*✔ Extras`, LANG(), {
                //         en: `*✔ Extras`
                //     }),
                //     features: [
                //         Lang(``, LANG(), {
                //             en: ``
                //         }),
                //     ]
                // },
                {
                    title: Lang(`✔ Kompatibilitas`, LANG(), {
                        en: `✔ Compatibility`
                    }),
                    features: [
                        Lang(`$Kompatibel dengan berbagai macam aplikasi penyedia soal dan LMS, misalnya Moodle, Canva, Google Drive, Socrates, dan lain-lain`, LANG(), {
                            en: `Compatible with a wide range of question providers and LMS applications, such as Moodle, Canva, Google Drive, Socrates, etc.`
                        }),
                    ]
                },
                {
                    title: Lang(`✔ Kustomisasi Ujian`, LANG(), {
                        en: `✔ Exam Customization`
                    }),
                    features: [
                        Lang(`*Hubungi kami untuk penyesuaian sesuai kebutuhan Anda`, LANG(), {
                            en: `Contact us for customization according to your needs`
                        }),
                    ]
                },
                {
                    title: Lang(`✔ Konfigurasi Ujian`, LANG(), {
                        en: `✔ Exam Configuration`
                    }),
                    features: [
                        Lang(`Mengkonfigurasi ujian online yang dilaksanakan`, LANG(), {
                            en: `Configure the online exam being administered`
                        }),
                        Lang(`Menambahkan pengawas`, LANG(), {
                            en: `Add proctors`
                        })
                    ]
                },
                {
                    title: `✔ Broadcast Alert`,
                    features: [
                        Lang(`$Mengirimkan pesan ke seluruh peserta ujian online`, LANG(), {
                            en: `$Send a message to all online test participants`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Penandaan Peserta`, LANG(), {
                        en: `✔ Examinee Tagging`
                    }),
                    features: [
                        Lang(`Notes, memberikan catatan khusus tentang seorang peserta ujian online`, LANG(), {
                            en: `Notes, provides customized notes about an online test taker`
                        }),
                        Lang(`Screenshot, menscreenshot tangkapan wajah peserta dan tangkapan layar peserta pada saat tertentu`, LANG(), {
                            en: `Screenshots, screenshot a participant's face and a screenshot of the participant at a specific moment in time`
                        }),
                        Lang(`Flag, menandai peserta yang dianggap mencurigakan`, LANG(), {
                            en: `Flag, marks participants who are considered suspicious`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Penjadwalan Ujian`, LANG(), {
                        en: `✔ Exam Scheduling`
                    }),
                    features: [
                        Lang(`Kalender`, LANG(), {
                            en: `Calendar`
                        }),
                        Lang(`Menjadwalkan waktu pelaksanaan ujian`, LANG(), {
                            en: `Schedule the time of the exam`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Deteksi Kecurangan Berbasis AI`, LANG(), {
                        en: `✔ AI-based Cheating Detection`
                    }),
                    features: [
                        Lang(`Deteksi Wajah`, LANG(), {
                            en: `Face Detection`
                        }),
                        Lang(`Analisa Suara`, LANG(), {
                            en: `Voice Analysis`
                        }),
                        Lang(`Analisa Wajah`, LANG(), {
                            en: `Face Analysis`
                        }),
                        Lang(`Fitur AI Yang Ditambahkan`, LANG(), {
                            en: `Added AI Features`
                        }),
                        Lang(`Mendeteksi dan mencatat kecurangan - kecurangan yang mungkin dilakukan peserta selama  ujian berlangsung`, LANG(), {
                            en: `Detect and record cheating - cheating that participants may do during the test`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Dukungan berbasis percakapan`, LANG(), {
                        en: `✔ Conversation-based support`
                    }),
                    features: [
                        Lang(`Peserta dapat mengirimkan permintaan bantuan`, LANG(), {
                            en: `Participants can send help requests`
                        }),
                        Lang(`Peserta dan pengawas dapat saling mengirimkan pesan lewat chatbox`, LANG(), {
                            en: `Participants and proctors can send messages to each other through chat boxes`
                        }),
                        Lang(`Percakapan dalam tim`, LANG(), {
                            en: `Team conversation`
                        })
                    ]
                },
                {
                    title: `✔ Post Proktor`,
                    features: [
                        Lang(`Tempat untuk mengelola, mengatur, dan mengkonfigurasi ujian online yang diselenggarakan`, LANG(), {
                            en: `A place to manage, organize, and configure organized online exams`
                        }),
                        Lang(`Monitor statistik kegiatan peserta secara live`, LANG(), {
                            en: `Monitor live participant activity statistics`
                        }),
                        Lang(`Melihat kembali pelaksanaan ujian yang telah dan sedang berlangsung`, LANG(), {
                            en: `Look back on past and ongoing exams`
                        }),
                        Lang(`Laporan video dan gambar`, LANG(), {
                            en: `Video and image reports`
                        })
                    ]
                },
                {
                    title: Lang(`✔ Laporan dikirimkan ke email `, LANG(), {
                        en: `✔ Reports sent to email`
                    })
                },
                {
                    title: Lang(`*✔ Cloud storage Tidak Terbatas`, LANG(), {
                        en: `*✔ Unlimited cloud storage`
                    })
                },
            ]
        }
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentWillUnmount() {
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    generateFeatures(title, features = []) {
        const vd = this.state.desktopView;

        let tl = title;
        if (title.includes("*")) {
            title = title.replace("*", "");
            tl = <>
                <span className={classes.highlighted}>{title}</span>
                <img
                    alt="new"
                    src={new1}
                />
            </>
        }

        return <div key={title}>
            <span className={vd ? null : classes.mobile}>
                {tl}
            </span>
            <div key={`_${title}`}>
                <ul className={vd ? null : classes.mobile}>
                    {features.map((v) => {
                        if (v.includes("*")) {
                            v = v.replace("*", "");
                            return <li
                                key={v}
                                className={classes.highlighted}>
                                {v}
                                <img
                                    alt="new"
                                    src={new1}
                                    width={"25px"}
                                    title="Fitur Baru"
                                />
                            </li>
                        } else if (v.includes("#")) {
                            v = v.replace("#", "");
                            return <li key={v} className={classes.highlightedblue}>
                                {v}
                            </li>
                        } else if (v.includes("$")) {
                            v = v.replace("$", "");
                            return <li key={v} className={classes.highlightedpink}>
                                {v}
                                <img
                                    alt="new"
                                    src={favorite}
                                    width={"25px"}
                                    title="Favorit"
                                />
                            </li>
                        }
                        return <li key={v}>{v}</li>
                    })}
                </ul>
            </div>
        </div>
    }

    generatePlan(title, price, signup, plans, plan_action = () => { }) {
        const vd = this.state.desktopView;
        return <div key={`${title}`} className={[classes.plan, vd ? null : classes.mobile].join(" ")}>
            <div className={classes.plan_title}>{title}</div>
            <div className={classes.plan_what}>
                <span
                    // className={classes.plan_what}
                    onClick={() => {
                        if (title.includes("Proktor First")) {
                            window.location.href = "/proktorfirstlearnmore"
                        } else if (title === "Proktor Freedom") {
                            window.location.href = "/proktorfreedomlearnmore"
                        } else if (title === "Proktor Event") {
                            window.location.href = "/proktoreventlearnmore"
                        }
                    }}
                >
                    Apa itu {title.includes("Proktor First") ? "Proktor First" : title} ?
                </span>
                {price !== "" ?
                    <div className={classes.plan_price}>
                        {/*price*/}
                        {/*price === "Gratis" ? <img alt="recommended" src={badge} width={"20px"} /> : null*/}
                    </div>
                    : null
                }
            </div>
            <div className={classes.plan_action}
                onClick={plan_action}
            >
                <span className={vd ? null : classes.mobile}>{signup}</span>
            </div>
            <div className={classes.plan_features}>
                {
                    plans.map((p) => {
                        return this.generateFeatures(
                            p.title,
                            p.features
                        )
                    })
                }
            </div>
        </div>
    }

    generateContent() {
        const vd = this.state.desktopView;
        return <div className={[classes.container, vd ? null : classes.mobile].join(" ")}>
            <NavPage desktopView={this.state.desktopView} />
            <div className={classes.container_title}>
                Paket Harga
            </div>
            <div className={[classes.container_plans, vd ? null : classes.mobile].join(" ")}>
                {this.generatePlan("Proktor First - Basic", "Gratis", DISABLE_START_FREE ? "Will be ready soon" : "Mulai Sekarang", this.plans.basic, () => {
                    if (DISABLE_START_FREE) {
                        window.location.href = "/sales"
                    } else {
                        window.location.href = "https://proktor.amanin.id"
                    }
                })}
                {this.generatePlan("Proktor First - Standard", "", "Will be ready soon", this.plans.standard, () => {
                    window.location.href = "/sales"
                })}
                {this.generatePlan("Proktor First - Pro", "", "Will be ready soon", this.plans.pro, () => {
                    window.location.href = "/sales"
                })}
                {this.generatePlan("Proktor Freedom", "", "Hubungi Kami Sekarang", this.plans.freedom,
                    () => {
                        window.location.href = "/sales"
                    })}
                {this.generatePlan("Proktor Event", "", "Hubungi Kami Sekarang", this.plans.event, () => {
                    window.location.href = "/sales"
                })}
            </div>
            <Footer />
            {/* {generateFooter(this)} */}
        </div>
    }

    render() {
        return this.generateContent();
    }
}

export default PricePlanPage;