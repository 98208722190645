import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _6_SpesifikasiSistem = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("6. Spesifikasi Sistem", LANG(), { en: '6. System Specifications' })}
        </span>
        <p>
            {Lang("Penggunaan Anda terhadap Platform dan Layanan memerlukan satu atau lebih perangkat yang kompatibel, akses Internet, dan perangkat lunak pihak ketiga tertentu, dan Anda mungkin diwajibkan untuk mendapatkan pembaruan atau peningkatan dari waktu ke waktu untuk Platform dan/atau Layanan atau perangkat lunak pihak ketiga, yang mungkin mengakibatkan biaya tambahan bagi Anda. Karena penggunaan Platform dan Layanan melibatkan perangkat keras, perangkat lunak, dan akses Internet, kemampuan Anda untuk mengakses dan menggunakan Layanan dan Perangkat Lunak dapat dipengaruhi oleh kinerja dari hal-hal tersebut. Akses Internet berkecepatan tinggi direkomendasikan. Anda sepenuhnya bertanggung jawab atas setiap biaya yang mungkin berlaku untuk akses atau penggunaan Platform dan Layanan, termasuk biaya untuk perangkat keras, perangkat lunak, atau akses Internet. Anda menyetujui bahwa persyaratan tersebut menjadi tanggung jawab Anda, dan Proktor Amanin dapat, dengan kebijaksanaannya sendiri, menghentikan ketersediaan atau kompatibilitas Platform dan Layanan, pada sistem operasi, perangkat, atau platform tertentu.", LANG(),
                { en: 'Your use of the Platform and Service requires one or more compatible devices, Internet access, and specific third-party software, and you may be required to obtain updates or upgrades from time to time for the Platform and/or Service or third-party software, which may result in additional costs to you. Because the use of the Platform and Service involves hardware, software, and Internet access, your ability to access and use the Service and Software may be affected by the performance of these items. High-speed Internet access is recommended. You are solely responsible for any costs that may apply to access or use of the Platform and Service, including costs for hardware, software, or Internet access. You agree that these requirements are your responsibility, and Proktor Amanin may, at its discretion, terminate the availability or compatibility of the Platform and Service on certain operating systems, devices, or platforms.' })}
        </p>
    </div>
}