import blog from "../../../assets/imgs/content/amanin_desc7.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle7 = {
    title: Lang(`Memperbanyak Pendidikan Daring (Online)`, LANG(), { en: 'Expanding Online Education' }),
    img: blog,
    shorttext:
        Lang(`Pandemi Covid-19 mengajarkan kepada kita tentang pentingnya  pendidikan online. Tidak pernah terbayangkan sebelumnya, ternyata pendidikan online itu jauh lebih baik dari yang kita bayangkan sebelumnya. Pendidikan online memberikan akses yang lebih luas pada pendidikan, sehingga orang dari berbagai lokasi dan latar belakang dapat mengakses materi pembelajaran dengan lebih mudah. Selain itu, pendidikan online juga lebih fleksibel dalam hal jadwal, sehingga siswa dapat belajar sesuai dengan waktu yang paling nyaman bagi mereka.`, LANG(), { en: 'The Covid-19 pandemic taught us the importance of online education. It was beyond our imagination that online education could be much better than we had previously thought. Online education provides broader access to learning, allowing people from various locations and backgrounds to access educational materials more easily. Moreover, online education offers more flexibility in scheduling, enabling students to learn at times most convenient for them.' }),
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang("Pandemi Covid-19 mengajarkan kepada kita tentang pentingnya  pendidikan online. Tidak pernah terbayangkan sebelumnya, ternyata pendidikan online itu jauh lebih baik dari yang kita bayangkan sebelumnya. Pendidikan online memberikan akses yang lebih luas pada pendidikan, sehingga orang dari berbagai lokasi dan latar belakang dapat mengakses materi pembelajaran dengan lebih mudah. Selain itu, pendidikan online juga lebih fleksibel dalam hal jadwal, sehingga siswa dapat belajar sesuai dengan waktu yang paling nyaman bagi mereka.", LANG(), { en: 'The Covid-19 pandemic taught us the importance of online education. It was beyond our imagination that online education could be much better than we had previously thought. Online education provides broader access to learning, allowing people from various locations and backgrounds to access educational materials more easily. Moreover, online education offers more flexibility in scheduling, enabling students to learn at times most convenient for them.' })}

        </p>
        <p>
            {Lang("Bagi Indonesia yang geografisnya tersebar begitu luas, terdiri dari ribuan pulau yang terpisahkan oleh lautan, pendidikan online dapat menjadi solusi bagi pemerataan pendidikan. Tiadanya biaya transportasi dan akomodasi membuat pendidikan online dapar dijangkau oleh seluruh masyarakat dari berbagai lokasi dengan berbagai latar belakang sosial.", LANG(), { en: 'For Indonesia, with its vast geography consisting of thousands of islands separated by seas, online education can be a solution for educational equity. The absence of transportation and accommodation costs makes online education accessible to people from various locations and diverse social backgrounds.' })}

        </p>
        <p>
            {Lang("Tiba-tiba saja pandemi Covid-19 menyadarkan kepada kita tentang solusi pemerataan pendidikan yang ada di depan mata.", LANG(), { en: 'Suddenly, the Covid-19 pandemic made us realize the solution for educational equity was right in front of us.' })}

        </p>
        <p>
            {Lang("Memang masih banyak PR yang harus kita kerjakan supaya kualitas pendidikan online tidak kalah dibandingkan pendidikan tatap muka. Tetapi sudah banyak teknologi yang tersedia untuk membantu meningkatkan kualitas pendidikan online ini. Banyak solusi Learning Management System yang harganya sangat terjangkau untuk mengelola pendidikan online dengan kualitas yang tinggi.", LANG(), { en: 'There is still much work to be done to ensure that the quality of online education matches that of face-to-face education. However, there is already a lot of technology available to help improve the quality of online education. Many affordable Learning Management System (LMS) solutions are available to manage online education with high quality' })}

        </p>
        <p>
            {Lang("Bagaimana dengan ujian online? Bukankah ujian online itu rawan kecurangan?", LANG(), { en: "What about online exams? Aren't they prone to cheating?" })}

        </p>
        <p>
            {Lang("Jangan khawatir. Teknologi untuk meminimalkan kecurangan dalam ujian online sudah banyak tersedia. Salah satunya adalah Online Proctoring, solusi ujian online berbasis Artificial Intelligence yang dikembangkan PT Amanin Inteligensia International. Tunggu apa lagi? Yuk kita perbanyak pendidikan online dengan memanfaatkan berbagai teknologi yang tersedia.", LANG(), { en: "Don't worry. There is plenty of technology available to minimize cheating in online exams. One such technology is Online Proctoring, an AI-based online exam solution developed by PT Amanin Inteligensia International. So, what are we waiting for? Let's expand online education by utilizing the various technologies available." })}

        </p>
    </article>
}