import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _3_PenggunaanPlatformDanLayanan = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("3. Penggunaan Platform dan Layanan", LANG(), { en: '3. Usage of Platform and Service' })}
        </span>
        <p>
            {Lang("Setiap akses dan penggunaan Platform dan Layanan Proktor Amanin tunduk kepada Syarat dan Ketentuan ini.", LANG(), { en: 'Any access and use of Proktor Amanin`s Platform and Service is subject to these Terms and Conditions.' })}
        </p>
        <p>
            {Lang("Dengan mematuhi ketentuan dalam Syarat dan Ketentuan ini, Anda diberikan lisensi non-eksklusif, terbatas, tidak dapat dipindahkan, dan dapat dicabut dengan bebas untuk mengakses dan menggunakan Platform dan Layanan untuk keperluan bisnis atau pribadi. Setiap orang harus memiliki akun yang unik, dan Anda bertanggung jawab atas setiap aktivitas yang dilakukan pada akun Anda. Anda tidak diizinkan memberi izin kepada pihak lain untuk mengakses atau menggunakan Platform dan Layanan dengan menggunakan nama pengguna unik, kata sandi, atau kode keamanan lainnya.", LANG(), { en: 'By complying with the provisions in these Terms and Conditions, you are granted a non-exclusive, limited, non-transferable, and freely revocable license to access and use the Platform and Service for business or personal purposes. Each person must have a unique account, and you are responsible for all activities conducted on your account. You are not permitted to authorize others to access or use the Platform and Service using your unique username, password, or other security code.' })}
        </p>
    </div>
}