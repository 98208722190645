import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _15_PersetujuanPengguna = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("16. PERSETUJUAN PENGGUNA UNTUK MENERIMA KOMUNIKASI DALAM BENTUK ELEKTRONIK", LANG(), { en: '16. USER CONSENT TO RECEIVE COMMUNICATIONS IN ELECTRONIC FORM' })}
        </span>
        <p>
            {Lang("Untuk tujuan kontraktual, Anda:", LANG(), { en: 'For contractual purposes, you:' })}
        </p>
        <ol type="a">
            <li>
                {Lang("memberikan persetujuan untuk menerima komunikasi dari Kami dalam bentuk elektronik melalui alamat email yang Anda kirimkan; dan", LANG(), { en: 'Consent to receive communications from us in electronic form via the email address you provide; and' })}
            </li>
            <li>
                {Lang("menyetujui bahwa semua Syarat dan Ketentuan, perjanjian, pemberitahuan, pengungkapan, dan komunikasi lain yang Kami berikan kepada Anda secara elektronik memenuhi persyaratan hukum apa pun yang harus dipenuhi oleh komunikasi semacam itu jika ditulis. Hal tersebut tidak memengaruhi hak-hak yang tidak dapat Anda abaikan.", LANG(), { en: 'Agree that all Terms and Conditions, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirements that such communications would fulfill if they were in writing. This does not affect any rights you cannot waive.' })}
            </li>
        </ol>
        <p>
            {Lang("Kami juga dapat menggunakan alamat email Anda untuk mengirimkan pesan lain, termasuk informasi tentang Platform dan Layanan dan penawaran khusus.", LANG(), { en: 'We may also use your email address to send you other messages, including information about the Platform and Services and special offers.' })}
        </p>
    </div>
}