import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _7_HubungiKami = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("7. HUBUNGI KAMI", LANG(), { en: '7. CONTACT US' })}</span>
        <p>
            {Lang("Jika Anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau praktik situs ini, harap hubungi Kami ke : info@amanin.id", LANG(), { en: 'If you have any questions about this Privacy Policy or the practices of this site, please contact us at: info@amanin.id' })}
        </p>
    </div>
}