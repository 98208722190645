import blog from "../../../assets/imgs/content/amanin_desc4.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle4 = {
    title: Lang(`Sistem Pendidikan Hibrid`, LANG(), { en: 'Hybrid Education System' }),
    img: blog,
    shorttext: Lang(`Sejak selesainya pandemi Covid-19, sistem pendidikan hibrid makin popular. Mengapa? Karena sistem ini menggabungkan elemen-elemen dari pendidikan tatap muka (in-person) dan pendidikan jarak jauh (online). Sistem pendidikan hibrid mempunya banyak keunggulan, terutama karena memberikan pengalaman pembelajaran yang lebih fleksibel dan responsif terhadap kebutuhan siswa dan kondisi tertentu.`, LANG(), { en: "Since the end of the Covid-19 pandemic, hybrid education systems have become increasingly popular. Why? Because this system combines elements of in-person and online learning. The hybrid education system offers many advantages, especially by providing a more flexible and responsive learning experience tailored to students' needs and specific circumstances." }),
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang("Sejak selesainya pandemi Covid-19, sistem pendidikan hibrid makin popular. Mengapa? Karena sistem ini menggabungkan elemen-elemen dari pendidikan tatap muka (in-person) dan pendidikan jarak jauh (online). Sistem pendidikan hibrid mempunya banyak keunggulan, terutama karena memberikan pengalaman pembelajaran yang lebih fleksibel dan responsif terhadap kebutuhan siswa dan kondisi tertentu.", LANG(), { en: "Since the end of the Covid-19 pandemic, hybrid education systems have become increasingly popular. Why? Because this system combines elements of in-person and remote learning. The hybrid education system offers many advantages, particularly by providing a more flexible learning experience that is responsive to students' needs and specific circumstances." })}

        </p>
        <p>
            {Lang("Berikut adalah beberapa alasan mengapa sistem pendidikan hibrid semakin populer:", LANG(), { en: 'Here are several reasons why hybrid education systems are becoming more popular:' })}
        </p>
        <ol>
            <li>
                {Lang("Fleksibilitas Pembelajaran: Sistem pendidikan hibrid memungkinkan siswa untuk mengakses materi pembelajaran baik secara langsung di kelas maupun secara online. Ini memberikan fleksibilitas bagi siswa untuk memilih cara terbaik bagi mereka untuk belajar, sesuai dengan gaya belajar dan jadwal mereka.", LANG(), { en: 'Learning Flexibility: The hybrid education system allows students to access learning materials both directly in the classroom and online. This provides flexibility for students to choose the best way for them to learn, according to their learning style and schedule.' })}

            </li><li>
                {Lang("Ketersediaan Sumber Daya Online: Ketersediaan sumber daya online, seperti platform pembelajaran digital, video pelajaran, dan sumber belajar interaktif, telah membuat pendidikan jarak jauh lebih mudah diakses. Ini memungkinkan pendidikan hibrid untuk memanfaatkan teknologi ini sebagai tambahan atau pengganti bahan ajar tradisional.", LANG(), { en: 'Availability of Online Resources: The availability of online resources, such as digital learning platforms, instructional videos, and interactive learning resources, has made remote education more accessible. This allows hybrid education to utilize this technology as a supplement or replacement for traditional teaching materials.' })}

            </li><li>
                {Lang("Adaptasi Terhadap Perkembangan Teknologi: Kemajuan teknologi telah memungkinkan pendidikan hibrid untuk berkembang pesat. Guru dapat menggunakan alat-alat seperti konferensi video dan platform pembelajaran online untuk mengajar siswa dari berbagai lokasi.", LANG(), { en: 'Adaptation to Technological Advances: Technological advancements have enabled hybrid education to evolve rapidly. Teachers can use tools like video conferencing and online learning platforms to teach students from various locations.' })}

            </li><li>
                {Lang("Kecerdasan Buatan (AI) dan Personalisasi: Teknologi kecerdasan buatan memungkinkan pendidikan hibrid untuk menyediakan pengalaman pembelajaran yang lebih personal. AI dapat membantu dalam menganalisis kemajuan siswa dan memberikan rekomendasi pembelajaran yang disesuaikan dengan kebutuhan masing-masing siswa", LANG(), { en: "Artificial Intelligence (AI) and Personalization: AI technology allows hybrid education to provide a more personalized learning experience. AI can help analyze student progress and offer learning recommendations tailored to each student's needs." })}
                .
            </li><li>
                {Lang("Meningkatkan Keterlibatan Siswa: Pendidikan hibrid memungkinkan guru untuk menciptakan pengalaman pembelajaran yang lebih menarik dengan mengintegrasikan teknologi, permainan edukatif, dan interaksi online. Ini dapat meningkatkan keterlibatan siswa dalam pembelajaran.", LANG(), { en: 'Enhancing Student Engagement: Hybrid education allows teachers to create more engaging learning experiences by integrating technology, educational games, and online interactions. This can increase student engagement in learning.' })}

            </li><li>
                {Lang("Persiapan untuk Dunia Digital: Pendidikan hibrid mempersiapkan siswa untuk dunia yang semakin terhubung secara digital. Mereka belajar untuk menggunakan teknologi dengan bijak dan beradaptasi dengan berbagai bentuk pembelajaran.", LANG(), { en: 'Preparation for the Digital World: Hybrid education prepares students for an increasingly digitally connected world. They learn to use technology wisely and adapt to various forms of learning' })}

            </li><i>
                {Lang("Fleksibilitas Administratif: Sistem pendidikan hibrid juga memberikan fleksibilitas administratif bagi sekolah dan perguruan tinggi. Mereka dapat mengatur jadwal dan sumber daya mereka dengan lebih efisien, yang dapat menghemat biaya operasional.", LANG(), { en: 'Administrative Flexibility: The hybrid education system also provides administrative flexibility for schools and colleges. They can manage their schedules and resources more efficiently, which can save operational costs.' })}

            </i>
        </ol>
        <p>
            {Lang("Sistem pendidikan hibrid tidak hanya relevan selama pandemi, tetapi juga dapat menjadi pilihan yang berharga untuk memenuhi berbagai kebutuhan pembelajaran di masa depan. Kombinasi antara pembelajaran online dan tatap muka dapat meningkatkan aksesibilitas, fleksibilitas, dan efektivitas pendidikan.", LANG(), { en: 'The hybrid education system is not only relevant during the pandemic but can also be a valuable option to meet various learning needs in the future. The combination of online and in-person learning can enhance the accessibility, flexibility, and effectiveness of education.' })}

        </p>
    </article>
}