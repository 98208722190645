// export const _4_KeamananDanKebijakanPrivasi = (_this, classes) => {
//     return <div className={classes.paragraph}>
//         <span className={classes.partitle}>
//         </span>
//         <p>
//         </p>
//         <p>
//         </p>
//         <p>
//         </p>
//         <p>
//         </p>
//         <p>
//         </p>
//     </div>
// }

import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

// {Lang("", LANG(), { en: '' })}
export const _4_KeamananDanKebijakanPrivasi = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("4. Keamanan dan Kebijakan Privasi", LANG(), { en: '4. Security and Privacy Policy' })}
        </span>
        <p>
            {Lang("Proktor Amanin menerapkan  langkah-langkah keamanan fisik, teknis, dan administratif yang dirancang untuk melindungi informasi Anda dari akses, penghancuran, penggunaan, modifikasi, atau pengungkapan yang tidak sah. Anda dapat mempelajari lebih lanjut tentang perlindungan Layanan dan informasi Anda di Kebijakan Privasi Proktor Amanin.", LANG(), { en: 'Proktor Amanin implements physical, technical, and administrative security measures designed to protect your information from unauthorized access, destruction, use, modification, or disclosure. You can learn more about the protection of our Services and your information in the Proktor Amanin Privacy Policy.' })}
        </p>
        <p>
            {Lang("Kebijakan Privasi Proktor Amanin menjelaskan cara Kami mengumpulkan, menggunakan, membagikan, menjaga, mengungkap, dan menyimpan data pribadi Anda", LANG(), { en: 'The Proktor Amanin Privacy Policy explains how we collect, use, share, maintain, disclose, and store your personal data.' })} .
        </p>
        <p>
            {Lang("Dengan mendaftar pada dan/atau menggunakan Platform dan Layanan Proktor Amanin berarti Anda memberikan persetujuan dan menyetujui Kebijakan Privasi kami, dan Anda diberi pemberitahuan dan mengakui bahwa pengumpulan, berbagi, dan pemrosesan (yang mungkin mencakup pengorganisasian, strukturisasi, penyimpanan, penggunaan, atau pengungkapan) data pribadi Anda akan tunduk pada Kebijakan Privasi kami.", LANG(), { en: 'By registering for and/or using the Platform and Service of Proktor Amanin, you consent to and agree to our Privacy Policy, and you are notified and acknowledge that the collection, sharing, and processing (which may include organizing, structuring, storing, using, or disclosing) of your personal data will be subject to our Privacy Policy.' })}
        </p>
    </div>
}