import React from "react";

import classes from "./__index__.module.scss";

import NavPage from "../../components/Nav";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { handleScreenChange } from "../../libs/utils";
import Footer from "../../components/Footer";
import { __Achievement__ } from "./Achievement";
import { __Portofolio__ } from "./Portofolio";
import { __VisionMission__ } from "./VisionMission";
import { __Product__ } from "./Product";
import { __Solution__ } from "./Solution";
import { __Videos__ } from "./Videos";

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();
        this.productRef = React.createRef();
        this.solutionRef = React.createRef();
        this.videoRef = React.createRef();

        this._achievement = new __Achievement__(this);
        this._portofolio = new __Portofolio__(this);
        this._visionmission = new __VisionMission__(this);
        this._product = new __Product__(this);
        this._solution = new __Solution__(this);
        this._videos = new __Videos__(this);

        this.state = {
            resolution: { width: window.innerWidth, height: window.innerHeight },

            desktopView: true,
            chosenProduct: "Proktor First",
            carouselIdx: 0,

            body: {
                loading: 0,
                content_id: 0,
                closeContent: 'false'
            },
            body3: {
                chosenIndustry: null,
                showMainDesc: true,
                itemReadyForClick: true,
                chosenIndustryContent: {
                    img: null,
                    title: "",
                    text: ""
                }
            },

            body4: {
                chosenCompleteReview: null,
                chosenCompleteReviewFadeOut: false,
            }
        }
        this.carouselInterval = null
    }

    scrollToProduct() {
        this.productRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    scrollToSolution() {
        this.solutionRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    scrollToVideo() {
        this.videoRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    handleResize = () => {
        this.setState({
            resolution: { width: window.innerWidth, height: window.innerHeight },
        });
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);

        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)

        setInterval(() => {
            let body = this.state.body;
            if (body.loading > 100) {
                body.loading = 0;
                body.closeContent = 'true';
                setTimeout(() => {
                    body.closeContent = 'preparation';
                    body.content_id = (body.content_id + 1) % this._visionmission.getBodyContentLength();
                    this.setState({ body: body });

                    setTimeout(() => {
                        body.closeContent = 'false'
                    }, 600)
                }, 500);
            } else {
                body.loading += 0.25;
            }
            this.setState({ body: body });
        }, 50);
    }

    generateNav() {
        return <NavPage
            desktopView={this.state.desktopView}
            _this={this}
        />
    }

    generateWidthHeightInfo() {
        const { width, height } = this.state.resolution;
        return (
            <div>
                <p>Lebar Layar: {width}px</p>
                <p>Tinggi Layar: {height}px</p>
            </div>
        );
    }

    render() {
        // return this.generateWidthHeightInfo();
        return <div className={classes.container}>
            {/* {<div>
                <p>Lebar Layar: {width}px</p>
                <p>Tinggi Layar: {height}px</p>
            </div>}  */}
            {this.generateNav()}
            {this._visionmission.render()}
            {this._product.render()}
            {this._solution.render()}
            {this._achievement.render()}
            {this._videos.render()}
            {this._portofolio.render()}
            <Footer />
        </div>
    }
}

export default withRouter(HomePage);
