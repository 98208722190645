import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

// {Lang("", LANG(), { en: '' })}
export const _5_InformasiAkun = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("5. Informasi Akun", LANG(), { en: '5. Account Information' })}
        </span>
        <p>
            {Lang("Anda mungkin diminta untuk memberikan informasi tentang diri Anda untuk mendaftar dan mengakses atau menggunakan Platform dan Layanan Proktor Amanin. Anda menyatakan dan menjamin bahwa informasi tersebut, termasuk Data Pelanggan, adalah dan akan tetap akurat dan lengkap, dan bahwa Proktor Amanin tidak memiliki tanggung jawab sama sekali atas kesalahan dan kelalaian dalam Data Pelanggan Anda. Anda juga mungkin diminta untuk memilih nama pengguna dan kata sandi untuk mengakses atau menggunakan Platform dan Layanan Proktor Kami. Kami dapat menolak, atau mengharuskan Anda mengubah, nama pengguna atau kata sandi tersebut sesuai kebijakan Kami. Anda sepenuhnya bertanggung jawab atas menjaga keamanan nama pengguna dan kata sandi Anda, dan Anda setuju untuk tidak mengungkapkan atau membuat nama pengguna atau kata sandi Anda dapat diakses oleh pihak ketiga manapun.", LANG(),
                { en: 'You may be required to provide information about yourself to register and access or use the Platform and Service of Proktor Amanin. You represent and warrant that such information, including Customer Data, is and will remain accurate and complete, and that Proktor Amanin has no responsibility whatsoever for errors and omissions in your Customer Data. You may also be required to select a username and password to access or use our Platform and Service. We may refuse or require you to change such username or password in accordance with our policy. You are solely responsible for maintaining the security of your username and password, and you agree not to disclose or make your username or password accessible to any third party.' })}
        </p>
    </div>
}