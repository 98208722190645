import React from "react";
import classes from "./Brand.module.scss"
import Image from 'react-bootstrap/Image';
import brand from "../assets/imgs/proktoramanin.png";
import brandwhite from "../assets/imgs/proktoramaninwhite.png";

class Brand extends React.Component {
    render() {
        return <div className={
            [
                classes.container,
                this.props.desktopView ? null : classes.mobile
            ].join(" ")
        }>
            <a href="/">
                <img
                    src={this.props.white ? brandwhite : brand}
                    alt="logo"
                // height="40px" 
                />
            </a>
        </div>
    }
}

export default Brand;