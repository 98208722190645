import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _10_TanggungJawabTerkaitPenggunaan = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("11. Tanggung Jawab Terkait Penggunaan Layanan dan Pengguna Akhir", LANG(), { en: '11. Responsibilities Regarding Service Usage and End Users:' })}
        </span>
        <ol type="a">
            <li>
                {Lang("Anda akan, dan Anda akan memastikan Pengguna Akhir Anda, mematuhi dan memastikan kepatuhan terhadap Syarat dan Ketentuan ini. Penggunaan Platform dan Layanan dinyatakan tidak sah di tempat yang dilarang. Anda bertanggung jawab atas akses Anda dan akses Pengguna Akhir Anda ke Platform dan Layanan. Anda bertanggung jawab atas kegiatan semua Pengguna Akhir Anda, termasuk memastikan bahwa semua Pengguna Akhir akan mematuhi Syarat dan Ketentuan ini dan kebijakan Proktor Amanin yang berlaku. Anda mengakui bahwa Anda tetap bertanggung jawab atas tindakan dan kelalaian pihak ketiga apa pun yang Anda izinkan, mungkin, atau sebaliknya memberikan akses ke Platform dan Layanan, apakah akses tersebut secara tegas diizinkan oleh Proktor Amanin atau tidak.", LANG(),
                    { en: "You will, and you will ensure your End Users, comply with and ensure compliance with these Terms and Conditions. Use of the Platform and Service is declared illegal in places where prohibited. You are responsible for your access and the access of your End Users to the Platform and Service. You are responsible for the activities of all your End Users, including ensuring that all End Users comply with these Terms and Conditions and Proktor Amanin's applicable policies. You acknowledge that you remain responsible for the actions and omissions of any third party that you allow, enable, or otherwise provide access to the Platform and Service, whether such access is expressly permitted by Proktor Amanin or not." })}
            </li>
            <li>
                {Lang("Proktor Amanin tidak bertanggung jawab atau memiliki tanggung jawab atas pelanggaran terhadap Syarat dan Ketentuan ini oleh Pengguna Akhir atau pihak ketiga lainnya yang Anda izinkan, arahkan, atau mungkin memberikan akses ke Platform dan Layanan. Jika Anda mengetahui adanya pelanggaran terhadap Syarat dan Ketentuan ini sehubungan dengan penggunaan Platform dan Layanan oleh seseorang, Anda harus menghubungi Kami.", LANG(),
                    { en: "Proktor Amanin is not responsible or liable for violations of these Terms and Conditions by End Users or other third parties that you allow, direct, or may provide access to the Platform and Service. If you become aware of any violation of these Terms and Conditions in connection with someone's use of the Platform and Service, you must contact us." })}
            </li>
            <li>
                {Lang("Dalam keadaan apapun Proktor Amanin tidak akan bertanggung jawab atas data atau konten lain yang dilihat saat menggunakan Platform dan Layanan, termasuk kesalahan atau kelalaian dalam data atau konten tersebut, atau kerugian atau kerusakan dari segala jenis yang timbul sebagai hasil dari penggunaan, akses, atau penolakan akses ke data atau konten apa pun.", LANG(), { en: 'Under no circumstances will Proktor Amanin be liable for data or other content viewed while using the Platform and Service, including errors or omissions in such data or content, or loss or damage of any kind arising from the use, access, or denial of access to any data or content.' })}
            </li>
            <li>
                {Lang("Proktor Amanin dapat menyelidiki setiap keluhan dan pelanggaran yang Kami perhatikan dan dapat mengambil tindakan apa pun, atas kebijakan Kami sendiri, termasuk memberikan peringatan, menangguhkan atau memutuskan Platform dan Layanan, menghapus data atau konten yang bersangkutan, mengakhiri akun atau profil Pengguna Akhir, atau mengambil tindakan lain yang wajar menurut kebijakan Kami.", LANG(),
                    { en: 'Proktor Amanin may investigate any complaints and violations that we notice and may take any action, at our own discretion, including giving warnings, suspending or terminating the Platform and Service, deleting the relevant data or content, terminating the End User account or profile, or taking other reasonable actions according to our policies.' })}
            </li>
        </ol>
    </div>
}