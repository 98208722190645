import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _14_EmailTidakBoleh = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("15. EMAIL TIDAK BOLEH DIGUNAKAN UNTUK PEMBERITAHUAN", LANG(), { en: '15. EMAIL MUST NOT BE USED FOR NOTIFICATION' })}
        </span>
        <p>
            {Lang("Komunikasi melalui sistem email dan pesan Kami tidak akan dianggap sebagai pemberitahuan hukum kepada Kami, Platform, Layanan, atau pihak-pihak terkait lainnya seperti para petugas, karyawan, agen, atau perwakilan dalam situasi di mana pemberitahuan hukum diperlukan oleh kontrak atau hukum atau peraturan apa pun.", LANG(),
                { en: 'Communications through our email and messaging system will not constitute legal notice to us, the Platform, the Services, or any of our officers, employees, agents, or representatives in any situation where legal notice is required by contract or any law or regulation.' })}
        </p>
    </div>
}