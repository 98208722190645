import React from "react";

import classes from "./ProktorFirstLM.module.scss";
import NavPage from "../../components/Nav";
import { handleScreenChange } from "../../libs/utils";
import { generateBody1 } from "./Body1";
import { generateBody2 } from "./Body2";
import { generateBody3 } from "./Body3";
// import { generateFooter } from "../HomePage/Footer";
import { generateBody4 } from "./Body4";
import { generateBody5 } from "./Body5";
import Footer, { generateFooter } from "../../components/Footer";

class ProktorFirstLM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desktopView: true,
        }

    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    generateContent() {
        const vd = this.state.desktopView;
        // return <>
        //     <NavPage desktopView={this.state.desktopView}
        //         _this={this}
        //     />
        //     <Footer />

        // </>
        return <>
            <NavPage desktopView={this.state.desktopView}
                _this={this}
            />
            <div className={[
                classes.container,
                vd ? null : classes.mobile].join(" ")}>

                {generateBody1(this)}
                {generateBody2(this)}
                {generateBody3(this)}
                {generateBody4(this)}
                {generateBody5(this)}
                <Footer />
            </div>
        </>
    }
    render() {
        return this.generateContent();
    }
}

export default ProktorFirstLM;