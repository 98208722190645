import React from "react";

import classes from "./ProktorEventLM.module.scss";
import NavPage from "../../components/Nav";
import { handleScreenChange } from "../../libs/utils";
import { generateBody1 } from "./Body1";
import { generateBody2 } from "./Body2";
import { generateBody3 } from "./Body3";
// import { generateFooter } from "../HomePage/Footer";
import { generateBody4 } from "./Body4";
import { generateBody5 } from "./Body5";
import Footer from "../../components/Footer";
import { casestudyitbki, casestudyitbsm } from "../../assets/imgs";

class ProktorEventLM extends React.Component {
    constructor(props) {
        super(props);
        this.caseRef = React.createRef();
        this.state = {
            desktopView: true,
        }
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    generateCaseStudy() {
        const vd = this.state.desktopView;
        return <>
            <div
                ref={this.caseRef}
                className={[classes.casestudy, vd ? null : classes.mobile].join(" ")}>
                <img alt="case" src={casestudyitbsm}
                    width={"800px"}
                />
            </div>
            <div
                // ref={this.caseRef}
                className={[classes.casestudy, vd ? null : classes.mobile].join(" ")}>
                <img alt="case" src={casestudyitbki}
                    width={"800px"}
                />
            </div>
        </>
    }

    generateContent() {
        const vd = this.state.desktopView;
        return <div className={[classes.container, vd ? null : classes.mobile].join(" ")}>
            <NavPage desktopView={this.state.desktopView} />
            {generateBody1(this)}
            {/* {generateBody2(this)}
            {generateBody3(this)}
            {generateBody4(this)}
            {generateBody5(this)} */}
            {/* {generateFooter(this)} */}
            {this.generateCaseStudy()}
            <Footer />
        </div>
    }
    render() {
        return this.generateContent();
    }
}

export default ProktorEventLM;