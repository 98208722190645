import blog from "../../../assets/imgs/content/amanin_desc6.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle14 = {
    title: Lang(`Menggunakan Solusi Online Proctoring dalam Ujian Online`, LANG(), { en: 'Using Online Proctoring Solutions in Online Exams' }),
    img: blog,
    shorttext: Lang(`Menggunakan solusi Online Proctoring dalam ujian online penting dengan beberapa alasan, karena mereka membantu menjaga integritas dan keamanan penilaian online:`, LANG(), { en: 'Using Online Proctoring solutions in online exams is crucial for several reasons, as they help maintain the integrity and security of online assessments:' }),
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang('Menggunakan solusi Online Proctoring dalam ujian online penting dengan beberapa alasan, karena mereka membantu menjaga integritas dan keamanan penilaian online:', LANG(), { en: 'Using Online Proctoring solutions in online exams is crucial for several reasons, as they help maintain the integrity and security of online assessments:' })}

        </p>
        <ol>
            <li>
                {Lang("Mencegah Kecurangan: Solusi Online Proctoring membantu mencegah kecurangan selama ujian online. Mereka dapat memantau siswa secara real-time, termasuk memverifikasi identitas mereka, melacak gerakan mata, dan menandai perilaku yang mencurigakan seperti mencari jawaban atau menggunakan sumber daya yang tidak sah.", LANG(), { en: 'Preventing Cheating: Online Proctoring solutions help prevent cheating during online exams. They can monitor students in real-time, including verifying their identity, tracking eye movements, and flagging suspicious behaviors such as looking up answers or using unauthorized resources.' })}

            </li><li>
                {Lang("Otentikasi Ujian: Solusi Online Proctoring sering kali mencakup langkah-langkah otentikasi, seperti verifikasi ID dan pengenalan wajah, untuk memastikan bahwa orang yang mengikuti ujian adalah siswa terdaftar.", LANG(), { en: 'Exam Authentication: Online Proctoring solutions often include authentication measures, such as ID verification and facial recognition, to ensure that the person taking the exam is the registered student.' })}

            </li><li>
                {Lang("Menjaga Integritas Akademik: Online Proctoring online mendorong integritas akademik dengan memastikan bahwa siswa dinilai secara adil berdasarkan pengetahuan dan keterampilan mereka sendiri, bukan kemampuan mereka untuk mengakses sumber daya eksternal atau berkolaborasi dengan orang lain.", LANG(), { en: 'Maintaining Academic Integrity: Online Proctoring promotes academic integrity by ensuring that students are evaluated fairly based on their own knowledge and skills, rather than their ability to access external resources or collaborate with others.' })}

            </li><li>
                {Lang("Mencegah Pemalsuan: Solusi Online Proctoring membantu mencegah pemalsuan, di mana orang lain mengikuti ujian atas nama siswa terdaftar.", LANG(), { en: 'Preventing Impersonation: Online Proctoring solutions help prevent impersonation, where someone else takes the exam on behalf of the registered student.' })}

            </li><li>
                {Lang("Meningkatkan Keamanan Ujian: Online Proctoring online menambahkan lapisan keamanan tambahan dalam proses penilaian online, mengurangi risiko akses tidak sah ke pertanyaan ujian atau kecurangan.", LANG(), { en: 'Enhancing Exam Security: Online Proctoring adds an extra layer of security to the online assessment process, reducing the risk of unauthorized access to exam questions or cheating.' })}

            </li><li>
                {Lang("Efek Deterren: Mengetahui bahwa mereka sedang dimonitor dapat mencegah siswa mencoba untuk curang, dengan demikian mempromosikan budaya kejujuran dan integritas dalam pendidikan online.", LANG(), { en: 'Deterrent Effect: Knowing they are being monitored can deter students from attempting to cheat, thus promoting a culture of honesty and integrity in online education.' })}

            </li><li>
                {Lang("Dukungan Pembelajaran Jarak Jauh: Solusi Online Proctoring online memungkinkan pelaksanaan ujian yang aman dalam lingkungan pembelajaran jarak jauh dan online, yang sangat penting, terutama selama pandemi COVID-19 dan untuk siswa yang tidak dapat menghadiri pusat ujian fisik.", LANG(), { en: 'Supporting Distance Learning: Online Proctoring solutions enable the secure administration of exams in remote and online learning environments, which is crucial, especially during the COVID-19 pandemic and for students who cannot attend physical exam centers.' })}

            </li><li>
                {Lang("Fleksibilitas: Solusi Online Proctoring dapat menawarkan fleksibilitas dengan memungkinkan siswa mengikuti ujian pada waktu yang sesuai dengan jadwal mereka, sambil tetap menjaga keamanan ujian.", LANG(), { en: 'Flexibility: Online Proctoring solutions can offer flexibility by allowing students to take exams at a time that suits their schedule while still maintaining exam security.' })}

            </li><li>
                {Lang("Laporan Rinci: Sistem Online Proctoring menghasilkan laporan rinci tentang perilaku siswa selama ujian, termasuk aktivitas mencurigakan. Data ini dapat bermanfaat bagi pendidik untuk menyelidiki ketidakwajaran.", LANG(), { en: 'Detailed Reports: Online Proctoring systems generate detailed reports on student behavior during exams, including suspicious activities. This data can be valuable for educators to investigate irregularities.' })}

            </li><li>
                {Lang("Ketenangan Hati: Mengetahui bahwa solusi Online Proctoring ada dapat memberikan ketenangan hati baik bagi pendidik maupun siswa, memastikan bahwa ujian dilaksanakan dengan adil dan aman.", LANG(), { en: 'Peace of Mind: Knowing that Online Proctoring solutions are in place can provide peace of mind for both educators and students, ensuring that exams are conducted fairly and securely.' })}

            </li><li>
                {Lang("Keamanan Data: Solusi Online Proctoring yang terpercaya dirancang dengan langkah-langkah keamanan data yang kuat untuk melindungi informasi siswa dan mencegah pelanggaran data", LANG(), { en: 'Data Security: Reliable Online Proctoring solutions are designed with robust data security measures to protect student information and prevent data breaches.' })}
                .
            </li><li>
                {Lang("Skalabilitas: Solusi Online Proctoring dapat diskalakan untuk mengakomodasi jumlah siswa yang besar, membuatnya memungkinkan untuk mengadakan ujian online yang aman untuk kelas atau lembaga yang besar.", LANG(), { en: 'Scalability: Online Proctoring solutions can be scaled to accommodate large numbers of students, making it feasible to conduct secure online exams for large classes or institutions.' })}

            </li>
        </ol>
        <p>
            {Lang("Meskipun solusi Online Proctoring menawarkan manfaat yang signifikan, penting untuk mempertimbangkan keseimbangan antara keamanan ujian dan privasi siswa. Beberapa siswa mungkin memiliki kekhawatiran tentang privasi saat menggunakan alat ini, oleh karena itu penting untuk berkomunikasi tujuan dan perlindungan yang ada saat menerapkan solusi Online Proctoring untuk mengatasi kekhawatiran ini dan memastikan lingkungan ujian yang adil dan aman. Selain itu, lembaga harus mempertimbangkan opsi untuk mengakomodasi siswa dengan disabilitas atau mereka yang memiliki akses terbatas ke teknologi yang diperlukan untuk Online Proctoring.", LANG(), { en: 'While Online Proctoring solutions offer significant benefits, it is essential to balance exam security with student privacy. Some students may have concerns about privacy when using these tools, so it is important to communicate the purpose and safeguards in place when implementing Online Proctoring solutions to address these concerns and ensure a fair and secure exam environment. Additionally, institutions should consider options to accommodate students with disabilities or those with limited access to the technology required for Online Proctoring.' })}

        </p>
    </article>
}