import classes from "./Body2.module.scss"
import amanin_desc4 from "../../assets/imgs/content/amanin_desc4.jpg";
import { generatePricePlan } from "./PricePlan";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

export const generateBody2 = (_this) => {
    return <div className={classes.container}>
        <div className={classes.container_content}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor Freedom</span>
                <span className={classes.title2}>
                    {/* Penyelenggaraan ujian dapat disesuaikan dengan keinginan Anda */}
                    {Lang(`Penyelenggaraan ujian dapat disesuaikan dengan keinginan Anda`, LANG(), {
                        en: `Customizable exam administration at your own pace`
                    })}
                </span>
                <span className={classes.content}>
                    {/* Apabila Anda mempunyai kebutuhan ujian yang kompleks, sering dan membutuhkan banyak penyesuaian, maka Proktor Freedom sangat sesuai untuk Anda. */}
                    {Lang(`Apabila Anda mempunyai kebutuhan ujian yang kompleks, sering dan membutuhkan banyak penyesuaian, maka Proktor Freedom sangat sesuai untuk Anda.`, LANG(), {
                        en: `If you have complex, frequent and highly customizable exam needs, then Proctor Freedom is for you.`
                    })}
                </span>
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc4}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"}
                />
            </div>
        </div>
    </div>
}