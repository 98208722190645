import React from "react";
import classes from "./__index__.module.scss";
import NavPage from "../../components/Nav";
import { LANG, handleScreenChange } from "../../libs/utils";
import Footer from "../../components/Footer";
import { Lang } from "../../libs/Language";

class DownloadPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            desktopView: true,
        }
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentWillUnmount() {
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    renderManual(title, url) {
        return <div className={classes.manual} key={title}>
            <div className={classes.title}>
                <a href={url}>{title}</a>
            </div>
            {/* <div className={classes.link}>
                <a href={url}>download</a>
            </div> */}
        </div>
    }

    render() {
        const manuals = [
            {
                title: `User Manual Proktor Event ver. 1.1.15 - Management`,
                url: `https://amanin.id/pdf/User_Manual_Management_ver_1.1.15.pdf`
            },
            {
                title: `User Manual Proktor Event ver. 1.1.15 - Peserta`,
                url: `https://amanin.id/pdf/User_Manual_Peserta_ver.1.1.15.pdf`
            },
            {
                title: `User Manual Proktor Event ver. 1.1.15 - Pengawas`,
                url: `https://amanin.id/pdf/User_Manual_Pengawas_ver.1.1.15.pdf`
            },
            {
                title: `User Manual Proktor Event ver. 1.1.15 - Participant, English Version`,
                url: `https://amanin.id/pdf/User_Manual_Participant_English_ver.1.1.15.pdf`
            },
            {
                title: `User Manual Proktor Event ver. 1.1.15 - Proctor, English Version`,
                url: `https://amanin.id/pdf/User_Manual_Proctor_English_ver.1.1.15.pdf`
            },
            {
                title: Lang(`User Manual Proktor First - Peserta`, LANG(), { en: 'User Manual Proktor First - Participant' }),
                url: `https://amanin.id/pdf/ProktorFirst-Peserta.pdf`
            },
            {
                title: Lang(`User Manual Proktor First - Pembuat Ujian & Pengawas`, LANG(), { en: `User Manual Proktor First - Exam Maker & Proctor` }),
                url: `https://amanin.id/pdf/ProktorFirst-Pengawas.pdf`
            },
            {
                title: Lang(`User Manual Proktor Event - Peserta`, LANG(), { en: `User Manual Proktor Event - Participant` }),
                url: `https://amanin.id/pdf/participants.pdf`
            },
            {
                title: Lang(`User Manual Proktor Event - Pengawas`, LANG(), { en: `User Manual Proktor Event - Proctor` }),
                url: `https://amanin.id/pdf/proctorers.pdf`
            },
            {
                title: `User Manual Proktor Event - Post Proctoring`,
                url: `https://amanin.id/pdf/post_proctoring.pdf`
            },
            {
                title: `Safe Exam Browser Amanin for OSX`,
                url: `https://amanin.id/files/SafeExamBrowserAmanin-3.2.4.dmg`
            },
            {
                title: `Safe Exam Browser Amanin for Windows`,
                url: `https://amanin.id/files/SafeExamBrowserAmanin_3.6.0.633.exe`
            },
            {
                title: Lang(`Konfigurasi SEB Amanin - Proktor First, password: janganlupaberdoa`, LANG(), { en: `SEB Amanin Configuration - Proktor First, password: janganlupaberdoa` }),
                url: `https://amanin.id/files/ProktorFirstSEB.seb`
            }
        ]
        return <div className={classes.container}>
            <NavPage desktopView={this.state.desktopView} />
            <div className={classes.container_content}>
                <span>Download</span>
                <div>
                    {manuals.map((v) => {
                        return this.renderManual(v.title, v.url)
                    })}
                </div>
            </div>
            <Footer />
        </div>
    }
}

export default DownloadPage;