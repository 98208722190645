import blog from "../../../assets/imgs/content/amanin_desc2.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle1 = {
    title: Lang(`Mengapa Pendidikan ?`, LANG(), { en: 'Why Education?' }),
    img: blog,
    shorttext: Lang(`Banyak orang tua yang mau melakukan apapun untuk pendidikan anak-anaknya. Ini berlaku bahkan untuk orang tua yang ekonominya sangat sulit sekalipun. Bagi orang tua yang gajinya pas-pasan, mereka mau menghabiskan sebagian besar gajinya untuk pendidikan anak-anaknya. Sementara untuk dirinya sendiri, mereka rela hidup prihatin. Bagi orang tua yang petani misalnya, banyak yang rela menjual sebagian sawahnya demi anaknya bisa melanjutkan pendidikannya. Begitu pula dengan orang tua dengan profesi yang lain. Mereka rela melakukan apapun demi pendidikan anak-anaknya. `, LANG(),
        { en: "Many parents are willing to do anything for their children's education. This is true even for those who are financially struggling. Parents with limited income are willing to spend most of their earnings on their children's education. For themselves, they are prepared to live frugally. For instance, farming parents are often willing to sell part of their land so their children can continue their education. The same applies to parents in other professions. They are ready to do whatever it takes for their children's education." }),
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang("Banyak orang tua yang mau melakukan apapun untuk pendidikan anak-anaknya. Ini berlaku bahkan untuk orang tua yang ekonominya sangat sulit sekalipun. Bagi orang tua yang gajinya pas-papsan, mereka mau menghabiskan sebagian besar gajinya untuk pendidikan anak-anaknya. Sementara untuk dirinya sendiri, mereka rela hidup prihatin. Bagi orang tua yang petani misalnya, banyak yang rela menjual sebagian sawahnya demi anaknya bisa melanjutkan pendidikannya. Begitu pula dengan orang tua dengan profesi yang lain. Mereka rela melakukan apapun demi pendidikan anak-anaknya.", LANG(), { en: "Many parents are willing to do anything for their children's education. This is true even for those who are financially struggling. Parents with limited income are willing to spend most of their earnings on their children's education. For themselves, they are prepared to live frugally. For instance, farming parents are often willing to sell part of their land so their children can continue their education. The same applies to parents in other professions. They are ready to do whatever it takes for their children's education." })}
        </p>
        <ol>
            <li>
                {Lang("Mencegah Kecurangan: Dengan efektif mencegah dan mendeteksi kecurangan selama ujian online, solusi Sistem Proctoring menunjukkan komitmen universitas untuk menjaga standar akademik yang tinggi. Ini membantu memastikan bahwa kualifikasi yang diperoleh melalui program online adalah kredibel dan mencerminkan pengetahuan dan keterampilan sejati siswa.", LANG(), { en: "Preventing Cheating: Effectively preventing and detecting cheating during online exams, Proctoring System solutions demonstrate the university's commitment to maintaining high academic standards. This helps ensure that qualifications obtained through online programs are credible and reflect the true knowledge and skills of the students." })}

            </li>
            <li>
                {Lang(" Melindungi Integritas Akademik: Penggunaan solusi Sistem Proctoring mengirimkan pesan yang jelas bahwa universitas menghargai dan melindungi integritas akademik. Komitmen ini dapat meningkatkan kredibilitas universitas dan program online-nya, memastikan mahasiswa dan pemangku kepentingan bahwa institusi tersebut mengambil sikap serius terhadap praktik yang tidak jujur.", LANG(), { en: 'Protecting Academic Integrity: The use of Proctoring System solutions sends a clear message that the university values and protects academic integrity. This commitment can enhance the credibility of the university and its online programs, reassuring students and stakeholders that the institution takes a serious stance against dishonest practices.' })}

            </li><li>
                {Lang("Memverifikasi Identitas Mahasiswa: Solusi Sistem Proctoring sering kali mencakup langkah-langkah verifikasi identitas, seperti pengenalan wajah dan pemeriksaan ID. Hal ini memastikan bahwa orang yang mengikuti ujian adalah benar-benar mahasiswa terdaftar, mengurangi risiko pemalsuan identitas.", LANG(), { en: 'Verifying Student Identity: Proctoring System solutions often include identity verification measures, such as facial recognition and ID checks. This ensures that the person taking the exam is indeed the registered student, reducing the risk of identity fraud.' })}

            </li><li>
                {Lang("Deterren dan Keadilan: Keberadaan solusi Sistem Proctoring berfungsi sebagai penghalang bagi calon penipu, mempromosikan budaya keadilan dan kejujuran di antara mahasiswa. Mengetahui bahwa ujian diawasi dapat menghasilkan tingkat kepercayaan yang lebih tinggi terhadap kualifikasi yang diperoleh dari program online.", LANG(), { en: 'Deterrence and Fairness: The presence of Proctoring System solutions acts as a deterrent to potential cheaters, promoting a culture of fairness and honesty among students. Knowing that exams are monitored can lead to a higher level of trust in the qualifications earned from online programs.' })}

            </li><li>
                {Lang("  Menjamin Kualitas dan Konsistensi: Solusi Sistem Proctoring membantu menjaga kualitas dan konsistensi penilaian baik dalam program online maupun tatap muka, yang memperkuat kredibilitas program gelar universitas.", LANG(), { en: 'Ensuring Quality and Consistency: Proctoring System solutions help maintain the quality and consistency of assessments in both online and in-person programs, reinforcing the credibility of the university’s degree programs.' })}

            </li><li>
                {Lang(" Memperoleh Akreditasi: Banyak badan akreditasi dan organisasi penjaminan mutu menekankan pentingnya integritas akademik dan keamanan penilaian. Mengimplementasikan solusi Sistem Proctoring dapat membantu universitas memenuhi standar akreditasi ini, yang lebih meningkatkan kredibilitas mereka.", LANG(), { en: 'Achieving Accreditation: Many accreditation bodies and quality assurance organizations emphasize the importance of academic integrity and assessment security. Implementing Proctoring System solutions can help universities meet these accreditation standards, further enhancing their credibility.' })}

            </li><li>
                {Lang("Reputasi Alumni: Program pendidikan online yang kredibel menghasilkan lulusan yang dapat memberikan referensi tentang kualitas pendidikan mereka. Reputasi universitas yang kuat di kalangan alumni dapat memperkuat kredibilitasnya dan mendorong lebih banyak mahasiswa untuk mendaftar.", LANG(), { en: 'Alumni Reputation: Credible online education programs produce graduates who can attest to the quality of their education. A strong university reputation among alumni can bolster its credibility and attract more students.' })}

            </li><li>
                {Lang("Persepsi Publik: Penggunaan solusi Sistem Proctoring dapat berpengaruh positif terhadap persepsi publik tentang universitas. Calon mahasiswa dan pengusaha sering mencari indikator kualitas dan kredibilitas ketika mengevaluasi gelar atau diploma.", LANG(), { en: 'Public Perception: The use of Proctoring System solutions can positively impact public perception of the university. Prospective students and employers often seek indicators of quality and credibility when evaluating degrees or diplomas.' })}

            </li><li>
                {Lang(" Keunggulan Bersaing: Menawarkan program pendidikan online yang aman dan kredibel dapat memberikan keunggulan bersaing kepada universitas dalam pasar pendidikan online, menarik basis mahasiswa yang lebih luas dan beragam.", LANG(), { en: 'Competitive Advantage: Offering secure and credible online education programs can provide universities with a competitive edge in the online education market, attracting a broader and more diverse student base.' })}

            </li><li>
                {Lang("Perbaikan Berbasis Data: Solusi Sistem Proctoring menghasilkan data tentang kinerja ujian, termasuk area kekhawatiran atau ketidakberesan. Data ini dapat digunakan untuk perbaikan berkelanjutan dalam desain dan pengiriman kursus, yang lebih meningkatkan kualitas dan kredibilitas program online.", LANG(), { en: 'Data-Driven Improvement: Proctoring System solutions generate data on exam performance, including areas of concern or irregularities. This data can be used for continuous improvement in course design and delivery, further enhancing the quality and credibility of online programs.' })}

            </li><li>
                {Lang("Jangkauan Global: Solusi Sistem Proctoring memungkinkan universitas untuk memperluas jangkauan mereka ke audiens global. Dengan memastikan keamanan ujian online, universitas dapat menarik mahasiswa internasional dan membangun reputasi di seluruh dunia.", LANG(), { en: 'Global Reach: Proctoring System solutions enable universities to extend their reach to a global audience. By ensuring the security of online exams, universities can attract international students and build a worldwide reputation.' })}

            </li><li>
                {Lang("Kekuatan dalam Krisis: Dalam situasi seperti pandemi COVID-19, di mana ujian tatap muka tidak mungkin, keberadaan solusi Sistem Proctoring memungkinkan universitas untuk terus melakukan penilaian yang aman dan mempertahankan kredibilitas mereka selama saat-saat yang penuh tantangan.", LANG(), { en: 'Resilience in Crisis: In situations like the COVID-19 pandemic, where in-person exams are not feasible, the availability of Proctoring System solutions allows universities to continue conducting secure assessments and maintain their credibility during challenging times.' })}

            </li>
        </ol>
        <p>
            {Lang("Solusi Sistem Proctoring memegang peran penting dalam menjaga kredibilitas program pendidikan online universitas. Mereka menunjukkan komitmen terhadap integritas akademik, menyediakan penilaian yang aman, dan memberikan keunggulan bersaing dalam lanskap pendidikan online. Saat semakin banyak mahasiswa dan pengusaha mencari opsi pendidikan online yang kredibel, penggunaan solusi Sistem Proctoring dapat meningkatkan reputasi dan kepercayaan terhadap penawaran online universitas.", LANG(), { en: "Proctoring System solutions play a crucial role in upholding the credibility of university online education programs. They demonstrate a commitment to academic integrity, provide secure assessments, and offer a competitive advantage in the online education landscape. As more students and employers seek credible online education options, the use of Proctoring System solutions can enhance the reputation and trust in the university's online offerings." })}

        </p>
    </article>
}