import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";
import classes from "./Videos.module.scss";

export const __Videos__ = function (_this) {
    this.list = [
        {
            title: Lang("Pengenalan Sistem Proctoring", LANG(), {
                en: "Introduction to Proctoring System"
            }),
            url: "https://www.youtube.com/embed/SCH-khzPSYk?si=BPcth6mEhCeVZ8WY",
        },
        {
            title: Lang("Safe Exam Browser dengan Proktor Amanin", LANG(), {
                en: "Safe Exam Browser with Proktor Amanin"
            }),
            url: "https://www.youtube.com/embed/ajnfFGfMg58?si=E4Tp3v6K7YCKiqL7"
        },
        {
            title: Lang("Login sebagai peserta & Validasi Wajah", LANG(), {
                en: "Participant Login & Facial Recognition Validation"
            }),
            url: "https://www.youtube.com/embed/bMysKDP2hkc?si=1ujkseqI5LaEX7Cs",
        },
        {
            title: Lang("Membuat Ujian Online Secara Gratis", LANG(), {
                en: "Creating Online Exams for Free"
            }),
            url: "https://www.youtube.com/embed/cL2VUcpjZhU?si=fcrKP8aYEVIX74iL",
        },
        {
            title: Lang("Beberapa Fitur Pengawasan", LANG(), {
                en: "Several Monitoring Features"
            }),
            url: "https://www.youtube.com/embed/quTJZ2bh6Ts?si=9TDiwaL7prU-jAm5",
        },
        {
            title: Lang("Menggunakan Fitur Chat", LANG(), {
                en: "Utilizing the Chat Feature"
            }),
            url: "https://www.youtube.com/embed/7yB_evgecPM?si=eBDjQR1mW4sRWe6u",
        },
        // {
        //     title: "Menggunakan Tombol \"Butuh Bantuan\"",
        //     url: "https://www.youtube.com/embed/SCH-khzPSYk?si=BPcth6mEhCeVZ8WY",
        // },
        // {
        //     title: "Title 1",
        //     url: "https://www.youtube.com/embed/SCH-khzPSYk?si=BPcth6mEhCeVZ8WY",
        // },
        // {
        //     title: "Title 1",
        //     url: "https://www.youtube.com/embed/SCH-khzPSYk?si=BPcth6mEhCeVZ8WY",
        // },
    ]
    this.render = () => {
        return <div ref={_this.videoRef} id="video" className={classes.container}>
            <div className={classes.container_c}>
                {this.list.map((v) => {
                    return <div
                        key={v.title}
                        className={classes.video}>
                        <iframe
                            // width="300"
                            // height="315"

                            src={v.url}
                            title={v.title}
                            frameBorder="0"
                            id="ytplayer"
                            type="text/html"
                            allowFullScreen
                            allowFullScreen="allowfullscreen"

                        >
                        </iframe>
                        <span>{v.title}</span>
                    </div>
                })}
            </div>
        </div>
    }
}