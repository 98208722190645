import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _0_Intro = (_this, classes) => {
    return <div
        className={classes.paragraph}
        style={{ padding: "10px" }}
    >
        <span className={classes.title}>{Lang("Syarat dan Ketentuan Proktor Amanin", LANG(), { en: 'Terms and Conditions of Proktor Amanin' })}</span>
        <span className={classes.date}>{Lang("Berlaku efektif sejak Januari 2024", LANG(), { en: 'Effective from January 2024' })}</span>
        <div className={classes.break}></div>
        <p>
            {Lang("Selamat datang di Syarat dan Ketentuan Proktor Amanin. Harap baca dengan seksama Syarat dan Ketentuan Proktor Amanin ini sebelum menggunakan Platform dan Layanan Proktor Amanin.", LANG(),
                { en: 'Welcome to the Terms and Conditions of Proktor Amanin. Please read these Terms and Conditions carefully before using the Proktor Amanin Platform and Services.' })}
        </p>
        <p>

        </p>
    </div>
}





