import casestudyitbkiid from './casestudyitbki(id).jpg';
import casestudyitbkien from './casestudyitbki(en).png';
import casestudyitbsmid from './casestudyitbsm(id).jpg';
import casestudyitbsmen from './casestudyitbsm(en).png';
import casestudyteluid from './casestudytelu(id).jpg';
import casestudyteluen from './casestudytelu(en).png';

import { LANG } from '../../libs/utils';

import slide_h1_id from "./content/amanin_small1(id).png";
import slide_h1_en from "./content/amanin_small1(en).png";
import slide_h2_id from "./content/amanin_small2(id).png";
import slide_h2_en from "./content/amanin_small2(en).png";
import slide_v2_id from "./content/amanin_small3(id).png";
import slide_v2_en from "./content/amanin_small3(en).png";
import slide_v1_id from "./content/amanin_small4(id).png";
import slide_h4_id from "./content/amanin_small5(id).png";
import slide_h4_en from "./content/amanin_small5(en).png";
import slide_v3_id from "./content/amanin_small6(id).png";
import slide_v3_en from "./content/amanin_small6(en).png";
import slide_h3_id from "./content/amanin_small7(id).png";
import slide_h3_en from "./content/amanin_small7(en).png";
import slide_v4_id from "./content/amanin_small8(id).png";
import slide_v4_en from "./content/amanin_small8(en).png";

let casestudyitbki;
let casestudyitbsm;
let casestudytelu;

let slide_h1;
let slide_h2;
let slide_h3;
let slide_h4;

let slide_v1;
let slide_v2;
let slide_v3;
let slide_v4;


switch (LANG()) {
    case 'en':
        casestudyitbki = casestudyitbkien;
        casestudyitbsm = casestudyitbsmen;
        casestudytelu = casestudyteluen;

        slide_h1 = slide_h1_en;
        slide_h2 = slide_h2_en;
        slide_h3 = slide_h3_en;
        slide_h4 = slide_h4_en;

        slide_v1 = slide_v1_id;
        slide_v2 = slide_v2_en;
        slide_v3 = slide_v3_en;
        slide_v4 = slide_v4_en;
        break;
    default:
        casestudyitbki = casestudyitbkiid;
        casestudyitbsm = casestudyitbsmid;
        casestudytelu = casestudyteluid;

        slide_h1 = slide_h1_id;
        slide_h2 = slide_h2_id;
        slide_h3 = slide_h3_id;
        slide_h4 = slide_h4_id;

        slide_v1 = slide_v1_id;
        slide_v2 = slide_v2_id;
        slide_v3 = slide_v3_id;
        slide_v4 = slide_v4_id;
}

export {
    casestudyitbki,
    casestudyitbsm,
    casestudytelu,

    slide_h1,
    slide_h2,
    slide_h3,
    slide_h4,

    slide_v1,
    slide_v2,
    slide_v3,
    slide_v4,

};