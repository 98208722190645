import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _0_Intro = (_this, classes) => {
    return <div
        className={classes.paragraph}
        style={{ padding: "10px" }}
    >
        <span className={classes.title}>
            {Lang("Kebijakan Privasi Proktor Amanin", LANG(), { en: 'Privacy Policy of Proktor Amanin' })}
            {/* Lang(`Kebijakan Privasi Proktor Amanin`, LANG(), {
            en: `Privacy Policy of Proctor Amanin`}) */}
        </span>
        <span className={classes.date}>{Lang("Berlaku efektif sejak Januari 2024", LANG(), { en: 'Effective from January 2024' })}</span>
        <div className={classes.break}></div>
    </div>
}