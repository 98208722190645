import { Lang } from "../../libs/Language";
import { DISABLE_START_FREE, LANG } from "../../libs/utils";
import classes from "./PricePlan.module.scss";

export const generatePricePlan = (_this) => {
    return <div className={classes.container}>
        <div>
            <div className={classes.package}
                onClick={() => {
                    window.location.href = "/priceplan";
                }}
            >{Lang("Lihat Paket", LANG(), { en: 'View Package' })}</div>

            {!DISABLE_START_FREE && <div className={classes.free}
                onClick={() => {
                    window.location.href = "https://proktor.amanin.id"
                }}
            >
                {/* Mulai Dengan Gratis */}
                {Lang(`Mulai Dengan Gratis`, LANG(), { en: `Start for Free` })}</div>
            }
        </div>
    </div>
}