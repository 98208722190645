import classes from "./Solution.module.scss";

// import industry1 from "../../assets/imgs/content/industry1.jpg";
import industry2 from "../../assets/imgs/content/industry2.jpg";

import education from "../../assets/imgs/education.png";
import finance from "../../assets/imgs/bank.png";
import certification from "../../assets/imgs/certicifation.png";
import corporate from "../../assets/imgs/corporate.png";
import government from "../../assets/imgs/government.png";
import course from "../../assets/imgs/course.png";

import _education from "../../assets/imgs/content/amanin_desc_education.jpg";
import _finance from "../../assets/imgs/content/amanin_desc_finance.jpg";
import _corporate from "../../assets/imgs/content/amanin_desc_corporate.jpg";
import _course from "../../assets/imgs/content/amanin_desc_course.jpg";
import _government from "../../assets/imgs/content/amanin_desc_government.jpg";
import _certification from "../../assets/imgs/content/amanin_desc_certification.jpg";
import { LANG } from "../../libs/utils";
import { Lang } from "../../libs/Language";

export const __Solution__ = function (_this) {
    this.render = () => {
        const contents = {
            [Lang('Pendidikans', LANG(), { en: 'Education' })]: {
                img: _education,
                title: Lang(`Karena Ujian Online sudah menjadi hal yang wajar di dunia pendidikan`, LANG(), {
                    en: `Because Online Exams have become commonplace in the education world`
                }),
                text: Lang(`Pembelajaran jarak jauh dan hibrida telah menciptakan peluang pendidikan yang lebih luas, sehingga Ujian Online menjadi kebutuhan.`, LANG(),
                    {
                        en: `Distance and hybrid learning have created broader educational opportunities, making Online Exams a necessity.`
                    })
            },
            [Lang('Layanan Keuangan', LANG(), { en: 'Finance' })]
                : {
                img: _finance,
                title: Lang(`Solusi yang handal pada sektor keuangan`, LANG(), {
                    en: `Reliable solutions in the financial sector`
                }),
                text: Lang(`Kegiatan assesment internal dan seleksi masuk dapat dilakukan secara online untuk mendapatkan efisiensi tanpa mengorbankan integritas.`, LANG(), {
                    en: `Internal assessment and entry selection activities can be done online to gain efficiency without compromising integrity.`
                })
            },
            [Lang('Korporasi', LANG(), { en: 'Corporate' })]
                : {
                img: _corporate,
                title: Lang('Karena korporasi selalu berorientasi pada efisiensi', LANG(), {
                    en: 'Because corporations are always oriented towards efficiency'
                })
                ,
                text: Lang('Seleksi masuk, assesment internal dan penilaian karyawan oleh tim HRD menjadi lebih efisien dengan dilakukan secara online.',
                    LANG(),
                    {
                        en: 'Entry selection, internal assessment and employee appraisal by the HRD team become more efficient by being done online.'
                    })
            },
            [Lang('Kursus', LANG(), { en: 'Course' })]
                : {
                img: _course,
                title: Lang('Solusi bagi lembaga kursus untuk menjaga kualitas siswanya',
                    LANG(), {
                    en: 'Solution for course institutions to maintain the quality of their students'
                })
                ,
                text:
                    Lang('Kualitas siswa dapat dimonitor dengan baik walaupun kegiatan belajar mengajar dilakukan secara online.',
                        LANG(),
                        {
                            en: 'The quality of students can be monitored properly even though teaching and learning activities are carried out online.'
                        })
            },
            [Lang('Pemerintahan', LANG(), { en: 'Government' })]
                : {
                img: _government,
                title:
                    Lang('Solusi bagi lembaga pemerintahan untuk melakukan kegiatan ujian di berbagai bidang',
                        LANG(),
                        {
                            en: 'A solution for governmental institutions to conduct exams in various fields'
                        }),
                text:
                    Lang('Seleksi ujian masuk, penilaian internal dan kegiatan ujian lainnya dapat dilakukan dengan jumlah yang besar dan bersamaan.',
                        LANG(),
                        {
                            en: 'Entrance exams, internal assessments, and other exam activities can be conducted in large numbers and simultaneously.'
                        })
            },
            [Lang('Sertifikasi', LANG(), { en: 'Certification' })]
                : {
                img: _certification,
                title:
                    Lang('Ujian Sertifikasi menjadi lebih luas dan efisien',
                        LANG(),
                        {
                            en: 'Certification exams become more widespread and efficient'
                        })
                ,
                text:
                    Lang('Solusi bagi penyedia ujian sertifikasi untuk mengadakan ujian sertifikasi secara luas dan efisien tanpa mengorbankan integritas.',
                        LANG(),
                        { en: 'A solution for certification exam providers to conduct certification exams widely and efficiently without compromising integrity.' }
                    )
            }
        }
        const readContent = (id) => {
            return contents[id] ? contents[id] : {
                img: industry2,
                title:
                    Lang('Layanan Default',
                        LANG(),
                        {
                            en: 'Default Service'
                        }),
                text:
                    Lang('Layanan Default Desain lingkungan pembelajaran jarak jauh dan hibrida, berdayakan guru dan siswa, dan ciptakan peluang pendidikan yang lebih adil.',
                        LANG(),
                        {
                            en: 'Default Service Design for distance and hybrid learning environments, empower teachers and students, and create more equitable educational opportunities.'
                        })
            }
        }

        const generateItem = (text, imgsrc = education, chosenIndustry = null, cb = null) => {
            const vd = _this.state.desktopView;
            let cl = [
                chosenIndustry ? classes.small : classes.normal,
                chosenIndustry === text ? classes.chosenitem : null,
                vd ? null : classes.itemmobile
            ].join(" ");
            return <div key={text} className={cl}
                onClick={() => {
                    cb(text)
                }}
            >
                <img alt='i' src={imgsrc} />
                {text}
            </div>
        }

        const chooseItem = (item) => {
            let body3 = _this.state.body3;
            if (body3.itemReadyForClick === false) {
                return;
            }
            if (body3.chosenIndustry === item) {
                body3.chosenIndustry = null;
                setTimeout(() => {
                    body3.itemReadyForClick = true;
                    body3.showMainDesc = true;
                    _this.setState({ body3: body3 });
                }, 1000);
            } else {
                body3.chosenIndustry = item
                body3.showMainDesc = false;
                setTimeout(() => {
                    body3.itemReadyForClick = true;
                    _this.setState({ body3: body3 });
                }, 1000)
            }
            body3.itemReadyForClick = false;
            _this.setState({ body3: body3 });
        }

        const generateIndustry = () => {
            const vd = _this.state.desktopView;

            let dynclass = _this.state.body3.chosenIndustry ?
                classes.chosen_show :
                classes.chosen_close;

            if (vd === false) {
                dynclass = _this.state.body3.chosenIndustry ?
                    classes.chosenmobile_show :
                    classes.chosenmobile_close
            }

            return <div className={[
                !vd ? classes.chosenmobile : classes.chosen,
                dynclass,
            ].join(" ")}>
                <img
                    className={classes.fadein}
                    src={readContent(_this.state.body3.chosenIndustry).img}
                    alt="industry"
                />
                {_this.state.body3.itemReadyForClick &&
                    <div className={[
                        classes.chosen_c,
                        classes.fadein
                    ].join(" ")}>
                        <div className={classes.chosen_c_title}>
                            {readContent(_this.state.body3.chosenIndustry).title}
                        </div>
                        <div className={[
                            classes.chosen_c_content,
                            vd ? null : classes.mb,
                            classes.fadein
                        ].join(" ")}
                        >
                            {readContent(_this.state.body3.chosenIndustry).text}
                        </div>
                    </div>
                }
            </div>
        }

        const defaultTitle = Lang(' Membantu kebutuhan berbagai Institusi dan Lembaga di berbagai bidang',
            LANG(),
            {
                en: 'Assisting the needs of various institutions and agencies in various fields.'
            });

        const defaultContent = Lang('Proktor amanin membantu Anda memberikan rasa aman dan memastikan integritas kegiatan Ujian Anda.',
            LANG(),
            {
                en: 'Proktor amanin assists you in providing security and ensuring the integrity of your exam activities.'
            });

        const imageSources = {
            [Lang('Pendidikan', LANG(), { en: 'Education' })]: education,
            [Lang('Layanan Keuangan', LANG(), { en: 'Finance' })]: finance,
            [Lang('Pemerintahan', LANG(), { en: 'Government' })]: government,
            [Lang('Korporasi', LANG(), { en: 'Corporate' })]: corporate,
            [Lang('Kursus', LANG(), { en: 'Course' })]: course,
            [Lang('Sertifikasi', LANG(), { en: 'Certification' })]: certification,
        };

        const generateContent = () => {

            return <div className={classes.container}>
                <div className={classes.container_left}>
                    <div
                        className={classes.container_left_c}>
                        {generateIndustry()}
                        {_this.state.body3.showMainDesc && <div className={[classes.body3_content, classes.fadein].join(" ")}>
                            <p className={[classes.body3_content_title].join(" ")}>{defaultTitle}</p>
                            <p className={[classes.body3_content_content].join(" ")}>
                                {defaultContent}
                            </p>
                        </div>
                        }
                    </div>
                </div>
                <div
                    className={classes.container_right}>
                    <div className={classes.container_right_c}>
                        <div className={classes.body3_content2}>
                            {[
                                Lang('Pendidikan', LANG(), { en: 'Education' }), //daily, ujian masuk
                                Lang('Layanan Keuangan', LANG(), { en: 'Finance' }), // assesmemnt
                                Lang('Pemerintahan', LANG(), { en: 'Government' }), //Penerimaan 
                                Lang('Korporasi', LANG(), { en: 'Corporate' }), //Pelindo, HR
                                Lang('Kursus', LANG(), { en: 'Course' }),
                                Lang('Sertifikasi', LANG(), { en: 'Certification' })
                            ].map((v) => {

                                let img_src = imageSources[v];

                                return generateItem(v, img_src, _this.state.body3.chosenIndustry,
                                    () => {
                                        chooseItem(v)
                                    })
                            })}
                        </div>
                    </div>
                </div>
            </div>
        }

        const generateContentMobile = () => {
            return <div className={classes.mcontainer}>
                <div
                    className={classes.mcontainer_top}>
                    <div className={classes.mcontainer_top_c}>
                        <div className={classes.mbody3_content2}>
                            {[
                                [Lang('Pendidikan', LANG(), { en: 'Education' })], //daily, ujian masuk
                                [Lang('Layanan Keuangan', LANG(), { en: 'Finance' })], // assesmemnt
                                [Lang('Pemerintahan', LANG(), { en: 'Government' })], //Penerimaan 
                                [Lang('Korporasi', LANG(), { en: 'Corporate' })], //Pelindo, HR
                                [Lang('Kursus', LANG(), { en: 'Course' })],
                                [Lang('Sertifikasi', LANG(), { en: 'Certification' })]
                            ].map((v) => {

                                let img_src = imageSources[v];

                                return generateItem(v, img_src, _this.state.body3.chosenIndustry,
                                    () => {
                                        chooseItem(v)
                                    })
                            })}
                        </div>
                    </div>
                </div>
                <div className={classes.mcontainer_bottom}>
                    <div
                        className={classes.mcontainer_bottom_c}>
                        {generateIndustry()}
                        {_this.state.body3.showMainDesc && <div
                            className={[classes.mbody3_content, classes.fadein].join(" ")}>
                            <p className={[classes.mbody3_content_title, classes.mbt].join(" ")}>
                                {defaultTitle}
                            </p>
                            <p className={[classes.mbody3_content_content, classes.mb].join(" ")}>
                                {defaultContent}
                            </p>
                        </div>
                        }
                    </div>
                </div>
            </div>
        }

        return <div id="solution" ref={_this.solutionRef}>
            {_this.state.desktopView ?
                generateContent()
                :
                generateContentMobile()}
        </div>
    }
}