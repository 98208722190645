import classes from "./Body3.module.scss"
import amanin_desc3 from "../../assets/imgs/content/amanin_desc3.jpg";
import { generatePricePlan } from "./PricePlan";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

export const generateBody3 = (_this) => {
    return <div className={classes.container}>
        <div className={classes.container_content}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor First</span>
                <span className={classes.title2}>
                    {/* Memudahkan Anda dalam Pengawasan Ujian */}
                    {Lang(`Memudahkan Anda dalam Pengawasan Ujian`, LANG(), {
                        en: `Make it Easy for You to Supervise Exams`
                    })}
                </span>
                <span className={classes.content}>
                    <ul>
                        {/* <li>AI membantu anda dalam mendeteksi kecurangan yang mungkin dilakukan</li>
                        <li>Fitur Screenshot, Notes dan Flags memudahkan Anda menandai peserta yang dianggap mencurigakan</li> */}
                        <li>
                            {Lang(`AI membantu anda dalam mendeteksi kecurangan yang mungkin dilakukan`, LANG(), {
                                en: `AI helps you to detect possible cheating`
                            })}
                        </li>
                        <li>
                            {Lang(`Fitur Screenshot, Notes dan Flags memudahkan Anda menandai peserta yang dianggap mencurigakan`, LANG(), {
                                en: `Screenshot, Notes and Flags features make it easy for you to mark participants who are considered suspicious`
                            })}
                        </li>

                    </ul>
                </span>
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc3}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"}
                />
            </div>
        </div>
    </div>
}