import { Container } from "react-bootstrap"
import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"
import classes from "./Achievement.module.scss"


export const __Achievement__ = function (_this) {
    this.render = () => {
        const generateItem = (value, title, desc) => {
            return <div>
                <div>
                    <span className={classes.v}>{value}</span>
                    <span>{desc}</span>
                </div>
                <span className={_this.state.desktopView ? null : classes.mobile}>{title}</span>
            </div>
        }
        return <div
            className={classes.container}
        >
            {generateItem(
                "25.000+",
                Lang("Peserta Ujian", LANG(), { en: 'Exam Participants' }),
                Lang(
                    `Lebih dari 25 ribu peserta telah berpartisipasi dalam ujian online menggunakan Proktor Amanin.`,
                    LANG(),
                    { en: 'More than 25,000 participants have taken part in the online exam using Proktor Amanin.' }))},
            {/* {generateItem("10", "Universitas")} */}
            {generateItem(
                "400+",
                Lang("Pengawas Ujian", LANG(), { en: 'Proctors' }),
                Lang(`Lebih dari 400 pengawas turut berpartisipasi menjaga ujian berintegritas.`,
                    LANG(), {
                    en: `More than 400 proctors have participated in ensuring the integrity of the exam.`
                }))}
            {/* {generateItem("20", "Lembaga Pendidikan")} */}
        </div>
    }
}