import React from "react"
import classes from "./__index__.module.scss";
import NavPage from "../../components/Nav";

import sales from "../../assets/imgs/content/penjualan.jpg";
import { Button } from "react-bootstrap";
import Footer from "../../components/Footer";
import { LANG, handleScreenChange } from "../../libs/utils";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import whatsapp from "../../assets/imgs/whatsapp.png";
import { Lang } from "../../libs/Language";

class SalesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desktopView: true,
        }

        const searchParams = new URLSearchParams(this.props.location.search);
        this.action = searchParams.get('action');

    }

    generateFormulir() {
        return <div className={classes.formulir}>
            <span className={classes.title}>Masukkan Informasi Anda</span>
            <div className={classes.email}>
                <span>
                    Email Anda
                </span>
                <input type="text" placeholder="* email anda" />
            </div>
            <div className={classes.info}>
                <span>
                    Informasi Tambahan Terkait dengan Kebutuhan Anda
                </span>
                <textarea cols={40} rows={15}>
                </textarea>
            </div>
            <div className={classes.button}>
                <Button className="btn">Submit</Button>
            </div>
        </div>
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }


    generateContent() {
        const vd = this.state.desktopView;
        return <div className={[classes.container, vd ? null : classes.mobile].join(" ")}>
            <NavPage desktopView={this.state.desktopView} />
            <div className={classes.container_content}>
                <div className={vd ? null : classes.mobile}>
                    <div className={classes.left}>
                        <span className={classes.title}>
                            {this.action === "requestfordemo" ? [Lang("Hubungi Kami Untuk Meminta Demo", LANG(), { en: 'Contact Us to Request a Demo' })] : [Lang("Hubungi Kami", LANG(), { en: 'Contact Us' })]}
                        </span>
                        {this.action === "requestfordemo" ?
                            <span className={classes.info}>
                                {[Lang("Rasakan pengalaman menggunakan Proktor amanin secara langsung", LANG(), { en: 'Experience using Proktor Amanin ' })]}
                                .<br></br>
                                {/* Isi formulir berikut untuk meminta demo solusi kami. */}
                                {[Lang("Silakan menghubungi via WhatsApp di", LANG(), { en: 'Please contact via WhatsApp at' })]}
                                <a href="https://wa.me/087885110303"><img alt="wa" src={whatsapp} />+62 878-8511-0303</a>
                                {/* {[Lang("atau", LANG(), { en: 'or' })]}
                                <a href="https://wa.me/089608276141"><img alt="wa" src={whatsapp} />+62 896-0827-6141</a>
                                {[Lang("untuk informasi lebih lanjut, dan meminta demo.", LANG(), { en: 'for more information, and request a demo.' })]} */}

                            </span> :
                            <span className={classes.info}>
                                {[Lang("Proktor amanin akan membuat ujian online yang Anda selenggarakan menjadi lebih mudah, aman dan nyaman.", LANG(), { en: 'Proktor Amanin will make the online exam that you organized easier, safer, and more comfortable.' })]}
                                <br></br>
                                {/* Isi formulir berikut untuk menghubungi kami. */}
                                {[Lang("Silakan menghubungi via WhatsApp di", LANG(), { en: 'Please contact via WhatsApp at' })]}
                                <a href="https://wa.me/087885110303"><img alt="wa" src={whatsapp} />+62 878-8511-0303</a>
                                {/* {[Lang("atau", LANG(), { en: 'or' })]}
                                <a href="https://wa.me/089608276141"><img alt="wa" src={whatsapp} />+62 896-0827-6141</a>
                                {[Lang("untuk informasi lebih lanjut, dan meminta demo.", LANG(), { en: 'for more information, and request a demo.' })]} */}

                            </span>
                        }
                        <img alt="sales" src={sales} />
                    </div>
                    <div className={classes.contact}>
                        {/* {this.generateFormulir()} */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    }

    render() {
        return this.generateContent();
    }
}

export default withRouter(SalesPage);