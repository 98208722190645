import blog from "../../../assets/imgs/content/amanin_desc2.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle2 = {
    title: Lang(`Apa itu Integritas?`, LANG(), { en: 'What is Integrity?' }),
    img: blog,
    shorttext: Lang(`Kita sering mendengar istilah integritas. Tidak hanya di dunia kerja, tetapi juga di dunia sosial yang kita hadapi sehari-hari. Di dunia kerja, integritas seseorang sangat menentukan perjalanan karirnya dalam jangka panjang. Orang yang berintegritas akan lebih mudah mencapai kesuksesan dalam karirnya dibanding orang yang tidak berintegritas. Dalam kehidupan sosial, orang yang berintegritas akan mendapatkan kepercayaan dari orang-orang di sekelilingnya, sehingga ia akan menjadi pemimpin informal yang disegani dan diteladani oleh masyarakat.`, LANG(), { en: '' })
    ,
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang(" Kita sering mendengar istilah integritas. Tidak hanya di dunia kerja, tetapi juga di dunia sosial yang kita hadapi sehari-hari. Di dunia kerja, integritas seseorang sangat menentukan perjalanan karirnya dalam jangka panjang. Orang yang berintegritas akan lebih mudah mencapai kesuksesan dalam karirnya dibanding orang yang tidak berintegritas. Dalam kehidupan sosial, orang yang berintegritas akan mendapatkan kepercayaan dari orang-orang di sekelilingnya, sehingga ia akan menjadi pemimpin informal yang disegani dan diteladani oleh masyarakat.", LANG(), { en: "Integrity is a term we often hear. It applies not only in the workplace but also in the social world we face daily. In the workplace, a person's integrity greatly determines their long-term career journey. Those with integrity find it easier to achieve success in their careers compared to those without. In social life, individuals with integrity earn trust from those around them, making them respected informal leaders who are admired and emulated by society." })}


        </p>
        <p>
            {Lang("Integritas adalah sifat atau karakteristik moral yang mencakup kejujuran, kepercayaan, dan konsistensi dalam tindakan dan nilai-nilai seseorang. Integritas adalah kualitas yang mengacu pada ketepatan moral dan etika seseorang dalam segala aspek kehidupan, baik dalam tindakan, perkataan, maupun nilai-nilai yang dipegang.", LANG(), { en: "Integrity is a moral characteristic encompassing honesty, trustworthiness, and consistency in one's actions and values. It refers to the moral accuracy and ethical correctness individuals exhibit in all aspects of life, including their actions, words, and upheld values." })}

        </p>
        <p>
            {Lang("Integritas mencakup beberapa aspek utama, termasuk:", LANG(), { en: 'Integrity includes several key aspects, including:' })}

        </p>
        <ol>
            <li>
                {Lang("Kejujuran: Ini mengacu pada kemampuan seseorang untuk selalu mengatakan yang sebenarnya, bahkan ketika menghadapi tekanan atau kesulitan. Orang yang memiliki integritas tinggi dikenal sebagai individu yang jujur dan dapat diandalkan.", LANG(), { en: "Honesty: This refers to one's ability to always tell the truth, even under pressure or difficulty. Individuals with high integrity are known for being honest and reliable." })}

            </li><li>
                {Lang("Tanggung Jawab: Integritas juga mencakup tanggung jawab pribadi, yaitu kemampuan untuk mengenali dan menerima konsekuensi dari tindakan dan keputusan seseorang, baik yang positif maupun negatif.", LANG(), { en: "Responsibility: Integrity also involves personal responsibility, recognizing and accepting the consequences of one's actions and decisions, both positive and negative." })}

            </li><li>
                {Lang("Konsistensi: Individu yang memiliki integritas tinggi cenderung konsisten dalam nilai-nilai, prinsip-prinsip, dan tindakan mereka sepanjang waktu, tanpa adanya perubahan yang besar dalam perilaku moral mereka.", LANG(), { en: "Consistency: Individuals with high integrity tend to be consistent in their values, principles, and actions over time, without significant changes in their moral behavior." })}

            </li><li>
                {Lang("Etika: Integritas juga berkaitan dengan kesesuaian tindakan seseorang dengan standar moral dan etika yang diterima secara luas dalam masyarakat.", LANG(), { en: "Ethics: Integrity is also about aligning one's actions with widely accepted moral and ethical standards within society." })}

            </li><li>
                {Lang("Kepercayaan: Orang dengan integritas yang tinggi seringkali diberikan kepercayaan oleh orang lain karena mereka dianggap dapat diandalkan dan tidak akan melanggar kepercayaan tersebut.", LANG(), { en: 'Trust: Those with high integrity often earn the trust of others because they are seen as dependable and unlikely to violate that trust.' })}

            </li>
        </ol>
        <p>
            {Lang("Integritas adalah atribut penting dalam berbagai konteks kehidupan, termasuk dalam lingkungan pekerjaan, pendidikan, hubungan sosial, dan kehidupan pribadi. Individu yang memiliki integritas tinggi cenderung dihormati dan dihargai oleh orang lain karena mereka menunjukkan kemampuan untuk bertindak dengan benar dan berpegang pada prinsip-prinsip moral yang kuat.", LANG(), { en: 'Integrity is a crucial attribute in various life contexts, including the workplace, education, social relationships, and personal life. Individuals with high integrity are typically respected and valued by others because they demonstrate the ability to act rightly and adhere to strong moral principles.' })}
        </p>
    </article>
}