import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _9_PembayarandanBiaya = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("9. Pembayaran dan Biaya", LANG(), { en: '9. Payments and Fees' })}
        </span>
        <p>
            {Lang("Proktor Amanin menyediakan Layanan gratis dan berbayar. Anda dapat mempelajari lebih lanjut tentang berbagai penawaran langganan Proktor Amanin https://amanin.id/priceplan.", LANG(),
                { en: 'Proktor Amanin provides both free and paid services. You can learn more about the various subscription offers from Proktor Amanin at https://amanin.id/priceplan.' })}
        </p>
        <ol type="a">
            <li>
                {Lang("Langganan dan Perpanjangan. Langganan Anda akan diperbarui secara otomatis setiap bulan. Anda dapat membatalkan langganan kapan saja. Jika Anda membatalkan langganan, Anda tidak akan menerima pengembalian dana atau kredit untuk jumlah yang sudah ditagih.", LANG(),
                    { en: 'Subscriptions and Renewals. Your subscription will automatically renew every month. You can cancel your subscription anytime. If you cancel your subscription, you will not receive a refund or credit for the billed amount.' })}
            </li>
            <li>
                {Lang('Biaya. Anda menyetujui bahwa Kami dapat menagih kartu kredit, kartu debit, atau mekanisme pembayaran lain yang dipilih oleh Anda dan disetujui oleh Kami ("Metode Pembayaran") untuk semua jumlah yang jatuh tempo dan terkait dengan penggunaan Anda atas Layanan, sebagaimana diatur dalam Formulir Pesanan yang berlaku atau digunakan atau dipesan oleh Anda melalui Layanan, termasuk biaya lainnya yang terkait dengan Layanan atau akun Anda ("Biaya"). Anda setuju untuk segera memperbarui Metode Pembayaran Anda untuk memungkinkan pembayaran tepat waktu. Perubahan yang dibuat pada Metode Pembayaran Anda tidak akan mempengaruhi Biaya yang diajukan oleh Kami ke Metode Pembayaran yang Anda pilih sebelum Kami dapat sewajarnya bertindak atas perubahan Anda. Selain itu, Anda setuju untuk memperbolehkan Kami menggunakan informasi Metode Pembayaran yang diperbarui oleh bank penerbit atau jaringan pembayaran yang berlaku dan menagih Metode Pembayaran utama Anda saat ini dengan menggunakan rincian Metode Pembayaran tersebut yang disimpan dalam sistem kami, bahkan jika Metode Pembayaran tersebut ditolak. Kami juga berhak menggunakan Metode Pembayaran cadangan Anda. Dengan menambahkan Metode Pembayaran cadangan, Anda memberikan izin kepada Kami untuk memproses Biaya yang berlaku pada Metode Pembayaran cadangan Anda jika Metode Pembayaran utama Anda ditolak.', LANG(),
                    { en: 'Charges. You agree that We may charge your credit card, debit card, or other payment methods selected by you and approved by Us ("Payment Method") for all amounts due and related to your use of the Services, as set forth in the applicable Order Form or used or ordered by you through the Services, including other fees related to the Services or your account ("Fees"). You agree to promptly update your Payment Method to enable timely payment. Changes made to your Payment Method will not affect Fees submitted by Us to the Payment Method you selected before We could reasonably act on your changes. In addition, you agree to allow Us to use updated Payment Method information provided by the issuing bank or applicable payment network and to charge your current primary Payment Method using the details of the Payment Method stored in our system, even if the Payment Method is declined. We also reserve the right to use your backup Payment Method. By adding a backup Payment Method, you authorize Us to process applicable Fees on your backup Payment Method if your primary Payment Method is declined.' })}
            </li>
            <li>
                {Lang("Pembatalan. Anda dapat berhenti menggunakan Layanan dan/atau membatalkan langganan kapan saja melalui pengaturan akun Anda. Jika Anda membatalkan langganan, Anda tidak berhak atas pengembalian biaya yang sudah dibayarkan dan biaya yang belum dibayarkan akan segera menjadi hutang dan harus segera dibayar.", LANG(),
                    { en: 'Cancellation. You may stop using the Services and/or cancel your subscription at any time through your account settings. If you cancel your subscription, you are not entitled to a refund of fees already paid, and any unpaid fees will become immediately due and payable.' })}
            </li>
            <li>
                {Lang("Perubahan Harga. Proktor Amanin berhak mengubah harganya kapan saja. Jika Anda berlangganan, perubahan harga tidak akan berlaku hingga perpanjangan berikutnya atau tiga puluh (30) hari setelah pemberitahuan, yang mana lebih lambat.", LANG(),
                    { en: 'Price Changes. Proktor Amanin reserves the right to change its prices at any time. If you are subscribed, price changes will not take effect until the next renewal or thirty (30) days after notice, whichever is later.' })}
            </li>
            <li>
                {Lang("Tarif Promosi dan Diskon. Harga yang ditentukan dalam Formulir Pesanan dapat mencakup tarif promosi, diskon, penjualan, atau penawaran khusus, yang mungkin bersifat sementara dan dapat kedaluwarsa sesuai dengan syarat penawaran. Kedaluwarsa diskon atau penentuan harga promosi dapat terjadi tanpa pemberitahuan tambahan kepada Anda, kecuali ditentukan dalam Formulir Pesanan atau sebagaimana yang diwajibkan oleh Hukum yang berlaku. Kami berhak untuk menghentikan atau memodifikasi setiap promosi, diskon, penjualan, atau penawaran khusus, sesuai dengan kebijakannya.", LANG(),
                    { en: 'Promotional Rates and Discounts. The prices specified in the Order Form may include promotional rates, discounts, sales, or special offers, which may be temporary and may expire according to the terms of the offer. Expiration of discounts or promotional pricing may occur without additional notice to you, unless specified in the Order Form or as required by applicable Law. We reserve the right to discontinue or modify any promotion, discount, sale, or special offer, according to its policies.' })}
            </li>
        </ol>
    </div>
}