export const footerText = () => {
    return <>
        <span>
            # Online Proctoring Solutions
        </span>
        <span>
            # Proctoring System Solutions
        </span>
        <span>
            # Online Proctoring Indonesia
        </span>
        <span>
            # The Best Proctoring Solutions
        </span>
        <span>
            # Online Proctoring System Solutions in Indonesia
        </span>
        <span>
            # Ujian Online
        </span>
        <span>
            # Exam Online
        </span>
        <span>
            # Ujian Daring
        </span>
        <span>
            # Sistem Pengawasan Ujian Online
        </span>
    </>
}