import classes from "./Body2.module.scss"
import amanin_desc4 from "../../assets/imgs/content/amanin_desc2.jpg";
import { generatePricePlan } from "./PricePlan";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

export const generateBody2 = (_this) => {
    const vd = _this.state.desktopView;
    return <div className={classes.container}>
        <div className={classes.container_content}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor First</span>
                <span className={classes.title2}>
                    {/* Memudahkan Anda Melakukan Komunikasi */}
                    {Lang(`Memudahkan Anda Melakukan Komunikasi`, LANG(), {
                        en: `Make it Easy for You to Communicate`
                    })}
                </span>
                <span className={classes.content}>
                    <ul>
                        {/* <li>Peserta dapat meminta bantuan saat mengalami kesulitan dengan satu tombol saja</li>
                        <li>Kirimkan pengumuman kapan saja dengan fitur Broadcast Alert</li>
                        <li>Anda dan Peserta dapat berkomunikasi dengan mudah dengan adanya fitur Private Chat</li> */}
                        <li>
                            {Lang(`Peserta dapat meminta bantuan saat mengalami kesulitan dengan satu tombol saja`, LANG(), {
                                en: `Participants can request help when experiencing difficulties with just one button`
                            })}
                        </li>
                        <li>
                            {Lang(`Kirimkan pengumuman kapan saja dengan fitur Broadcast Alert`, LANG(), {
                                en: `Send announcements at any time with the Broadcast Alert feature`
                            })}
                        </li>
                        <li>
                            {Lang(`Anda dan Peserta dapat berkomunikasi dengan mudah dengan adanya fitur Private Chat`, LANG(), {
                                en: `You and the participants can communicate easily with the Private Chat feature`
                            })}
                        </li>

                    </ul>
                </span>
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc4}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"}
                />
            </div>
        </div>
    </div>
}