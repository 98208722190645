import React from "react";
import classes from "./AboutUs.module.scss";
import { COMPANY_NAME, LANG, handleScreenChange } from "../../libs/utils";
import Footer from "../../components/Footer";
import NavPage from "../../components/Nav";
import amaninwhite from "../../assets/imgs/proktoramaninwhite.png";
import { Lang } from "../../libs/Language";

class AboutUsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            desktopView: true
        }
    }

    componentWillUnmount() {
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    generateContent1() {
        return <div>
        </div>
    }

    generateBackground() {
        return <div className={classes.background}>
            <div className={classes.background_top}>

            </div>
            <div className={classes.background_bottom}>

            </div>
        </div>
    }

    generateContent() {
        const vd = this.state.desktopView;
        return <div className={[classes.container, vd ? null : classes.mcontainer].join(" ")}>
            <NavPage desktopView={this.state.desktopView} />
            <div className={classes.container_c}>
                <div className={[classes.title, classes.fadeinscale].join(" ")}>
                    {/* Kami adalah perusahaan teknologi yang berfokus di bidang AI dan Pengembangan Software */}
                    {Lang(`Kami adalah perusahaan teknologi yang berfokus di bidang AI dan Pengembangan Software`,
                        LANG(),
                        {
                            en: 'We are a technology company focusing on AI and Software Development'
                        })}
                </div>
                <div className={[classes.location, classes.fadeinscale].join(" ")}>
                    <div>
                        <img src={amaninwhite} />
                        <span className={classes.company}>
                            {COMPANY_NAME}
                        </span>
                        <span>
                            ICE Business Park A8.
                        </span>
                        <span>
                            Jl. BSD Grand Boulevard, Pagedangan,
                        </span>
                        <span>
                            Kec. Pagedangan,
                        </span>
                        <span>
                            Kabupaten Tangerang,
                        </span>
                        <span>
                            Banten 15339
                        </span>

                        <a href="/contactus">{[Lang("Hubungi Kami", LANG(), { en: 'Contact Us' })]}</a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    }
    render() {
        return this.generateContent();
    }
}

export default AboutUsPage;