import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _7_TautanKeSitusLain = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("7. TAUTAN KE SITUS LAIN DAN/ATAU BAHAN LAIN", LANG(), { en: '7. Links to Other Sites and/or Other Material' })}
        </span>
        <p>
            {Lang('Sebagai bagian dari Platform dan Layanan, Kami dapat menyediakan Anda tautan dan akses ke situs web pihak ketiga ("situs pihak ketiga") serta konten atau bahan atau materi yang dimiliki atau berasal dari pihak ketiga ("tautan, akses, aplikasi, perangkat lunak, atau konten pihak ketiga"). Tautan ini disediakan sebagai layanan kepada pelanggan Platform dan Layanan.', LANG(),
                { en: 'As part of the Platform and Service, we may provide you with links and access to third-party websites ("third-party sites") as well as content or materials owned or originated from third parties ("links, access, applications, third-party software, or content"). These links are provided as a service to our Platform and Service customers.' })}
        </p>
        <p>
            {Lang("Kami tidak memiliki kendali atas situs pihak ketiga atau aplikasi pihak ketiga, perangkat lunak, atau konten atau promosi, materi, informasi, barang, atau layanan yang tersedia di situs pihak ketiga atau aplikasi pihak ketiga, perangkat lunak, atau konten tersebut. situs pihak ketiga dan aplikasi pihak ketiga, perangkat lunak, atau konten tersebut tidak diselidiki, di monitor, atau diperiksa untuk akurasi, kesesuaian, atau kelengkapan, dan Kami tidak bertanggung jawab atas situs pihak ketiga yang diakses melalui Platform dan Layanan atau aplikasi pihak ketiga, perangkat lunak, atau konten yang diposting di Platform dan Layanan, tersedia melalui Platform dan Layanan, atau diinstal dari Platform dan Layanan, termasuk konten, akurasi, sifat menyinggung, pendapat, keandalan, praktik privasi, atau kebijakan lain dari atau yang terkandung dalam situs pihak ketiga atau aplikasi pihak ketiga, perangkat lunak, atau konten tersebut. penyertaan, penautan, atau izin penggunaan atau instalasi situs pihak ketiga atau aplikasi pihak ketiga, perangkat lunak, atau konten tersebut tidak menyiratkan persetujuan atau dukungan dari Kami. Jika Anda memutuskan untuk meninggalkan Platform dan Layanan dan mengakses situs pihak ketiga atau menggunakan atau menginstal aplikasi pihak ketiga, perangkat lunak, atau konten apa pun, Anda melakukannya atas risiko Anda sendiri dan Anda harus menyadari bahwa persyaratan dan kebijakan kami, termasuk Syarat dan Ketentuan ini, tidak lagi mengatur. Anda harus meninjau ketentuan dan kebijakan yang berlaku, termasuk praktik pengumpulan data dan privasi, dari situs pihak ketiga yang Anda kunjungi dari Platform dan Layanan atau yang terkait dengan aplikasi yang Anda gunakan atau instal dari situs pihak ketiga tersebut.", LANG(),
                { en: 'We do not control third-party sites or third-party applications, software, or content, or promotions, materials, information, goods, or services available on third-party sites or third-party applications, software, or such content. Third-party sites and third-party applications, software, or such content are not investigated, monitored, or reviewed for accuracy, appropriateness, or completeness, and we are not responsible for third-party sites accessed through the Platform and Service or third-party applications, software, or content posted on the Platform and Service, available through the Platform and Service, or installed from the Platform and Service, including content, accuracy, offensive nature, opinions, reliability, privacy practices, or other policies of or contained in third-party sites or third-party applications, software, or such content, inclusion, linking, or permission to use or install third-party sites or third-party applications, software, or such content does not imply approval or endorsement by us. If you choose to leave the Platform and Service and access third-party sites or use or install third-party applications, software, or any such content, you do so at your own risk, and you should be aware that our terms and policies, including these Terms and Conditions, no longer govern. You should review the applicable terms and policies, including data collection and privacy practices, of any third-party sites you visit from the Platform and Service or related to applications you use or install from such third-party sites.' })}
        </p>
    </div>
}