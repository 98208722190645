import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const ProktorEventParticipantFAQ = {
    title: Lang(`Proktor Event - Peserta`, LANG(), { en: 'Proktor Event - Participant' }),
    // Lang(``, LANG(), {
    //     en: ``
    // }),
    qna: {
        id: [
            {
                q: "Berapa jumlah maksimal peserta yang dapat mengikuti ujian online dalam satu waktu ?",
                a: `Jumlah peserta yang dapat mengikuti ujian online dalam satu waktu tidak terbatas`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa saja yang bisa dilakukan peserta yang mengikuti ujian online ?",
                a: `Peserta dapat mengakses soal ujian yang diberikan oleh penyelenggara ujian.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Berapa besar bandwidth internet yang diperlukan peserta untuk dapat mengikuti ujian online ?",
                a: `Besaran bandwidth dari peserta ditentukan oleh penyelenggara ujian, dengan opsi low, medium, dan high. Untuk low bandwidth yang dibutuhkan peserta 256 kbps, untuk medium sebesar 512 kbps, sedangkan untuk high 1 Mbps. Jika penyelenggara ujian membutuhkan kualitas video yang lebih cerah lagi, maka penyelenggara ujian dapat menentukan sendiri besaran bandwidth dari peserta.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apakah besar bandwidth internet di sisi peserta dapat dikonfigurasi atau tetap ?",
                a: `Besar bandwidth di sisi peserta tidak dapat dapat dikonfigurasi.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apakah setelah proses logout peserta dapat masuk lagi ke halaman ujian ?",
                a: `Apabila masih dalam rentang waktu peserta dapat masuk kembali, tetapi jika waktu telah habis maka peserta sudah tidak dapat login ke halaman ujian. Peserta yang melakukan login kembali tetap diwajibkan untuk melalui proses identifikasi.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa yang harus dilakukan oleh peserta dan atau pengawas jika terjadi masalah pada web kamera ?",
                a: `Peserta dapat menghubungi pengawas melalui broadcast message yang disediakan.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa yang harus dilakukan oleh peserta dan atau pengawas jika koneksi internet terputus secara tiba-tiba saat mengikuti ujian online ?",
                a: `Untuk peserta apabila koneksi internet terputus maka peserta tidak dapat mengikuti ujian sampai koneksi internet muncul kembali. Untuk itu peserta wajib memastikan koneksi internet selalu stabil.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Bagaimana peserta ujian online divalidasi ?",
                a: `Proses validasi ujian online dilakukan dengan mencocokkan wajah yang muncul di web kamera dengan foto di kartu identitas yang sudah disimpan oleh penyelenggara ujian.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa peserta dapat mengulang ujian jika koneksi internet terputus tiba-tiba ?",
                a: `Apabila masih dalam rentang waktu ujian koneksi internet kembali, maka peserta dapat mengikuti ujian kembali. Tetapi jika koneksi internet terputus sampai waktu habis, maka kebijakan mengulang ujian tergantung kepada keputusan dan aturan penyelenggara ujian.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa saja yang dibutuhkan oleh peserta yang akan mengikuti ujian online ?",
                a: `Peserta membutuhkan komputer yang mempunyai web kamera, internet yang stabil, serta username dan password yang diberikan oleh penyelenggara ujian saat peserta melakukan peserta melakukan pendaftaran ujian. Peserta perlu menyerahkan kartu identitas yang akan digunakan untuk proses identifikasi saat dilaksanakan ujian online.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa yang terjadi pada peserta jika dianggap melakukan kecurangan saat mengikuti ujian online ?",
                a: `Kecurangan dapat terjadi di tahap mana saja. Jika kecurangan terjadi pada tahap verifikasi identitas peserta, maka pengawas dapat mengeluarkan peserta dari sistem Live Proctoring. Lalu jika  kecurangan terjadi pada saat mengerjakan ujian, pengawas dapat langsung mengeluarkan peserta secara paksa. Peserta yang dikeluarkan oleh pengawas karena melakukan kecurangan tidak dapat login kembali ke sistem Live Proctor. Apabila kecurangan itu terdeteksi saat pengawas melakukan review video saat mengecek video di post proctor, maka kebijakan dilakukan sesuai keputusan penyelenggara ujian.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa yang harus dilakukan peserta yang dikeluarkan saat proses ujian online karena dianggap melakukan kecurangan jika ingin melakukan konfirmasi kepada pihak penyelenggara ujian ?",
                a: `Segala kebijakan terhadap peserta yang dikeluarkan saat proses ujian online karena dianggap melakukan kecurangan dilaksanakan sesuai aturan dan keputusan dari penyelenggara ujian. Oleh karena itu peserta dapat melakukan konfirmasi kepada penyelenggara ujian.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa spek komputer yang disarankan untuk peserta",
                a: `Peserta disarankan untuk menggunakan komputer dengan prosesor setara intel celeron dengan RAM setara 4GB`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            }
        ],
        en: [
            {
                q: "What is the maximum number of participants who can take an online exam at the same time?",
                a: "There is no limit to the number of participants who can take an online exam at the same time."
            },
            {
                q: "What can participants do during an online exam?",
                a: "Participants can access the exam questions provided by the exam organizer."
            },
            {
                q: "What bandwidth is required for participants to be able to take an online exam?",
                a: "The bandwidth required by participants is determined by the exam organizer, with options for low, medium, and high. For low bandwidth, participants need at least 256 kbps, for medium it is 512 kbps, and for high it is 1 Mbps. If the exam organizer requires higher video quality, they can specify the required bandwidth."
            },
            {
                q: "Can the internet bandwidth on the participant's side be configured or fixed?",
                a: "The internet bandwidth on the participant's side cannot be configured."
            },
            {
                q: "Can participants log back into the exam page after logging out?",
                a: "Participants can log back in within the allowed time frame. However, once the time is up, participants cannot log back in. Participants who log back in during the allowed time frame still need to go through the identification process."
            },
            {
                q: "What should participants or invigilators do if there is an issue with the webcam?",
                a: "Participants can contact the invigilator through the provided broadcast message."
            },
            {
                q: "What should participants or invigilators do if there is a sudden internet disconnection during an online exam?",
                a: "If a participant's internet connection is disconnected, they cannot continue the exam until their internet connection is restored. Therefore, participants must ensure a stable internet connection."
            },
            {
                q: "How are participants validated during an online exam?",
                a: "The validation process during an online exam involves matching the face appearing on the webcam with the photo on the participant's identification card stored by the exam organizer."
            },
            {
                q: "Can participants retake an exam if there is a sudden internet disconnection?",
                a: "If the internet connection is restored within the exam time frame, participants can retake the exam. However, if the internet connection is lost until the time is up, the policy for retaking the exam depends on the decision and rules set by the exam organizer."
            },
            {
                q: "What do participants need to participate in an online exam?",
                a: "Participants need a computer with a webcam, stable internet connection, and the username and password provided by the exam organizer during registration. Participants also need to submit an identification card for the identification process during the online exam."
            },
            {
                q: "What happens to participants if they are suspected of cheating during an online exam?",
                a: "Cheating can occur at any stage. If cheating occurs during the participant verification stage, the invigilator can remove the participant from the Live Proctoring system. If cheating occurs during the exam, the invigilator can immediately remove the participant forcibly. Participants removed by the invigilator for cheating cannot log back into the Live Proctor system. If cheating is detected during the invigilator's review of video during the post proctoring phase, the policy will follow the decision of the exam organizer."
            },
            {
                q: "What should participants who are removed during the online exam process due to suspected cheating do if they want to confirm with the exam organizer?",
                a: "All policies regarding participants removed during the online exam process due to suspected cheating are implemented according to the rules and decisions of the exam organizer. Therefore, participants can confirm with the exam organizer."
            },
            {
                q: "What are the recommended computer specifications for participants?",
                a: "Participants are advised to use a computer with a processor equivalent to Intel Celeron and RAM equivalent to 4GB."
            }
        ]
    }

}

export const ProktorEventProctorerFAQ = {
    title: Lang(`Proktor Event - Pengawas`, LANG(), { en: 'Proktor Event - Proctor' }),
    // Lang(``, LANG(), {
    //     en: ``
    // }),
    qna: {
        id: [
            {
                q: "Berapa jumlah maksimal pengawas yang dapat mengikuti ujian online dalam satu waktu ?",
                a: `Jumlah maksimal pengawas yang dapat mengikuti ujian online dalam satu waktu tidak terbatas.`
                // Lang(``, LANG(), {
                //     en: ``
                // })
            },
            {
                q: "Apa saja yang bisa dilakukan oleh pengawas pada saat mengikuti ujian online ?",
                a: `Pada tahap persiapan pengawas dapat melihat wajah dari para peserta yang diawasi yang sudah online. Pengawas dapat memvalidasi wajah peserta secara manual apabila ada peserta yang bermasalah di proses identifikasi. Pengawas dapat mengirim pesan secara broadcast terhadap seluruh peserta ujian serta dapat mengirim pesan secara pribadi terhadap peserta tertentu. Pengawas juga dapat mengeluarkan peserta yang melakukan kecurangan sesuai aturan aturan yang berlaku. Pada saat ujian berlangsung pengawas dapat mengawasi peserta melalui video secara live.`
                // Lang(``, LANG(), {
                //     en: ``
                // }),
            },
            {
                q: "Apa yang harus dilakukan oleh peserta dan atau pengawas jika terjadi masalah pada web kamera ?",
                a: `Apabila pengawas menghadapi masalah pada web kamera pengawas masih bisa melakukan pengawasan terhadap peserta.`
                //     Lang(``, LANG(), {
                //         en: ``
                //     }),
            },
            {
                q: "Apa yang harus dilakukan oleh peserta dan atau pengawas jika koneksi internet terputus secara tiba-tiba saat mengikuti ujian online ?",
                a: `Apabila internet terputus saat terjadi ujian online maka pengawas tidak dapat dapat melakukan pengawasan ujian online. Pengawas dapat login kembali ke sistem jika koneksi internet kembali.`
                // Lang(``, LANG(), {
                //     en: ``
                // }),
            },
            {
                q: "Berapa besar bandwidth yang diperlukan oleh setiap pengawas ?",
                a: `Kebutuhan internet di sisi pengawas lebih utama digunakan untuk mendownload video peserta ujian yang diawasi. Maka besaran bandwidthnya tergantung dari konfigurasi bandwidth peserta yang dipilih dan jumlah peserta yang sedang diawasi. Misalnya konfigurasi bandwidth peserta yang dipilih low dengan nilai 256 kbps dan jumlah peserta diawasi 20 peserta, maka kebutuhan bandwidth pengawasan minimal downlink adalah (256 kbps :2) x 20 = 2,56 Mbps per pengawas.`
                // Lang(``, LANG(), {
                //     en: ``
                // }),
            },
            {
                q: "Apa spesifikasi PC atau laptop untuk pengawas",
                a: <div>PC atau laptop yang dipakai oleh pengawas digunakan untuk memonitor banyak live video dari banyak user maka kami sarankan PC atau laptop yang digunakan pengawas memiliki minimum spesifikasi sebagai berikut :
                    < ol >
                        <li>Prosesor minimal i3 generasi ke-3</li>
                        <li>RAM 8 GB</li>
                    </ol >
                </div>
                // Lang(``, LANG(), {
                //     en: ``
                // }),
            }
        ],
        en: [
            {
                q: "What is the maximum number of proctorers who can participate in an online exam at the same time?",
                a: "There is no limit to the maximum number of proctorers who can participate in an online exam at the same time."
            },
            {
                q: "What can proctorers do during an online exam?",
                a: "During the preparation stage, proctorers can view the faces of monitored participants who are already online. Proctorers can manually validate the participant's face if there are issues during the identification process. Proctorers can broadcast messages to all exam participants and send private messages to specific participants. Proctorers can also dismiss participants who cheat according to the applicable rules. During the exam, proctorers can monitor participants via live video."
            },
            {
                q: "What should participants or proctorers do if there is an issue with the webcam?",
                a: "If a proctorer encounters an issue with the webcam, they can still monitor the participants."
            },
            {
                q: "What should participants or proctorers do if there is a sudden internet disconnection during an online exam?",
                a: "If the internet disconnects during an online exam, proctorers cannot continue monitoring. Proctorers can log back into the system once the internet connection is restored."
            },
            {
                q: "How much bandwidth is required for each proctorer?",
                a: "The internet requirement for proctorers is primarily used to download videos of monitored exam participants. Therefore, the bandwidth requirement depends on the selected participant bandwidth configuration and the number of participants being monitored. For example, with a selected participant bandwidth configuration of 'low' at 256 kbps and monitoring 20 participants, the minimum downlink bandwidth requirement per proctorer is (256 kbps : 2) x 20 = 2.56 Mbps."
            },
            {
                q: "What are the PC or laptop specifications recommended for Proctorer?",
                a: <div>PCs or laptops used by Proctorers to monitor multiple live videos from many users should ideally have the following minimum specifications :
                    < ol >
                        <li>Processor: at least 3rd generation Intel Core i3</li>
                        <li>RAM 8 GB</li>
                    </ol >
                </div>
            }
        ]

    }
}

export const ProktorEventAdminFAQ = {
    title: `Proktor Event - Admin`,
    // Lang(``, LANG(), {
    //     en: ``
    // }),
    qna: [
        {
            q: "Bagaimana Proktor Amanin mendukung proses penyelenggaraan ujian online, termasuk pengaturan soal, jadwal ujian, dan manajemen peserta ?",
            a: `Anda dapat dengan fleksibel mengatur jadwal ujian, mengelola peserta, dan memonitor progress ujian secara real-time. Perlu diingat bahwa saat ini kami belum menyediakan fitur pembuatan soal ujian. Untuk soal ujian Anda dapat mengintegrasikan dengan platform penyedia soal yang Anda pilih.`
            // Lang(``, LANG(), {
            //     en: ``
            // }),
            // Lang(``, LANG(), {
            //     en: ``
            // })
        },
        {
            q: "Apakah ada integrasi dengan LMS atau platform penyedia soal ?",
            a: `Ya, Anda dapat mengintegrasikan dengan LMS atau platform penyedia soal yang Anda pilih.`
            // Lang(``, LANG(), {
            //     en: ``
            // }),
            // Lang(``, LANG(), {
            //     en: ``
            // })
        },
        {
            q: "Apakah platform mendukung pengaturan ulang ujian untuk peserta yang mengalami masalah teknis atau keadaan darurat ?",
            a: `Ya, tetapi kami menyesuaikan dengan kebijakan penyelenggara ujian.`
            // Lang(``, LANG(), {
            //     en: ``
            // }),
            // Lang(``, LANG(), {
            //     en: ``
            // })
        },
        {
            q: "Apakah ada dukungan teknis yang tersedia selama penyelenggaraan ujian ?",
            a: `Ya, tim dukungan kami siap membantu Anda dalam setiap keadaan darurat atau kendala teknis selama event ujian berlangsung.`
            // Lang(``, LANG(), {
            //     en: ``
            // }),
            // Lang(``, LANG(), {
            //     en: ``
            // })
        },
        {
            q: "Apakah platform dapat disesuaikan dengan kebijakan atau aturan khusus ujian?",
            a: `Ya, platform kami dapat sepenuhnya disesuaikan. Anda dapat mengatur aturan khusus ujian, menyesuaikan durasi, dan mengonfigurasi berbagai parameter sesuai kebutuhan ujian Anda.`
            // Lang(``, LANG(), {
            //     en: ``
            // }),
            // Lang(``, LANG(), {
            //     en: ``
            // })
        },
        {
            q: "Apakah ada kemampuan untuk mengelola pengaturan keamanan dan privasi peserta selama pengawasan ujian?",
            a: `Ya, platform kami memberikan kontrol penuh kepada penyelenggara ujian untuk mengelola pengaturan keamanan dan privasi peserta. Anda dapat menyesuaikan tingkat pengawasan dan mematuhi regulasi privasi yang berlaku.`
        },
        {
            q: "Berapa orang admin yang diperlukan untuk mengelola layanan Live Proctoring ?",
            a: `Platform Proktor Event membutuhkan minimal satu orang admin.`
        },
        {
            q: "Berapa penyimpanan data yang diperlukan jika ingin menggunakan layanan Live Proctoring ",
            a: `Jumlah data yang disimpan sebanding dengan jumlah peserta ujian yang dikelola, kualitas video dan durasi ujian.`
        },
        {
            q: "Di manakah penyimpanan data Proktor Event akan disimpan ?",
            a: `Data Proktor Event akan disimpan di server Proktor Amanin`
        },
        {
            q: "Berapa lama durasi video tiap peserta yang dapat disimpan ?",
            a: `Lama durasi video tiap peserta yang dapat disimpan sesuai dengan durasi ujian.`
        },
        {
            q: "Apa Penyelenggara Ujian dapat melihat lagi rekaman video ujian online ?",
            a: `Ya, Penyelenggara ujian dapat melihat lagi rekaman video ujian online.`
        },
        {
            q: "Bagaimana keamanan data yang digunakan saat menggunakan layanan Live Proctoring ?",
            a: `Data yang digunakan hanya dapat diakses oleh pihak yang berkepentingan dan memiliki akses.`
        }

    ]
}
