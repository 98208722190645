import blog from "../../../assets/imgs/content/amanin_desc6.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle6 = {
    title: Lang(`Pengawasan Ujian Online dan Teknologi Kecerdasan Buatan`, LANG(), { en: 'Online Proctoring and Artificial Intelligence Technology' }),
    img: blog,
    shorttext: Lang(`Selama pandemi Covid-19, semua kegiatan pendidikan dilakukan secara daring (online). Tentu saja termasuk ujian. Karena ujian dilakukan secara online di rumah masing-masing, muncul kekhawatiran apakah peserta ujian benar-benar mengerjakan soal ujian secara jujur dan tidak dibantu orang lain? Kalau mereka tidak mengerjakan dengan jujur, bagaimana mengukur tingkat keberhasilan proses belajar dan mengajar tersebut?`, LANG(), { en: 'During the Covid-19 pandemic, all educational activities were conducted online, including exams. Since exams were taken online from home, concerns arose about whether students were honestly completing the exams without outside help. If they were not honest, how could we measure the success of the learning and teaching process?' }),
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang("Selama pandemi Covid-19, semua kegiatan pendidikan dilakukan secara daring (online). Tentu saja termasuk ujian. Karena ujian dilakukan secara online di rumah masing-masing, muncul kekhawatiran apakah peserta ujian benar-benar mengerjakan soal ujian secara jujur dan tidak dibantu orang lain? Kalau mereka tidak mengerjakan dengan jujur, bagaimana mengukur tingkat keberhasilan proses belajar dan mengajar tersebut?", LANG(), { en: 'During the Covid-19 pandemic, all educational activities were conducted online, including exams. Since exams were taken online from home, concerns arose about whether students were honestly completing the exams without outside help. If they were not honest, how could we measure the success of the learning and teaching process?' })}

        </p>
        <p>
            {Lang("Karena pada saat itu teknologi pengawasan ujian online belum banyak tersedia, tidak ada yang bisa menjamin apakah proses pendidikan berjalan dengan jujur seperti yang seharusnya proses pendidikan dijalankan.", LANG(), { en: 'At that time, online proctoring technology was not widely available, so there was no way to guarantee that the educational process was as honest as it should be.' })}

        </p>
        <p>
            {Lang(" Kini, teknologi tersebut sudah banyak tersedia di pasaran. Banyak pilihan solusi pengawasan ujian online yang tersedia dengan berbagai varian fungsi. Ada yang sederhana, dimana pengawasan dilakukan masih dengan cara tradisional dan membutuhkan keterlibatan manusia dengan segala keterbatasannya. Tetapi juga ada solusi yang canggih, yang dilengkapi dengan teknologi kecerdasan buatan (AI – Airtifical Inteligence), sehingga pengawasan ujian dapat dilakukan dengan sangat efektif dan efision.", LANG(), { en: 'Now, such technology is widely available on the market. There are many online proctoring solutions with various features. Some are simple, using traditional methods that still require human involvement and have limitations. However, there are also advanced solutions equipped with Artificial Intelligence (AI), allowing for very effective and efficient exam monitoring.' })}

        </p>
        <p>
            {Lang("Berikut ini beberapa manfaat dari solusi pengawasan ujian online yang telah dilengkapi dengan teknologi kecerdasan buatan:", LANG(), { en: 'Here are some benefits of online proctoring solutions enhanced with Artificial Intelligence:' })}

        </p>
        <ol>
            <li>
                {Lang("Verifikasi Identitas: Teknologi AI dapat memverifikasi identitas siswa secara online melalui pengenalan wajah atau suara, sehingga memastikan bahwa peserta ujian adalah orang yang seharusnya mengikuti ujian tersebut.", LANG(), { en: "Identity Verification: AI technology can verify students' identities online through facial or voice recognition, ensuring that the exam taker is the actual person who should be taking the exam." })}

            </li><li>
                {Lang("Deteksi Kecurangan: AI dapat digunakan untuk mendeteksi tindakan kecurangan seperti penggunaan bantuan tidak sah, atau kecurangan lainnya selama ujian berlangsung.", LANG(), { en: 'Cheating Detection: AI can detect cheating actions such as using unauthorized help or other dishonest behaviors during the exam.' })}

            </li><li>
                {Lang("Pengawasan Real-Time: AI dapat melakukan pengawasan real-time selama ujian, mendeteksi perilaku mencurigakan seperti berkomunikasi dengan orang lain, menggunakan alat bantu yang dilarang, atau tindakan lain yang dapat dikategorikan sebagai kecurangan.", LANG(), { en: 'Real-Time Monitoring: AI can perform real-time monitoring during the exam, detecting suspicious behaviors such as communicating with others, using forbidden tools, or other actions that could be considered cheating.' })}

            </li><li>
                {Lang("Analisis Data: AI dapat menganalisis data dari ujian online untuk mengidentifikasi pola kecurangan atau anomali yang mungkin tidak terdeteksi oleh manusia.", LANG(), { en: 'Data Analysis: AI can analyze data from online exams to identify cheating patterns or anomalies that might go unnoticed by humans.' })}

            </li><li>
                {Lang("Efisiensi: Penggunaan AI dalam pengawasan ujian online dapat mengurangi beban kerja pengawas manusia, sehingga memungkinkan pengawasan yang lebih efisien dan hemat biaya.", LANG(), { en: 'Efficiency: Using AI in online proctoring can reduce the workload on human proctors, enabling more efficient and cost-effective monitoring.' })}

            </li>
        </ol>
        <p>
            {Lang("Meskipun AI memberikan banyak manfaat, penting juga untuk memastikan bahwa implementasinya sesuai dengan kebijakan privasi dan etika yang ketat untuk melindungi data pribadi siswa dan menjaga integritas ujian.", LANG(), { en: "While AI offers many benefits, it is also important to ensure that its implementation complies with strict privacy and ethical policies to protect students' personal data and maintain exam integrity." })}

        </p>
    </article>
}