import blog from "../../../assets/imgs/content/amanin_desc8.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle8 = {
    title: Lang(`Equal Chance of Success: Memahami Keajaiban Pendidikan Online`, LANG(), { en: 'Equal Chance of Success: Understanding the Wonders of Online Education' }),
    img: blog,
    shorttext: Lang(`Di era modern ini, pendidikan online telah menjadi kekuatan revolusioner yang membawa perubahan besar dalam dunia pendidikan. Pendidikan online telah membuka pintu akses untuk semua orang, tanpa memandang status sosial atau lokasi geografis. Inilah kisah tentang bagaimana pendidikan online memberikan harapan dan kesempatan kepada masyarakat di daerah 3T - terluar, terdepan, dan tertinggal untuk meraih kesuksesan.`, LANG(), { en: 'In the modern era, online education has become a revolutionary force that is bringing significant changes to the world of learning. It has opened doors for everyone, regardless of social status or geographical location. This is the story of how online education brings hope and opportunities to people in remote, frontier, and underdeveloped regions (3T areas), enabling them to achieve success.' }),
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang("Di era modern ini, pendidikan online telah menjadi kekuatan revolusioner yang membawa perubahan besar dalam dunia pendidikan. Pendidikan online telah membuka pintu akses untuk semua orang, tanpa memandang status sosial atau lokasi geografis. Inilah kisah tentang bagaimana pendidikan online memberikan harapan dan kesempatan kepada masyarakat di daerah 3T - terluar, terdepan, dan tertinggal untuk meraih kesuksesan.", LANG(), { en: 'In the modern era, online education has become a revolutionary force that is bringing significant changes to the world of learning. It has opened doors for everyone, regardless of social status or geographical location. This is the story of how online education brings hope and opportunities to people in remote, frontier, and underdeveloped regions (3T areas), enabling them to achieve success.' })}

        </p>
        <p>
            {Lang("Pendidikan adalah kunci menuju pengetahuan, pengembangan pribadi, dan kemajuan karir. Namun, selama bertahun-tahun, banyak individu yang terbatas oleh faktor-faktor seperti lokasi geografis yang terpencil atau keterbatasan finansial. Pendidikan konvensional sering kali memerlukan akses fisik ke sekolah atau universitas tertentu, yang mungkin tidak tersedia bagi semua orang.", LANG(), { en: 'Education is the key to knowledge, personal development, and career advancement. However, for many years, individuals have been limited by factors such as remote geographical locations or financial constraints. Conventional education often requires physical access to specific schools or universities, which may not be available to everyone.' })}

        </p>
        <p>
            {Lang("Dengan kehadiran pendidikan online, segalanya berubah. Inilah alat demokratisasi pendidikan, yang menghapus hambatan fisik dan geografis. Seorang siswa atau mahasiswa di desa terpencil sekarang memiliki kesempatan yang sama dengan mereka yang tinggal di kota besar. Mereka dapat mengakses kursus, materi pembelajaran, dan sumber daya pendidikan tanpa perlu melakukan perjalanan jauh. Ini bukan hanya tentang kesetaraan, tetapi juga tentang inklusi sosial yang sejati.", LANG(), { en: 'With the advent of online education, everything has changed. It is a tool for democratizing education, breaking down physical and geographical barriers. A student in a remote village now has the same opportunities as those living in major cities. They can access courses, learning materials, and educational resources without needing to travel far. This is not just about equality but also about true social inclusion.' })}

        </p>
        <p>
            {Lang("Dalam dunia yang terus berubah ini, memiliki akses ke pendidikan online berarti memiliki akses ke pengetahuan dari seluruh dunia. Siswa dapat memilih mata pelajaran yang sesuai dengan minat dan tujuan mereka, tanpa harus terikat pada apa yang tersedia di lingkungan fisik mereka. Ini membuka pintu ke beragam peluang karier dan memungkinkan mereka untuk mengejar impian mereka dengan lebih baik.", LANG(), { en: 'In this ever-changing world, having access to online education means having access to knowledge from around the globe. Students can choose subjects that align with their interests and goals, without being confined to what is available in their physical environment. This opens doors to diverse career opportunities and allows them to pursue their dreams more effectively.' })}

        </p>
        <p>
            {Lang("Tidak hanya itu, pendidikan online juga mempromosikan fleksibilitas. Individu yang bekerja atau memiliki tanggung jawab keluarga tidak perlu mengorbankan pendidikan mereka. Mereka dapat belajar sesuai jadwal mereka sendiri, yang dapat disesuaikan dengan kehidupan sehari-hari mereka. Ini adalah langkah besar menuju peningkatan tingkat pendidikan di seluruh dunia.", LANG(), { en: 'Moreover, online education promotes flexibility. Individuals who are working or have family responsibilities do not have to sacrifice their education. They can learn according to their own schedules, which can be adapted to their daily lives. This is a major step towards improving educational levels worldwide.' })}

        </p>
        <p>
            {Lang("Dalam perjalanan menuju sukses, pendidikan online adalah teman setia. Ini memberikan kesempatan yang sama kepada semua orang, memungkinkan kita untuk meraih impian kita, terlepas dari latar belakang atau lokasi kita. Semua orang memiliki potensi untuk mencapai kesuksesan, dan pendidikan online telah membantu menjadikan potensi ini menjadi kenyataan bagi banyak individu di seluruh dunia. Equal Chance of Success bukanlah sekadar mimpi; ini adalah kisah nyata tentang bagaimana pendidikan online telah mengubah dunia pendidikan dan memberikan kesempatan yang setara kepada semua orang.", LANG(), { en: 'On the path to success, online education is a steadfast companion. It provides equal opportunities to everyone, enabling us to achieve our dreams, regardless of our background or location. Everyone has the potential for success, and online education has helped make this potential a reality for many individuals around the world. Equal Chance of Success is not just a dream; it is a true story of how online education has transformed the world of learning and provided equal opportunities to all.' })}

        </p>
    </article>
}