import blog from "../../../assets/imgs/content/amanin_desc5.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle5 = {
    title: Lang(`Sistem Pengawasan Ujian Online (Online Proctoring)`, LANG(), { en: 'Online Proctoring System' }),
    img: blog,
    shorttext: Lang(`Sistem Pengawasan Ujian Online atau Online Proctoring adalah sistem yang digunakan dalam pendidikan online untuk memantau dan mengawasi mahasiswa selama ujian atau penilaian online. Sistem ini menggunakan berbagai teknologi, khususnya teknologi kecerdasan buatan (AI), untuk meminimalkan kemungkinan peserta ujian berbuat curang selama mengikuti ujian online. Inilah alasan mengapa sistem pengawasan ujian online sangat penting dalam pendidikan online:`, LANG(), { en: 'Online Proctoring Systems are crucial in online education for monitoring and supervising students during online exams or assessments. These systems employ various technologies, particularly artificial intelligence (AI), to minimize the chances of cheating during online exams. Here are the reasons why online proctoring systems are vital in online education:' }),
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang("Sistem Pengawasan Ujian Online atau Online Proctoring adalah sistem yang digunakan dalam pendidikan online untuk memantau dan mengawasi mahasiswa selama ujian atau penilaian online. Sistem ini menggunakan berbagai teknologi, khususnya teknologi kecerdasan buatan (AI), untuk meminimalkan kemungkinan peserta ujian berbuat curang selama mengikuti ujian online. Inilah alasan mengapa sistem pengawasan ujian online sangat penting dalam pendidikan online:", LANG(), { en: 'Online Proctoring Systems are crucial in online education for monitoring and supervising students during online exams or assessments. These systems employ various technologies, particularly artificial intelligence (AI), to minimize the chances of cheating during online exams. Here are the reasons why online proctoring systems are vital in online education:' })}
        </p>
        <ol>
            <li>
                {Lang("Menjaga Integritas Ujian: Online proctoring membantu menjaga integritas ujian online. Ini memastikan bahwa mahasiswa mengikuti penilaian dengan adil dan jujur, mirip dengan pengawasan yang diberikan selama ujian tatap muka.", LANG(), { en: 'Maintaining Exam Integrity: Online proctoring helps maintain the integrity of online exams, ensuring that students undertake assessments fairly and honestly, similar to the oversight provided during in-person exams.' })}

            </li><li>
                {Lang("Mencegah Kecurangan: Kecurangan adalah masalah besar dalam pendidikan online karena sifat pembelajarannya yang jarak jauh. Sistem proctoring online dapat mendeteksi dan mencegah berbagai bentuk kecurangan, termasuk mencari jawaban, menggunakan sumber daya yang tidak sah, atau mendapatkan bantuan dari orang lain.", LANG(), { en: 'Preventing Cheating: Cheating is a significant issue in online education due to the remote nature of learning. Online proctoring systems can detect and prevent various forms of cheating, such as searching for answers, using unauthorized resources, or receiving help from others.' })}

            </li><li>
                {Lang("Penilaian yang Adil: Online proctoring mempromosikan keadilan dalam penilaian. Ini memastikan bahwa semua mahasiswa dinilai berdasarkan pengetahuan dan keterampilan sebenarnya, bukan kemampuan mereka untuk memanipulasi sistem atau terlibat dalam praktik yang tidak jujur.", LANG(), { en: 'Fair Assessment: Online proctoring promotes fairness in assessment, ensuring that all students are evaluated based on their actual knowledge and skills rather than their ability to manipulate the system or engage in dishonest practices.' })}

            </li><li>
                {Lang("Meningkatkan Kepercayaan: Kepercayaan sangat penting dalam pendidikan online. Penyelenggara pendidikan online harus yakin bahwa pengetahuan dan keterampilan yang diperoleh mahasiswa secara online adalah sah dan dapat dipercaya. Online proctoring berkontribusi pada kepercayaan dalam program pendidikan dan gelar online.", LANG(), { en: 'Enhancing Trust: Trust is crucial in online education. Online education providers must be confident that the knowledge and skills students acquire are legitimate and trustworthy. Online proctoring contributes to trust in online education programs and degrees.' })}

            </li><li>
                {Lang("Kepatuhan: Dalam beberapa kasus, lembaga pendidikan dan badan akreditasi mungkin memerlukan jaminan keamanan ujian dan integritas yang ketat untuk memenuhi standar akademik dan kepatuhan (compliance). Online proctoring membantu memenuhi persyaratan ini.", LANG(), { en: 'Compliance: In some cases, educational institutions and accreditation bodies may require strict exam security and integrity to meet academic standards and compliance requirements. Online proctoring helps meet these requirements.' })}

            </li><li>
                {Lang("Efek Pencegahan: Mengetahui bahwa ujian online dipantau oleh sistem dapat mencegah mahasiswa berbuat curang. Keberadaan alat proctoring mendorong mahasiswa untuk mengikuti ujian dengan jujur dan integritas.", LANG(), { en: 'Deterrent Effect: Knowing that online exams are monitored by a proctoring system can deter students from cheating. The presence of proctoring tools encourages students to take exams honestly and with integrity.' })}

            </li><i>
                {Lang("Menghemat Waktu Instruktur: Online proctoring dapat mengurangi beban kerja instruktur yang sebaliknya harus menghabiskan waktu memantau ujian atau meninjau perilaku yang mencurigakan. Ini memungkinkan pendidik untuk fokus pada aspek-aspek lain dalam pengajaran dan penilaian.", LANG(), { en: 'Saving Instructor Time: Online proctoring can reduce the workload of instructors who would otherwise spend time monitoring exams or reviewing suspicious behavior. This allows educators to focus on other aspects of teaching and assessment.' })}

            </i><i>
                {Lang("Melindungi Kehormatan Akademik: Online proctoring membantu melindungi kehormatan akademik dan reputasi, baik mahasiswa maupun lembaga pendidikan. Ini memastikan bahwa individu mendapatkan kualifikasi mereka melalui prestasi dan kerja keras.", LANG(), { en: 'Protecting Academic Honor: Online proctoring helps protect the academic honor and reputation of both students and educational institutions. It ensures that individuals earn their qualifications through merit and hard work.' })}

            </i><i>
                {Lang("Efisiensi: Sistem proctoring otomatis dapat memantau sejumlah besar mahasiswa secara efisien, sehingga dapat diaplikasikan di lembaga dengan beragam populasi mahasiswa.", LANG(), { en: 'Efficiency: Automated proctoring systems can efficiently monitor a large number of students, making them applicable in institutions with diverse student populations.' })}

            </i>
        </ol>
        <p>
            {Lang("Meskipun online proctoring berharga untuk menjaga integritas ujian, penting untuk mencapai keseimbangan antara mencegah kecurangan dan menghormati privasi mahasiswa. Lembaga pendidikan harus menyampaikan kebijakan proctoring secara jelas kepada mahasiswa dan memastikan bahwa metode proctoring diimplementasikan dengan cara yang menghormati privasi dan keamanan data.", LANG(), { en: 'While online proctoring is valuable for maintaining exam integrity, it is essential to strike a balance between preventing cheating and respecting student privacy. Educational institutions should clearly communicate proctoring policies to students and ensure that proctoring methods are implemented in a manner that respects privacy and data security.' })}

        </p>
    </article>
}