import React from "react";
import { LANG, handleScreenChange } from "../../libs/utils";
import classes from "./__index__.module.scss";
import NavPage from "../../components/Nav";
import Footer from "../../components/Footer";
import { Lang } from "../../libs/Language";
import { casestudyitbki, casestudyitbsm, casestudytelu } from "../../assets/imgs";

class CaseStudyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desktopView: true,
        }
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }


    generateContent() {
        const vd = this.state.desktopView;
        return <div className={[classes.container, vd ? null : classes.mobile].join(" ")}>

            <div>
                <NavPage desktopView={this.state.desktopView} />
            </div>
            <span>{Lang("Studi Kasus", LANG(), { en: "Case Studies" })}</span>
            <div className={vd ? null : classes.mobile}>
                <img
                    className={classes.fadein1s}
                    alt="casetelu"
                    src={casestudytelu}
                />
            </div>
            <div className={vd ? null : classes.mobile}>
                <img
                    className={classes.fadein1s}
                    alt="casetelu"
                    src={casestudyitbsm}
                />
            </div>
            <div className={vd ? null : classes.mobile}>
                <img
                    className={classes.fadein1s}
                    alt="casetelu"
                    src={casestudyitbki}
                />
            </div>

            <Footer />
        </div>
    }

    render() {
        return this.generateContent();
    }
}

export default CaseStudyPage;