import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _0_Intro2 = (_this, classes) => {
    return <div className={classes.paragraph}>
        <p>
            {Lang('Proktor Amanin berkomitmen untuk menjaga perlindungan privasi yang kuat untuk para penggunanya. Kebijakan Privasi ini dirancang untuk membantu Anda memahami bagaimana Kami mengumpulkan dan/atau memproses (yang mencakup pengumpulan, pengorganisasian, pengelompokan, penyimpanan, penggunaan, atau pengungkapan) data pribadi dan informasi yang Anda berikan untuk menyediakan produk dan layanan yang ditawarkan oleh PT Amanin Internasional (“Proktor Amanin”, Kami, Kita) , termasuk situs web Proktor Amanin, pengawasan ujian online, ujian online, dan fitur kolaboratif terkait ("produk dan layanan Proktor Amanin" atau "produk dan layanan"). Produk dan layanan Proktor Amanin yang dicakup dalam Kebijakan Privasi ini tidak termasuk produk atau layanan yang dikembangkan oleh PT Amanin Internasional atau Proktor Amanin yang tercakup dalam kebijakan privasi terpisah.', LANG(),
                { en: 'Proktor Amanin is committed to maintaining strong privacy protections for its users. This Privacy Policy is designed to help you understand how we collect and/or process (including collecting, organizing, grouping, storing, using, or disclosing) personal data and information you provide in order to deliver the products and services offered by PT Amanin Internasional (“Proktor Amanin,” “we,” “us”), including the Proktor Amanin website, online exam proctoring, online exams, and related collaborative features ("Proktor Amanin products and services" or "products and services"). The Proktor Amanin products and services covered by this Privacy Policy do not include products or services developed by PT Amanin Internasional or Proktor Amanin that are covered by separate privacy policies.' })}
        </p>
        <p>
            {Lang('Untuk kepentingan Perjanjian ini, “Platform” merujuk kepada situs web Kami (proktor.amanin.id), situs web lain yang Kami kelola, media fisik maupun digital, fitur, teknologi, konten, dan produk yang Kami sediakan. "Layanan" merujuk pada layanan Proktor Amanin yang diakses melalui situs Proktor Amanin, di mana pengguna dapat membuat ujian online, mengawasi ujian online, mengatur ujian online dan mengikuti ujian online. Kata "Kami" dan "Kita" merujuk pada Proktor Amanin. "Anda" merujuk pada Anda, sebagai pengguna Platform dan Layanan Proktor Amanin. Dengan mengakses Platform atau Layanan Kami, Anda menerima Kebijakan Privasi dan Syarat dan Ketentuan Kami, dan Anda memberikan persetujuan terhadap pengumpulan, penyimpanan, penggunaan, dan pengungkapan Informasi Pribadi Anda sebagaimana dijelaskan dalam Kebijakan Privasi ini.', LANG(),
                { en: 'For the purposes of this Agreement, "Platform" refers to our website (proktor.amanin.id), other websites we operate, as well as physical and digital media, features, technologies, content, and products we provide. "Services" refers to Proktor Amanin`s services accessed through the Proktor Amanin website, where users can create online exams, proctor online exams, organize online exams, and participate in online exams. The terms "we" and "us" refer to Proktor Amanin. "You" refers to you, as a user of the Proktor Amanin Platform and Services. By accessing our Platform or Services, you accept our Privacy Policy and Terms and Conditions, and you consent to the collection, storage, use, and disclosure of your Personal Information as described in this Privacy Policy.' })}
        </p>
    </div>
}