import classes from "./Body1.module.scss"
import amanin_desc1 from "../../assets/imgs/content/amanin_desc1.jpg";
import { generatePricePlan } from "./PricePlan";
import { LANG } from "../../libs/utils";
import { Lang } from "../../libs/Language";

export const generateBody1 = (_this) => {
    const vd = _this.state.desktopView
    return <div className={classes.container}>
        <div className={[classes.container_content, vd ? null : classes.mobile].join(" ")}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor First</span>
                <span className={classes.title2}>
                    {/* Memudahkan Anda dalam mengatur ujian. */}
                    {Lang(`Memudahkan Anda dalam mengatur ujian.`, LANG(), { en: `Make it easy for you to organize exams.` })}
                </span>
                <span className={classes.content}>
                    <ul>
                        {/* <li>Anda dapat menjadwalkan Ujian</li>
                        <li>Manfaatkan fasilitas Post Proktor untuk mengkonfigurasi dan melihat jalannya ujian yang Anda selenggarakan</li>
                        <li>Fitur Report akan membantu Anda mengevaluasi ujian dan peserta</li> */}
                        <li>{Lang(`Anda dapat menjadwalkan Ujian`, LANG(),
                            { en: `You can schedule Exams` })}</li>
                        <li>{Lang(`Manfaatkan fasilitas Post Proktor untuk mengkonfigurasi dan melihat jalannya ujian yang Anda selenggarakan`, LANG(), {
                            en: `Utilize the Post Proctor facility to configure and view your exams.`
                        })}</li>
                        <li> {Lang(`Fitur Report akan membantu Anda mengevaluasi ujian dan peserta`, LANG(), {
                            en: `The Report feature will help you evaluate your exams and participants`
                        })}</li>
                    </ul>
                </span>
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc1}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"}
                />
            </div>
        </div>
    </div>
}