import classes from "./Body1.module.scss"
import amanin_desc1 from "../../assets/imgs/content/amanin_desc7.jpg";
import { generatePricePlan } from "./PricePlan";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

export const generateBody1 = (_this) => {
    return <div className={classes.container}>
        <div className={classes.container_content}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor Event</span>
                <span className={classes.title2}>
                    {/* Penyelenggaraan ujian berskala besar tentu membutuhkan perencanaan dan koordinasi yang cermat */}
                    {Lang(`Penyelenggaraan ujian berskala besar tentu membutuhkan perencanaan dan koordinasi yang cermat`, LANG(), {
                        en: `Organizing large-scale exams requires careful planning and coordination.`
                    })}
                </span>
                <span className={classes.content}>
                    {/* Kami telah berpengalaman mengadakan ujian online berskala nasional dan internasional yang melibatkan ribuan peserta secara bersamaan */}
                    {Lang(` Kami telah berpengalaman mengadakan ujian online berskala nasional dan internasional yang melibatkan ribuan peserta secara bersamaan`, LANG(), {
                        en: `We have experience in conducting online exams on a national and international scale involving thousands of participants simultaneously.`
                    })}
                </span>
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc1}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"} />
            </div>
        </div>
    </div>
}