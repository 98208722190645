import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _17_PembatasanKerugian = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("18. PEMBATASAN KERUGIAN", LANG(), { en: '18. LIMITATION OF LIABILITY' })}
        </span>
        <p>
            {Lang("SEJAUH DIPERBOLEHKAN OLEH UNDANG-UNDANG YANG BERLAKU, DALAM SEGALA KEADAAN TIDAK AKAN ADA PLATFORM, LAYANAN, AFILIASI, KOMISARIS, DIREKTUR, ATAU KARYAWAN, ATAU PEMEGANG LISENSI, ATAU MITRA, BERTANGGUNG JAWAB KEPADA ANDA UNTUK KEHILANGAN LABA, PENGGUNAAN, ATAU DATA, ATAU UNTUK KERUGIAN INSIDENTAL, TIDAK LANGSUNG, KHUSUS, AKIBAT ATAU KONTAN YANG LUAR BIASA, BAGAIMANAPUN TIMBUL, YANG AKIBATNYA DARI: ", LANG(), { en: 'TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE PLATFORM, SERVICES, AFFILIATES, COMMISSIONERS, DIRECTORS, OR EMPLOYEES, OR LICENSORS, OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE, OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM:' })}
        </p>
        <ol type="A">
            <li>
                {Lang("PENGGUNAAN, PENGUNGKAPAN, ATAU TAMPILAN KONTEN PENGGUNA ANDA; ", LANG(), { en: 'THE USE, DISCLOSURE, OR DISPLAY OF YOUR USER CONTENT;' })}
            </li>
            <li>
                {Lang("PENGGUNAAN ATAU TIDAK DAPAT MENGGUNAKAN LAYANAN;", LANG(), { en: 'YOUR USE OR INABILITY TO USE THE SERVICES;' })}
            </li>
            <li>
                {Lang("LAYANAN SECARA UMUM ATAU PERANGKAT LUNAK ATAU SISTEM YANG MEMBUAT LAYANAN TERSEDIA; ATAU", LANG(), { en: 'THE SERVICES GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SERVICES AVAILABLE; OR' })}
            </li>
            <li>
                {Lang("INTERAKSI LAIN DENGAN PENGGUNA LAIN ATAU DENGAN PENGGUNA LAIN LAYANAN, APAKAH BERDASARKAN GARANSI, KONTRAK, TORT (TERMASUK KELALAIAN) ATAU TEORI HUKUM LAINNYA, DAN APAKAH KAMI TELAH DIINFORMASIKAN ATAU TIDAK AKAN KEMUNGKINAN ADANYA KEHILANGAN SEMACAM ITU, DAN MESKIPUN PERBAIKAN YANG DIBERIKAN DI SINI DIANGGAP GAGAL DARI TUJUAN POKOKNYA. BEBERAPA YURISDIKSI MEMBATASI ATAU TIDAK MEMPERKENANKAN PENOLAKAN TANGGUNG JAWAB, SEHINGGA KETENTUAN INI MUNGKIN TIDAK BERLAKU UNTUK ANDA.", LANG(),
                    { en: 'ANY OTHER INTERACTIONS WITH OTHER USERS OR WITH OTHER USERS OF THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY PROVIDED HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU.' })}
            </li>
            <p>
                {Lang("Jika Anda memiliki perselisihan dengan satu atau lebih pengguna, produk atau layanan yang Anda tinjau menggunakan Platform dan Layanan, Anda melepaskan Kami (dan pejabat, komisaris, direktur, agen, anak perusahaan, kemitraan dan karyawan Kami) dari klaim, tuntutan dan kerugian (nyata dan konsekuensial) dari setiap jenis dan sifat, yang diketahui dan tidak diketahui, yang timbul dari atau terkait dengan perselisihan tersebut.", LANG(), { en: 'If you have a dispute with one or more users, products, or services that you review using the Platform and Services, you release us (and our officers, commissioners, directors, agents, subsidiaries, joint ventures, and employees) from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way related to such disputes.' })}
            </p>
        </ol>
    </div>
}