import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _4_BagaimanaKamiMenjagaInformasi = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("4. BAGAIMANA KAMI MENJAGA INFORMASI", LANG(), { en: '4. HOW WE PROTECT INFORMATION' })}</span>
        <p>
            {Lang("Kami menerapkan langkah-langkah keamanan yang dirancang untuk melindungi informasi Anda dari akses yang tidak sah. Akun Anda dilindungi oleh kata sandi akun Anda dan Kami menganjurkan Anda untuk mengambil langkah-langkah agar informasi pribadi Anda tetap aman dengan tidak mengungkapkan kata sandi Anda dan dengan keluar dari akun Anda setelah setiap penggunaan. Kami juga melindungi informasi Anda dari potensi pelanggaran keamanan dengan menerapkan beberapa langkah keamanan teknologi tertentu, termasuk enkripsi, firewall, dan teknologi lapisan soket yang aman. Namun, langkah-langkah ini tidak menjamin bahwa informasi Anda tidak akan diakses, diungkapkan, diubah, atau dihancurkan melalui pelanggaran firewall dan perangkat lunak server yang aman tersebut. Dengan menggunakan Layanan Kami, Anda mengakui bahwa Anda memahami dan setuju untuk menerima risiko ini.", LANG(), { en: 'We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password, and we encourage you to take steps to keep your personal information safe by not disclosing your password and logging out of your account after each use. We also protect your information from potential security breaches by implementing various specific technological security measures, including encryption, firewalls, and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered, or destroyed through breaches of our secure firewall and server software. By using our Services, you acknowledge that you understand and agree to accept these risks.' })}
        </p>
    </div>
}