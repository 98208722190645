import React from "react";
import classes from "./__index__.module.scss";

import { LANG, handleScreenChange } from "../../libs/utils";
import NavPage from "../../components/Nav";
import Footer from "../../components/Footer";

import faq from "../../assets/imgs/content/faq.svg"
import { ProktorFirstParticipantFAQ, ProktorFirstProctorerFAQ } from "./FaqProktorFirst";
import { ProktorFreedomParticipantFAQ, ProktorFreedomProctorerFAQ } from "./FaqProktorFreedom";
import { ProktorEventParticipantFAQ, ProktorEventProctorerFAQ } from "./FaqProktorEvent";
import { Lang } from "../../libs/Language";

class FaqPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desktopView: true,
            chosenFAQ: "Proktor First",

            qnaState: {

            }
        }
    }

    componentWillUnmount() {
        this.mobileMediaQuery.removeEventListener('change', this.handleScreenChange); // Hapus listener saat komponen di-unmount
    }

    handleScreenChange = (event) => {
        handleScreenChange(this, event);
    }

    componentDidMount() {
        this.mobileMediaQuery = window.matchMedia('(max-width: 767px)');
        this.handleScreenChange(this.mobileMediaQuery); // Cek status awal
        this.mobileMediaQuery.addEventListener('change', this.handleScreenChange)
    }

    generateFAQ(title = [Lang(`Proktor First FAQ`, LANG(), { en: `Proctor First FAQ` })], qna = [
        {
            q: "How Do I Pay For My Service ?",
            a: `This is the answer sadf asdf asdf asdfasd fasdf asdf asdfas dfasdf asdf adaf`
        },
        {
            q: "How Do I Pay For My Service 2 ?",
            a: `2 This is the answer sadf asdf asdf asdfasd fasdf asdf asdfas dfasdf asdf adaf`
        }
    ], id = null) {
        let clm = this.state.desktopView ? null : classes.mobile

        return <div className={[classes.faq, clm].join(" ")}>
            <div className={[classes.faq_title, clm].join(" ")}>{title}</div>
            {qna.map((v, i) => {
                return <div key={i} className={classes.faq_content}
                    onClick={() => {
                        let qnaState = this.state.qnaState;
                        if (qnaState[id] === undefined) {
                            qnaState[id] = [i];
                        } else {
                            if (qnaState[id].includes(i)) {
                                qnaState[id] = qnaState[id].filter(function (value) {
                                    return value !== i;
                                })
                            } else {
                                qnaState[id].push(i);
                            }
                        }
                        this.setState({ qnaState: qnaState });
                    }}
                >
                    <div className={[classes.title, clm].join(" ")}>
                        <span className={[classes.number].join(" ")}>{i + 1}</span><span>{v.q}</span>
                    </div>
                    {this.state.qnaState[id] !== undefined && this.state.qnaState[id].includes(i) ?
                        <div className={[classes.answer, clm, classes.fadein].join(" ")}>
                            {v.a}
                        </div>
                        : null}
                </div>
            })}
        </div>
    }

    generateChosenFAQ() {
        return this.state.chosenFAQ === "Proktor First" ?
            <>
                {this.generateFAQ(ProktorFirstProctorerFAQ.title, ProktorFirstProctorerFAQ.qna[LANG()], 0)}
                {this.generateFAQ(ProktorFirstParticipantFAQ.title, ProktorFirstParticipantFAQ.qna[LANG()], 1)}

            </> : this.state.chosenFAQ === "Proktor Freedom" ?
                <>
                    {this.generateFAQ(ProktorFreedomProctorerFAQ.title, ProktorFreedomProctorerFAQ.qna[LANG()], 2)}
                    {this.generateFAQ(ProktorFreedomParticipantFAQ.title, ProktorFreedomParticipantFAQ.qna[LANG()], 3)}
                </> : this.state.chosenFAQ === "Proktor Event" ?
                    <>
                        {this.generateFAQ(ProktorEventProctorerFAQ.title, ProktorEventProctorerFAQ.qna[LANG()], 4)}
                        {this.generateFAQ(ProktorEventParticipantFAQ.title, ProktorEventParticipantFAQ.qna[LANG()], 5)}
                    </> : null
    }

    generateContent() {
        const vd = this.state.desktopView;
        const generateOption = (itemName) => {
            return <span
                onClick={() => {
                    this.setState({ chosenFAQ: itemName })
                }}
                className={this.state.chosenFAQ === itemName ? classes.chosen : null}>
                {itemName}
            </span>
        }
        return <div className={[classes.container, vd ? null : classes.mcontainer].join(" ")}>
            <NavPage desktopView={this.state.desktopView} />
            <div className={[classes.container_c, vd ? null : classes.mcontainer_c].join(" ")}>
                <div className={classes.title1}>
                    {/* Do you have questions ? */}
                    {Lang(`Ada pertanyaan ?`, LANG(), { en: 'Do you have questions ?' })}
                </div>
                <div className={classes.title2}>
                    {/* We have answers (Well, most of the times!) */}
                    {Lang(`Jangan kuatir, kami punya jawabannya`, LANG(), { en: 'We have answers (Well, most of the times!)' })}
                    <span className={classes.smiley}></span>
                </div>
                <div className={classes.title3}>
                    {/* Below you will find answers to the most common questions you may have on AdEspresso & Online Advertising. Also, please feel free to check out our Facebook & Google Advertising Guides & Academy.
                    If you still can't find the answer you're looking for, just Contact Us! */}

                    {/* Berikut adalah jawaban untuk pertanyaan-pertanyaan umum yang mungkin Anda miliki tentang Proktor First, Proktor Freedom dan Proktor Event.
                    Jika Anda masih tidak menemukan jawaban yang Anda cari, jangan ragu untuk */}
                    {Lang(`Berikut adalah jawaban untuk pertanyaan-pertanyaan umum yang mungkin Anda miliki tentang Proktor First, Proktor Freedom dan Proktor Event.
                    Jika Anda masih tidak menemukan jawaban yang Anda cari, jangan ragu untuk`, LANG(), {
                        en: `The following are answers to common questions you may have about Proktor First, Proktor Freedom, and Proktor Event. If you still do not find the answer you are looking for, please do not hesitate to` })} <a href="/contactus" style={{ fontWeight: "bold" }}>{Lang(`Hubungi Kami!`, LANG(), { en: 'Contact Us!' })}</a>
                </div>
                <div className={classes.img}>
                    <img alt="faq" src={faq} width={"250px"} />
                </div>
                <div className={[classes.options, vd ? null : classes.mobile].join(" ")}>
                    {generateOption("Proktor First")}
                    {generateOption("Proktor Freedom")}
                    {generateOption("Proktor Event")}
                </div>
                <div className={classes.qna}>
                    {this.generateChosenFAQ()}
                </div>
            </div>
            <Footer />
        </div>
    }

    render() {
        return this.generateContent();
    }
}

export default FaqPage;