import blog from "../../../assets/imgs/content/amanin_desc3.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle3 = {
    title: Lang(`Pendidikan dan Integritas`, LANG(), { en: 'Education and Integrity' }),
    img: blog,
    shorttext: Lang(`Integritas sering dianggap sebagai faktor terpenting dalam kesuksesan seseorang. Mengapa?  Karena integritas akan membangun kepercayaan, kredibilitas, dan perilaku etis. Ketika seseorang menunjukkan integritas dalam tindakan dan keputusannya, mereka lebih cenderung:`, LANG(), { en: "Integrity is often considered the most important factor in someone's success. Why? Because integrity builds trust, credibility, and ethical behavior. When someone demonstrates integrity in their actions and decisions, they are more likely to:" })
    ,
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang("Integritas sering dianggap sebagai faktor terpenting dalam kesuksesan seseorang. Mengapa?  Karena integritas akan membangun kepercayaan, kredibilitas, dan perilaku etis. Ketika seseorang menunjukkan integritas dalam tindakan dan keputusannya, mereka lebih cenderung:", LANG(), { en: "Integrity is often considered the most important factor in someone's success. Why? Because integrity builds trust, credibility, and ethical behavior. When someone demonstrates integrity in their actions and decisions, they are more likely to:" })}

        </p>
        <ol>
            <li>
                {Lang("Membangun Kepercayaan: Kepercayaan adalah dasar dari hubungan yang kuat, baik secara pribadi maupun profesional. Integritas memastikan bahwa orang lain dapat mengandalkan kata dan tindakan Anda, yang berujung pada kepercayaan dan kerja sama.", LANG(), { en: 'Build Trust: Trust forms the foundation of strong relationships, both personally and professionally. Integrity ensures that others can rely on your words and actions, fostering trust and collaboration.' })}
            </li><li>
                {Lang("Menjaga Kredibilitas: Integritas membantu individu membangun dan mempertahankan reputasi mereka untuk kejujuran dan keandalan, yang dapat membuka peluang dan kemitraan.", LANG(), { en: 'Maintain Credibility: Integrity helps individuals build and uphold a reputation for honesty and reliability, opening up opportunities and partnerships.' })}
            </li><li>
                {Lang("Mengambil Keputusan Etis: Bertindak dengan integritas berarti membuat keputusan etis, bahkan ketika sulit. Perilaku etis dihargai dalam masyarakat dan dapat mengarah pada kesuksesan jangka panjang.", LANG(), { en: 'Make Ethical Decisions: Acting with integrity means making ethical decisions, even when faced with difficulty. Ethical behavior is valued in society and can lead to long-term success.' })}
            </li><li>
                {Lang("Memupuk Penghargaan: Orang-orang dengan integritas sering mendapatkan penghargaan dari rekan, kolega, dan atasan mereka, yang dapat menghasilkan kemajuan karir dan kerja sama.", LANG(), { en: 'Earn Respect: People with integrity often receive respect from peers, colleagues, and superiors, which can lead to career advancement and collaboration' })}
            </li><li>
                {Lang("Membangun Hubungan yang Kuat: Integritas berkontribusi pada pengembangan hubungan yang bermakna dan langgeng, baik dalam kehidupan pribadi maupun profesional. Hubungan ini dapat memberikan dukungan dan peluang.", LANG(), { en: 'Forge Strong Relationships: Integrity contributes to developing meaningful and lasting relationships, both in personal and professional life. These relationships can provide support and opportunities' })}
            </li><li>
                {Lang("Menghadapi Tantangan dengan Bijaksana: Individu dengan integritas lebih cenderung menghadapi tantangan dengan jujur dan tangguh, yang dapat membantu mereka mengatasi kesulitan dan pulih dari kegagalan.", LANG(), { en: 'Face Challenges Wisely: Individuals with integrity are more likely to face challenges honestly and resiliently, which can help them overcome difficulties and recover from setbacks.' })}
            </li>
        </ol>
        <p>
            {Lang("Pertanyaannya? Bagaimana membangun integritas dalam diri seseorang? Jawabannya adalah pendidikan. Pendidikan dapat berperan penting dalam meningkatkan integritas seseorang melalui beberapa cara berikut:", LANG(), { en: "The question ? How can someone build integrity within themselves? The answer lies in education. Education plays a crucial role in enhancing an individual's integrity through several methods:" })}

        </p>
        <ol>
            <li>
                {Lang("Pengajaran Nilai dan Etika: Pendidikan seringkali mencakup pengajaran nilai-nilai moral dan etika yang baik. Melalui pelajaran ini, individu dapat memahami pentingnya integritas, kejujuran, dan bertindak dengan baik dalam kehidupan sehari-hari.", LANG(), { en: 'Teaching Values and Ethics: Education often includes teaching good moral values. Through these lessons, individuals can understand the importance of integrity, honesty, and ethical conduct in daily life.' })}

            </li><li>
                {Lang("Kembangkan Kesadaran Etis**: Dalam lingkungan pendidikan, siswa dapat belajar untuk lebih memahami implikasi etis dari tindakan mereka. Mereka dapat belajar bagaimana keputusan dan tindakan mereka dapat memengaruhi orang lain dan masyarakat secara keseluruhan.", LANG(), { en: 'Develop Ethical Awareness: In educational settings, students can learn to better understand the ethical implications of their actions. They learn how their decisions and actions can impact others and society as a whole.' })}
            </li><li>
                {Lang("Pengembangan Keterampilan Kritis: Pendidikan juga membantu dalam pengembangan keterampilan berpikir kritis. Ini memungkinkan individu untuk secara kritis menilai tindakan mereka sendiri dan orang lain, serta memahami konsekuensi etis dari tindakan tersebut.", LANG(), { en: 'Critical Thinking Development: Education also helps develop critical thinking skills. This allows individuals to critically evaluate their own actions and those of others, as well as understand the ethical consequences of those actions.' })}
            </li><li>
                {Lang("Contoh dan Role Model: Di lingkungan pendidikan, terdapat guru dan mentor yang dapat menjadi contoh yang baik dalam hal integritas dan etika. Siswa dapat mengamati dan belajar dari mereka, menciptakan model perilaku yang positif.", LANG(), { en: 'Examples and Role Models: In educational environments, there are teachers and mentors who can serve as good examples of integrity and ethics. Students can observe and learn from them, creating positive behavioral models.' })}
            </li><li>
                {Lang("Latihan Pemecahan Masalah Etis: Sekolah dapat memberikan situasi latihan yang melibatkan pemecahan masalah etis. Ini memungkinkan siswa untuk menghadapi situasi di mana mereka harus membuat keputusan yang berdasarkan pada integritas dan nilai-nilai moral.", LANG(), { en: 'Ethical Problem-Solving Exercises: Schools can provide practice situations involving ethical problem-solving. This allows students to face situations where they must make decisions based on integrity and moral values.' })}
            </li><li>
                {Lang("Diskusi dan Debat: Dalam lingkungan pendidikan, ada kesempatan untuk berdiskusi dan berdebat tentang masalah-masalah etis dan moral. Ini memungkinkan siswa untuk mengembangkan pemahaman yang lebih dalam tentang berbagai perspektif dan argumen yang berkaitan dengan integritas.", LANG(), { en: 'Discussion and Debate: In educational settings, there are opportunities to discuss and debate ethical and moral issues. This allows students to develop a deeper understanding of various perspectives and arguments related to integrity.' })}
            </li><li>
                {Lang("Pendidikan Karakter: Beberapa program pendidikan telah mengadopsi pendekatan pendidikan karakter yang fokus pada pembentukan nilai-nilai moral, termasuk integritas. Ini membantu siswa dalam memahami dan menerapkan nilai-nilai ini dalam kehidupan sehari-hari.", LANG(), { en: 'Character Education: Some educational programs adopt a character education approach that focuses on forming moral values, including integrity. This helps students understand and apply these values in their daily lives.' })}
            </li><li>
                {Lang("Kurikulum Khusus: Beberapa sekolah dan universitas menawarkan kurikulum khusus yang menekankan pada pengembangan integritas dan etika. Ini dapat mencakup kursus-kursus tentang etika bisnis, etika medis, atau etika profesi tertentu.", LANG(), { en: 'Exclusive curriculum: Some schools and universities offer specialized curricula that emphasize the development of integrity and ethics. This may include courses on business ethics, medical ethics, or ethics in specific professions.' })}
            </li><li>
                {Lang("Penekanan pada Tanggung Jawab Sosial: Pendidikan juga dapat mendorong tanggung jawab sosial. Melalui pengalaman dan proyek sosial, siswa dapat belajar tentang kontribusi positif yang dapat mereka berikan pada masyarakat, yang seringkali melibatkan tindakan yang didasarkan pada integritas.", LANG(), { en: 'Emphasis on Social Responsibility: Education can also encourage social responsibility. Through experiences and social projects, students can learn about the positive contributions they can make to society, often involving actions based on integrity.' })}
            </li>
        </ol>
        <p>
            {Lang("Melalui berbagai cara ini, pendidikan dapat memainkan peran penting dalam membentuk dan meningkatkan integritas individu. Itu membantu mereka memahami, menghargai, dan menerapkan nilai-nilai moral yang mendasari integritas dalam kehidupan", LANG(), { en: 'Through these various methods, education can play a significant role in shaping and enhancing individual integrity. It helps individuals understand, appreciate, and apply the moral values that underlie integrity in their lives.' })}
        </p>
    </article>
}