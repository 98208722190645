import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

// {Lang("", LANG(), { en: '' })}
export const _13_HakKekayaanIntelektual = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("14. Hak Kekayaan Intelektual", LANG(), { en: '14. Intellectual Property Rights' })}
        </span>
        <p>
            {Lang("Anda mengakui dan menyetujui bahwa Kami dan para pemberi lisensi Kami tetap memiliki kepemilikan atas semua hak kekayaan intelektual apa pun yang terkait dengan Platform dan Layanan, termasuk hak cipta, merek dagang, dan hak milik lainnya yang berlaku. Nama produk dan perusahaan lain yang disebutkan di Platform dan Layanan mungkin merupakan merek dagang dari pemiliknya masing-masing. Kami menahan semua hak yang tidak secara tegas diberikan kepada Anda dalam Syarat dan Ketentuan ini.", LANG(),
                { en: 'You acknowledge and agree that we and our licensors retain ownership of all intellectual property rights related to the Platform and Services, including copyrights, trademarks, and other proprietary rights. The names of other products and companies mentioned on the Platform and Services may be the trademarks of their respective owners. We reserve all rights not expressly granted to you under these Terms and Conditions.' })}
        </p>
        <p>
            {Lang("Dengan memposting postingan, konten, tautan, dan informasi apa pun melalui Kami, Anda dengan tegas memberikan, dan Anda menyatakan dan menjamin bahwa Anda memiliki hak untuk memberikan, kepada Kami lisensi bebas royalti, dapat disublisensikan, dapat dipindahkan, berkelanjutan, tidak dapat ditarik kembali, non-eksklusif, di seluruh dunia untuk menggunakan, mereproduksi, memodifikasi, menerbitkan, mencantumkan informasi tentang, mengedit, menerjemahkan, mendistribusikan, melakukan secara publik, menampilkan secara publik, dan membuat karya turunan dari seluruh postingan, konten, tautan, dan informasi tersebut dan nama, suara, dan/atau gambaran diri Anda yang terkandung dalam postingan, konten, tautan, dan informasi Anda, jika berlaku, sebagian atau seluruhnya, dan dalam bentuk, media, atau teknologi apa pun, baik yang dikenal saat ini atau dikembangkan di kemudian hari, untuk digunakan dalam kaitannya dengan Platform dan Layanan.", LANG(),
                { en: 'By posting any posts, content, links, or information through us, you expressly grant, and you represent and warrant that you have the right to grant, to us a royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, publicly perform, publicly display, and make derivative works of all such posts, content, links, and information, and your name, voice, and/or likeness as contained in such posts, content, links, and information, if applicable, in whole or in part, and in any form, media, or technology, whether now known or hereafter developed, for use in connection with the Platform and Services.' })}
        </p>
    </div>
}