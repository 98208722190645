import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const ProktorFirstParticipantFAQ = {
    title: Lang(`Proktor First - Peserta`, LANG(), { en: 'Proktor First - Participant' }),
    qna: {
        id: [
            {
                q: "Bagaimana bergabung dengan ujian ?",
                a: <ol>
                    <li>Buka laman proktor amanin</li>
                    <li>Login dengan email Anda</li>
                    <li>Masukkan kode ujian Anda dan klik Gabung</li>
                </ol>
            },
            {
                q: "Bagaimana membagikan tangkapan layar komputer, kamera web, dan mikrofon ?",
                a: `Setelah masuk ke laman persiapan peserta, klik tombol Aktifkan Web Camera dan Screen Capture`
            },
            {
                q: "Kenapa saya harus membagikan tangkapan layar komputer, web kamera dan mikrofon ?",
                a: "Tangkapan layar komputer, web kamera dan mikrofon dilakukan untuk memastikan ujian online berlangsung dengan lancar dan tidak ada kecurangan yang terjadi"
            },
            {
                q: "Apakah saya harus menginstal program atau aplikasi atau ekstensi agar bisa ikut ujian ?",
                a: "Tidak, Untuk tingkat keamanan tertentu anda tidak perlu menginstal ekstensi."
            },
            {
                q: "Apa saja yang dibutuhkan agar saya bisa bergabung ke sebuah ujian ?",
                a: "Anda membutuhkan sebuah PC dengan web kamera, sambungan internet, email dan kode ujian"
            },
            {
                q: "Apa saya perlu membuat akun agar bisa ikut ujian ?",
                a: "Ya, Anda perlu membuat akun sebelum mengikuti ujian"
            },
            {
                q: "Bagaimana platform ini menangani masalah kestabilan koneksi internet peserta selama pengawasan ujian?",
                a: "Untuk situasi darurat, gangguan koneksi internet yang cukup lama, dan gangguan teknis kami mengikuti kebijakan dari penyelenggara ujian"
            },
            {
                q: "Apakah ada batasan perangkat keras atau sistem operasi yang dapat digunakan peserta selama ujian?",
                a: "Peserta disarankan untuk menggunakan komputer dengan prosesor setara intel celeron dengan RAM setara 4GB. Untuk sistem operasi peserta bebas menggunakan apa saja asal browsernya menggunakan Google Chrome"
            },
            {
                q: "Bagaimana sistem mengelola situasi darurat, seperti pemadaman listrik atau gangguan lainnya, selama ujian?",
                a: "Untuk situasi darurat, gangguan koneksi internet yang cukup lama, dan gangguan teknis kami mengikuti kebijakan dari penyelenggara ujian."
            },
            {
                q: "Bagaimana sistem menjaga privasi peserta selama pengawasan ujian?",
                a: "Kami memprioritaskan privasi peserta dan hanya mengumpulkan data yang diperlukan untuk tujuan pengawasan. Seluruh proses mematuhi regulasi privasi dan kebijakan yang berlaku."
            },
            {
                q: "Bagaimana sistem mengatasi potensi gangguan teknis atau masalah koneksi peserta selama ujian?",
                a: "Untuk situasi darurat, gangguan koneksi internet yang cukup lama, dan gangguan teknis kami mengikuti kebijakan dari penyelenggara ujian."
            }
        ],
        en: [
            {
                q: "How do I join an exam?",
                a: <ol>
                    <li>Go to the Proktor Amanin website</li>
                    <li>Log in with your email</li>
                    <li>Insert your exam code and click Join</li>
                </ol>
            },
            {
                q: "How do I share screenshots of my computer, webcam, and microphone?",
                a: "After logging into the participant preparation page, click the Activate Web Camera and Screen Capture button."
            },
            {
                q: "Why do I need to share screenshots of my computer, webcam, and microphone?",
                a: "Screenshots of your computer, webcam, and microphone are taken to ensure the smooth conduct of the online exam and to prevent any cheating."
            },
            {
                q: "Do I need to install any programs, applications, or extensions to participate in the exam?",
                a: "No, for certain security levels, you do not need to install any extensions."
            },
            {
                q: "What do I need in order to join an exam?",
                a: "You need a PC with a webcam, internet connection, email, and an exam code."
            },
            {
                q: "Do I need to create an account to take the exam?",
                a: "Yes, you need to create an account before taking the exam."
            },
            {
                q: "How does this platform handle internet connection stability issues for participants during exam supervision?",
                a: "In cases of emergency, prolonged internet disruptions, and technical issues, we follow the exam organizer's policies."
            },
            {
                q: "Are there hardware or operating system limitations for participants during the exam?",
                a: "Participants are advised to use a computer with an Intel Celeron equivalent processor and 4GB RAM. Participants are free to use any operating system as long as their browser is Google Chrome."
            },
            {
                q: "How does the system manage emergency situations, such as power outages or other disruptions, during exams?",
                a: "In cases of emergency, prolonged internet disruptions, and technical issues, we follow the exam organizer's policies."
            },
            {
                q: "How does the system ensure participant privacy during exam supervision?",
                a: "We prioritize participant privacy and only collect necessary data for monitoring purposes. The entire process complies with applicable privacy regulations and policies."
            },
            {
                q: "How does the system address potential technical disruptions or connectivity issues for participants during exams?",
                a: "In cases of emergency, prolonged internet disruptions, and technical issues, we follow the exam organizer's policies."
            }
        ]

    }

}

export const ProktorFirstProctorerFAQ = {
    title: Lang(`Proktor First - Pengawas`, LANG(), { en: 'Proktor First - Proctor' }),
    qna: {
        id: [
            {
                q: "Bagaimana cara menjadwalkan ujian ?",
                a: <ol>
                    <li>Buka laman proktor amanin</li>
                    <li>Setelah berhasil Login, pilih Buat Ujian, pilih Ujian Instan atau Ujian Terjadwal</li>
                    <li>Jika Anda memilih Ujian Instan Anda dapat menyelenggarakan ujian saat itu juga</li>
                    <li>Jika memilih Ujian Terjadwal Anda dapat menjadwalkan kapan Anda akan menyelenggarakan ujian</li>
                </ol>
            },
            {
                q: "Bagaimana memulai ujian ?",
                a: <ol>
                    <li>Untuk pilihan Ujian Instan, ujian akan otomatis dimulai begitu dibuat</li>
                    <li>Sedangkan untuk pilihan Ujian Terjadwal, ujian akan dimulai sesuai jadwal. Tetapi jika Anda memutuskan untuk memulai ujian sebelum jadwalnya, Anda dapat memulai ujian dengan mengklik tombol Mulai Ujian</li>
                </ol>
            },
            {
                q: "Apa perbedaan Proktor First Basic, Pro dan Bisnis ?",
                a: `Secara umum, Proktor First Basic, Pro dan Bisnis berbeda pada fitur-fitur yang disedikan. Untuk lebih lengkapnya Anda dapat membuka Paket Harga di laman website Proktor Amanin`
            },
            {
                q: "Apa saja tanda warning di Proktor First ?",
                a: <div>Proses AI yang terjadi selama ujian berlangsung akan mendeteksi pelanggaran-pelanggaran yang dilakukan oleh Peserta. Macam-macam pelanggaran yang dideteksi adalah :
                    < ol >
                        <li>Wajah Peserta di luar frame</li>
                        <li>Wajah yang tertangkap kamera lebih dari satu</li>
                        <li>Wajah yang tertangkap kamera tidak sesuai dengan wajah di foto identitas</li>
                        <li>Ada tab atau browser baru yang dibuka selama ujian berlangsung</li>
                        <li>Melakukan Print Screen atau Screenshot selama ujian berlangsung</li>
                    </ol >
                </div>
            },
            {
                q: "Apa saja yang bisa saya lakukan di Post Proktor ?",
                a: <div>Penyelenggara ujian dapat mengelola, mengatur, dan mengonfigurasi ujian online yang diselenggarakan. Pada Post Proktor, penyelenggara ujian dapat melakukan :
                    <ol>
                        <li>Mengelola data peserta ujian online</li>
                        Penyelenggara ujian online dapat menambah peserta, mengedit data peserta, dan melihat laporan dari setiap peserta ujian.
                        <li>Mengonfigurasi ujian online</li>
                        Penyelenggara ujian online dapat mengonfigurasi ujian yang akan mereka laksanakan, seperti nama ujian, waktu pelaksanaan ujian, durasi, resolusi video serta threshold deteksi wajah
                        <li>Melihat statistik ujian online</li>
                        Penyelenggara ujian dapat melihat statistika penyelenggaraan ujian berdasarkan waktu dan jumlah peserta

                    </ol>
                </div>
            },
            {
                q: "Bagaimana saya bisa mengundang orang bergabung di ujian yang saya buat ?",
                a: <ol>
                    <li>Salin kode ujian di Halaman Pengawas</li>
                    <li>Bagikan kepada para peserta ujian</li>
                </ol>
            },
            {
                q: "Apakah ada fitur analisis atau pelaporan yang memudahkan evaluasi hasil ujian ?",
                a: `Ya, Anda dapat menemukan fitur analisis atau pelaporan di bagian Post Proktor`
            },
            {
                q: "Kenapa saya harus meningkatkan Proktor First saya ke Pro atau ke Bisnis ?",
                a: <div>Anda perlu meningkatkan Proktor First Anda dari versi Basic ke Pro atau Bisnis jika :
                    < ol >
                        <li>Membutuhkan pengawasan ujian lebih dari satu kali sehari.</li>
                        <li>Peserta yang diawasi lebih dari 25 orang</li>
                        <li>Durasi ujian lebih dari 2 jam</li>
                    </ol >
                </div>
            },
        ],
        en: [
            {
                q: "How do I schedule an exam?",
                a: <ol>
                    <li>Go to the Proktor Amanin website.</li>
                    <li>After logging in, select 'Create Exam', and choose 'Instant Exam' or 'Scheduled Exam'.</li>
                    <li>If you choose 'Instant Exam', you can start the exam immediately..</li>
                    <li>If you choose 'Scheduled Exam', you can set the date and time for the exam.</li>
                </ol>
            },
            {
                q: "How do I start the exam?",
                a: <ol>
                    <li>For 'Instant Exam', the exam will start automatically once it is created.</li>
                    <li>For 'Scheduled Exam', the exam will start at the scheduled time. However, if you decide to start the exam earlier, you can click the 'Start Exam' button.</li>
                </ol>
            },
            {
                q: "What are the differences between Proktor First Basic, Pro, and Business?",
                a: "Generally, Proktor First Basic, Pro, and Business differ in the features they offer. For more details, please refer to the Pricing Plans on the Proktor Amanin website."
            },
            {
                q: "What are the warning signs in Proktor First?",
                a: <div>The AI process during the exam detects violations by the participants. The types of violations detected include:
                    <ol>
                        <li>Participant's face out of frame.</li>
                        <li>More than one face detected by the camera.</li>
                        <li>Face detected by the camera does not match the photo ID.</li>
                        <li>New tab or browser opened during the exam.</li>
                        <li>Taking a print screen or screenshot during the exam.</li>
                    </ol>
                </div>
            },
            {
                q: "What can I do in Post Proktor?",
                a: <div>Exam organizers can manage, configure, and set up online exams. In Post Proctor, exam organizers can:
                    <ol>
                        <li>Manage online exam participant data.</li>
                        <p>Exam organizers can add participants, edit participant data, and view reports for each exam participant.</p>
                        <li>Configure the online exam.</li>
                        <p>Exam organizers can configure the upcoming exams, such as exam name, exam time, duration, video resolution, and face detection threshold.</p>
                        <li>View online exam statistics.</li>
                        <p>Exam organizers can view exam statistics based on time and the number of participants.</p>
                    </ol>
                </div>
            },
            {
                q: "How can I invite people to join the exam I created?",
                a: <ol>
                    <li>Copy the exam code from the Proctor Page.</li>
                    <li>Share it with the exam participants.</li>
                </ol>
            },
            {
                q: "Is there an analysis or reporting feature to facilitate the evaluation of exam results?",
                a: "Yes, you can find the analysis or reporting feature in the Post Proctor section."
            },
            {
                q: "Why should I upgrade my Proktor First to Pro or Business?",
                a: <div>You should upgrade your Proktor First from the Basic version to Pro or Business if:
                    <ol>
                        <li>You need to supervise exams more than once a day.</li>
                        <li>You have more than 25 participants to supervise.</li>
                        <li>The exam duration is longer than 2 hours.</li>
                    </ol>
                </div>
            }

        ]
    }


}

export const ProktorFirstAdminFAQ = {
    title: `Proktor First - Admin`,
    qna: {
        id: [
            {
                q: "Bagaimana Proktor Amanin mendukung proses penyelenggaraan ujian online, termasuk pengaturan soal, jadwal ujian, dan manajemen peserta ?",
                a: `Anda dapat dengan fleksibel mengatur jadwal ujian, mengelola peserta dan memonitor progress ujian secara real-time. Perlu diiingat bahwa saat ini kami belum menyediakan fitur pembuatan soal. Untuk soal ujian Anda dapat mengintegrasikan dengan platform penyedia soal yang Anda pilih`
            },
            {
                q: "Sejauh mana Proktor Amanin dapat disesuaikan dengan kebutuhan penyelenggara ujian ?",
                a: `Ya, platform kami dapat sepenuhnya disesuaikan. Anda dapat mengatur jadwal, menyesuaikan durasi, dan mengonfigurasi berbagai parameter sesuai kebutuhan ujian Anda. Untuk menyesuaikan dengan kebutuhan khusus Anda yang lebih kompleks dan spesifik, Anda dapat menggunakan produk Proktor Freedom.`
            },
            {
                q: "Apakah ada integrasi dengan LMS atau platform penyedia soal ?",
                a: "Ya, Anda dapat mengintegrasikan  dengan LMS atau platform penyedia soal yang Anda pilih"
            },
            {
                q: "Apakah platform mendukung pengaturan ulang ujian untuk peserta yang mengalami masalah teknis atau keadaan darurat ?",
                a: "Ya, tetapi pada prakteknya kami menyesuaikan dengan kebijakan penyelenggara ujian"
            },
            {
                q: "Apakah platform dapat disesuaikan dengan kebijakan atau aturan khusus ujian?",
                a: "Ya, platform kami dapat sepenuhnya disesuaikan. Anda dapat mengatur aturan khusus ujian, menyesuaikan durasi, dan mengonfigurasi berbagai parameter sesuai kebutuhan ujian Anda.Tetapi untuk perubahan yang ekstrim, unik dan spesifik Anda dapat menghubungi kami untuk menggunakan Proktor Freedom."
            },
            {
                q: "Apakah ada kemampuan untuk mengelola pengaturan keamanan dan privasi peserta selama pengawasan ujian ?",
                a: "Ya, platform kami memberikan kontrol penuh kepada penyelenggara ujian untuk mengelola pengaturan keamanan dan privasi peserta. Anda dapat menyesuaikan tingkat pengawasan dan mematuhi regulasi privasi yang berlaku."
            }
        ],
        eng: [
            {
                q: "How does Proktor Amanin support the process of conducting online exams, including question settings, exam schedules, and participant management?",
                a: "You can flexibly schedule exams, manage participants, and monitor exam progress in real-time. Please note that currently we do not provide question creation features. For exam questions, you can integrate with the question provider platform of your choice."
            },
            {
                q: "To what extent can Proktor Amanin be customized to meet the needs of exam organizers?",
                a: "Yes, our platform can be fully customized. You can schedule exams, adjust durations, and configure various parameters according to your exam needs. For more complex and specific requirements, you can use Proktor Freedom products."
            },
            {
                q: "Are there integrations with LMS or question provider platforms?",
                a: "Yes, you can integrate with LMS or the question provider platform of your choice."
            },
            {
                q: "Does the platform support exam reset options for participants facing technical issues or emergencies?",
                a: "Yes, but in practice, we adapt to the exam organizer's policies."
            },
            {
                q: "Can the platform be tailored to specific exam policies or rules?",
                a: "Yes, our platform can be fully customized. You can set specific exam rules, adjust durations, and configure various parameters according to your exam needs. For extreme, unique, and specific changes, you can contact us to use Proktor Freedom."
            },
            {
                q: "Is there capability to manage security and privacy settings for participants during exam monitoring?",
                a: "Yes, our platform gives exam organizers full control to manage security and privacy settings for participants. You can adjust the level of monitoring and comply with applicable privacy regulations."
            }
        ]
    }

}

export const ProktorFirstKeamananFAQ = {
    title: Lang(`Proktor First - Keamanan dan Integritas Penyelenggara Ujian`, LANG(), { en: `Proktor First - Security and Integrity of Exam Organizers` }),
    qna: {
        id: [
            {
                q: "Bagaimana keamanan ujian dijamin untuk mencegah kecurangan ?",
                a: `Platform kami mengintegrasikan fitur pengawasan online yang canggih. Ini mencakup teknologi pengenalan wajah, pemantauan webcam peserta, pemantauan tangkapan layar peserta, deteksi gerakan yang mencurigakan, dan analisis kecerdasan buatan untuk mendeteksi potensi tindakan kecurangan.`
            },
            {
                q: "Apakah ada fitur keamanan seperti pengawasan online, deteksi kecurangan, atau penggunaan perangkat keras keamanan ?",
                a: `Ya, Kami menyediakan fitur keamanan seperti pengawasan online, deteksi kecurangan, atau penggunaan perangkat keras keamanan.`
            },
            {
                q: "Apakah ada fitur pemantauan peserta selama ujian ?",
                a: "Ya, terdapat fitur pemantauan peserta selama ujian, yaitu pemantauan webcam peserta dan pemantauan tangkapan layar peserta."
            },
            {
                q: "Apakah ada laporan atau audit trail yang mencatat aktivitas pengawasan selama ujian?",
                a: "Ya, Anda akan mendapatkan fitur tersebut dengan mengakses Post Pproktor."
            },
            {
                q: "Apakah platform ini menyertakan fitur pengenalan wajah untuk memastikan bahwa peserta yang mengikuti ujian adalah orang yang seharusnya?",
                a: "Ya, kami menggunakan teknologi pengenalan wajah berbasis AI untuk memastikan kecocokan antara peserta dan data pendaftaran mereka, meningkatkan keamanan dan integritas ujian."
            },
            {
                q: "Apakah ada pelaporan otomatis untuk kejadian yang mencurigakan atau tindakan kecurangan?",
                a: "Ya, platform kami menyertakan pelaporan otomatis untuk setiap kejadian yang mencurigakan atau tindakan kecurangan. Ini membantu penyelenggara ujian untuk mengambil tindakan yang sesuai. Platform kami juga menyediakan fitur yang memungkinkan pengawas untuk menandai dan atau memberi catatan saat menemukan kejadian yang mencurigakan."
            }
        ],
        eng: [
            {
                q: "How is exam security ensured to prevent cheating?",
                a: "Our platform integrates advanced online monitoring features. This includes facial recognition technology, participant webcam monitoring, screen capture monitoring, detection of suspicious movements, and artificial intelligence analysis to detect potential cheating actions."
            },
            {
                q: "Are there security features such as online monitoring, cheating detection, or use of security hardware?",
                a: "Yes, we provide security features such as online monitoring, cheating detection, or use of security hardware."
            },
            {
                q: "Is there participant monitoring during exams?",
                a: "Yes, there is participant monitoring during exams, including participant webcam and screen capture monitoring."
            },
            {
                q: "Are there reports or audit trails that record monitoring activities during exams?",
                a: "Yes, you will have access to these features through Post Proctor."
            },
            {
                q: "Does this platform include facial recognition features to ensure that exam participants are who they claim to be?",
                a: "Yes, we utilize AI-based facial recognition technology to verify the identity of participants against their registration data, enhancing exam security and integrity."
            },
            {
                q: "Is there automatic reporting for suspicious incidents or cheating actions?",
                a: "Yes, our platform includes automatic reporting for any suspicious incidents or cheating actions. This helps exam organizers take appropriate actions. Our platform also provides features that allow proctorers to flag and make notes when encountering suspicious incidents."
            }
        ],
    }

}