import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _8_Rekaman = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("8. Rekaman", LANG(), { en: '8. Recording' })}
        </span>
        <p>
            {Lang("Anda bertanggung jawab untuk mematuhi semua Undang-Undang yang mengatur pemantauan atau perekaman sebagai Pengawas dan/atau Penyelenggara Ujian. Dengan menggunakan Platform dan Layanan, Anda memberikan izin kepada Proktor Amanin untuk menyimpan rekaman. Anda akan menerima pemberitahuan (secara visual atau lainnya) ketika perekaman diaktifkan. Jika Anda tidak memberikan persetujuan untuk direkam, Anda dapat memilih untuk tidak menggunakan Platform dan Layanan.", LANG(),
                { en: 'You are responsible for complying with all laws governing monitoring or recording as a Supervisor and/or Test Administrator. By using the Platform and Service, you grant permission to Proktor Amanin to store recordings. You will receive notice (visually or otherwise) when recording is activated. If you do not consent to being recorded, you may choose not to use the Platform and Service.' })}
        </p>
        <p>
            {Lang("Dengan menggunakan Platform dan  Layanan Kami, berarti Anda memberikan izin kepada Kami untuk merekam dan direkam sebagai bagian dari Platform dan Layanan. Jika Anda tidak memberikan persetujuan, Anda dapat memilih untuk tidak menggunakan Platform dan Layanan.", LANG(),
                { en: 'By using our Platform and Service, you consent to us recording and being recorded as part of the Platform and Service. If you do not consent, you may choose not to use the Platform and Service.' })}
        </p>
    </div>
}