import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _6_PerubahanKebijakan = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>{Lang("6. PERUBAHAN KEBIJAKAN PRIVASI KAMI", LANG(), { en: '6. CHANGES TO OUR PRIVACY POLICY' })}</span>
        <p>
            {Lang("Kami berhak mengubah kebijakan ini dan Syarat dan Ketentuan Kami kapan saja. Kami akan memberitahu Anda tentang perubahan signifikan pada Kebijakan Privasi Kami dengan mengirim pemberitahuan ke alamat email utama yang Anda tentukan di akun Anda atau dengan menempatkan pemberitahuan yang menonjol di situs Kami. Anda sebaiknya secara berkala memeriksa situs dan halaman privasi ini untuk pembaruan. Apabila Kami membuat perubahan yang bersifat signifikan pada Kebijakan Privasi Kami, Kami akan memberikan kesempatan kepada Anda untuk mempertimbangkan apakah akan tetap melanjutkan penggunaan produk dan layanan Kami.", LANG(), { en: 'We reserve the right to change this policy and our Terms and Conditions at any time. We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by prominently posting a notice on our website. You should periodically check this site and privacy pages for updates. If we make material changes to our Privacy Policy, we will provide you with an opportunity to review whether to continue using our products and services.' })}
        </p>
    </div>
}