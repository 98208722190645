import { Lang } from "../../libs/Language"
import { LANG } from "../../libs/utils"

export const _18_ModifikasiPengguan = (_this, classes) => {
    return <div className={classes.paragraph}>
        <span className={classes.partitle}>
            {Lang("19. MODIFIKASI KETENTUAN PENGGUNAAN", LANG(), { en: '19. MODIFICATION OF TERMS OF USE' })}
        </span>
        <p>
            {Lang("Kami dapat mengubah Syarat dan Ketentuan ini kapan saja dan akan memperbarui Syarat dan Ketentuan ini dalam hal adanya amandemen semacam itu. Ini adalah tanggung jawab Anda semata untuk memeriksa Platform dan Layanan dari waktu ke waktu untuk melihat perubahan semacam itu dalam perjanjian ini. Penggunaan berkelanjutan Anda terhadap Platform atau Layanan menandakan persetujuan Anda terhadap revisi Kami terhadap Syarat dan Ketentuan ini. Kami akan berusaha memberi tahu Anda tentang perubahan materi pada Ketentuan dengan memposting pemberitahuan di beranda dan/atau mengirimkan email ke alamat email yang Anda berikan kepada Kami saat registrasi. Untuk alasan tambahan ini, Anda harus menjaga informasi kontak dan profil Anda tetap terkini. Setiap perubahan pada Syarat dan Ketentuan ini (selain seperti yang diatur dalam paragraf ini) atau pengabaian hak Kami di bawah ini tidak akan sah atau efektif kecuali dalam sebuah perjanjian tertulis yang membawa tanda tangan fisik dari salah satu pejabat Kami. Tidak ada pengabaian atau modifikasi dari kesepakatan ini dari pihak Kami melalui komunikasi telepon atau email dianggap sah.", LANG(),
                { en: 'We reserve the right to amend these Terms and Conditions at any time. If any amendments are made, we will update these Terms and Conditions accordingly. It is your sole responsibility to periodically review the Platform and Services to stay informed about any changes. By continuing to use the Platform or Services, you agree to accept any revised Terms and Conditions. We will attempt to notify you of any significant changes to the Terms by posting a notice on the homepage and/or by emailing the address you provided during registration. Therefore, it is important to keep your contact information and profile current. Any modifications to these Terms and Conditions, or any waiver of our rights under these Terms, will only be valid or effective if made in a written agreement signed by one of our officers. No waiver or modification of this agreement made through phone or email communication will be considered valid.' })}
        </p>
    </div>
}