import blog from "../../../assets/imgs/content/amanin_desc1.jpg";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderArticle9 = {
    title: Lang(`Meningkatkan Kredibilitas Universitas Melalui Solusi Proctoring: Menjunjung Kejujuran dan Integritas`, LANG(), { en: 'Enhancing University Credibility Through Proctoring Solutions: Upholding Honesty and Integrity' }),
    img: blog,
    shorttext: Lang(`Kredibilitas adalah salah satu elemen kunci dalam menilai kualitas dan reputasi sebuah universitas. Universitas yang dipersepsikan memiliki tingkat kredibilitas yang tinggi akan menjadi pilihan utama bagi calon mahasiswa dan orang tua. Untuk mencapai kredibilitas yang tinggi, tidak hanya dibutuhkan pengajar berkualitas, tetapi juga perhatian yang serius terhadap integritas dan kejujuran. Dalam era di mana ujian online semakin umum, solusi proctoring telah muncul sebagai alat penting dalam memastikan kualitas dan kejujuran dalam pendidikan tinggi.`, LANG(), { en: 'Credibility is one of the key elements in assessing the quality and reputation of a university. A university perceived to have high credibility becomes the top choice for prospective students and parents. Achieving high credibility requires not only quality educators but also a serious focus on integrity and honesty. In an era where online exams are becoming more common, proctoring solutions have emerged as crucial tools to ensure quality and honesty in higher education.' }),
    content: <article>
        <figure>
            <img src={blog} alt='' width={"400px"} />
        </figure>
        <p>
            {Lang("Kredibilitas adalah salah satu elemen kunci dalam menilai kualitas dan reputasi sebuah universitas. Universitas yang dipersepsikan memiliki tingkat kredibilitas yang tinggi akan menjadi pilihan utama bagi calon mahasiswa dan orang tua. Untuk mencapai kredibilitas yang tinggi, tidak hanya dibutuhkan pengajar berkualitas, tetapi juga perhatian yang serius terhadap integritas dan kejujuran. Dalam era di mana ujian online semakin umum, solusi proctoring telah muncul sebagai alat penting dalam memastikan kualitas dan kejujuran dalam pendidikan tinggi.", LANG(), { en: 'Credibility is one of the key elements in assessing the quality and reputation of a university. A university perceived to have high credibility becomes the top choice for prospective students and parents. Achieving high credibility requires not only quality educators but also a serious focus on integrity and honesty. In an era where online exams are becoming more common, proctoring solutions have emerged as crucial tools to ensure quality and honesty in higher education.' })}

        </p>
        <p>
            {Lang("Solusi proctoring adalah sistem pengawasan ujian online yang membantu memastikan bahwa mahasiswa menjalani ujian mereka dengan jujur, tanpa kecurangan atau menggunakan sumber daya yang tidak diizinkan. Bagaimana solusi ini dapat meningkatkan kredibilitas sebuah universitas? Berikut adalah beberapa alasan yang mendasarinya:", LANG(), { en: "Proctoring solutions are online exam monitoring systems that help ensure students take their exams honestly, without cheating or using unauthorized resources. How can these solutions enhance a university's credibility? Here are some key reasons:" })}

        </p>
        <ol>
            <li>
                {Lang("Kejujuran adalah Landasan Kesuksesan: Universitas yang menekankan kejujuran dan integritas membantu menciptakan lulusan yang memiliki integritas tinggi. Siswa yang melewati ujian tanpa curang belajar untuk menghargai proses belajar-mengajar dan pentingnya kejujuran dalam kehidupan mereka.", LANG(), { en: 'Honesty is the Foundation of Success: Universities that emphasize honesty and integrity help create graduates with high integrity. Students who pass exams without cheating learn to appreciate the learning process and the importance of honesty in their lives.' })}

            </li><li>
                {Lang("Kualitas Pendidikan yang Diakui: Ketika universitas memberikan jaminan bahwa ujian mereka diawasi secara ketat dan kecurangan dihindari, ini memberikan keyakinan kepada pemangku kepentingan, termasuk calon mahasiswa dan orang tua. Ini menciptakan gambaran positif bahwa universitas tersebut mendedikasikan diri untuk memberikan pendidikan berkualitas tinggi.", LANG(), { en: 'Recognized Educational Quality: When a university guarantees that its exams are strictly monitored and cheating is prevented, it instills confidence in stakeholders, including prospective students and parents. This creates a positive image that the university is dedicated to providing high-quality education.' })}

            </li><li>
                {Lang("Memenuhi Tuntutan Pemangku Kepentingan: Dalam lingkungan pendidikan yang semakin kompetitif, universitas perlu memenuhi tuntutan pemangku kepentingan, termasuk alumni, calon mahasiswa, dan orang tua. Solusi proctoring adalah bukti nyata bahwa universitas tersebut berkomitmen pada kualitas dan integritas.", LANG(), { en: 'Meeting Stakeholder Demands: In an increasingly competitive educational environment, universities need to meet the demands of stakeholders, including alumni, prospective students, and parents. Proctoring solutions are tangible evidence that the university is committed to quality and integrity.' })}

            </li><li>
                {Lang("Menghadapi Tantangan Perubahan Teknologi: Dalam era digital, pendidikan online menjadi semakin umum. Universitas yang mengadopsi solusi proctoring menunjukkan bahwa mereka siap menghadapi tantangan teknologi dan memastikan bahwa inovasi ini tidak mengorbankan integritas akademik.", LANG(), { en: 'Addressing Technological Challenges: In the digital age, online education is becoming more common. Universities that adopt proctoring solutions demonstrate that they are ready to face technological challenges and ensure that these innovations do not compromise academic integrity.' })}
            </li>
        </ol>
        <p>
            {Lang("Solusi proctoring bukan hanya alat teknologi biasa. Ini adalah manifestasi nyata dari komitmen universitas terhadap kejujuran, integritas, dan kualitas pendidikan. Dengan membangun image sebagai lembaga yang memprioritaskan nilai-nilai ini, universitas dapat meningkatkan kredibilitas mereka dan mempersiapkan mahasiswa untuk masa depan yang sukses.", LANG(), { en: "Proctoring solutions are not just ordinary technological tools. They are a tangible manifestation of a university's commitment to honesty, integrity, and educational quality. By building an image as an institution that prioritizes these values, universities can enhance their credibility and prepare students for a successful future." })}
        </p>
    </article>
}