import classes from "./Body5.module.scss"
import amanin_desc5 from "../../assets/imgs/content/amanin_desc5.jpg";
import { generatePricePlan } from "./PricePlan";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

export const generateBody5 = (_this) => {
    return <div className={classes.container}>
        <div className={classes.container_content}>
            <div className={classes.container_content_left}>
                <span className={classes.title}>Proktor First</span>
                <span className={classes.title2}>
                    {/* Solusi Terbaik Untuk Penyelenggaraan & Pengawasan Ujian Online */}
                    {Lang(`Solusi Terbaik Untuk Penyelenggaraan & Pengawasan Ujian Online`, LANG(), {
                        en: `The Best Solution for Online Exam Organizing & Monitoring`
                    })}
                </span>
                <span className={classes.content}
                    style={{ marginBottom: "20px" }}
                >
                    {/* Buatlah Ujian yang akan Anda selenggarakan dengan mudah, aman dan nyaman bagi Anda dan Peserta tanpa mengorbankan Integritas Ujian Anda */}
                    {Lang(`Buatlah Ujian yang akan Anda selenggarakan dengan mudah, aman dan nyaman bagi Anda dan Peserta tanpa mengorbankan Integritas Ujian Anda`, LANG(), {
                        en: `Make your exams easy, secure and convenient for you and your participants without compromising the integrity of your exams.`
                    })}
                </span>
                {generatePricePlan(_this)}
            </div>
            <div className={classes.container_content_right}>
                <img
                    alt="desc"
                    src={amanin_desc5}
                    height={_this.state.desktopView ? "400px" : "auto"}
                    width={_this.state.desktopView ? "auto" : "100%"}

                />
            </div>
        </div>
    </div>
}